import React from 'react';
import _ from "lodash";
import './ClickableText.css';

import Cancel from '../assets/icons/cancel-icon.svg';
import Edit from '../assets/icons/edit-icon.svg';
import Reschedule from '../assets/icons/reschedule-icon.svg';
import Print from '../assets/icons/print-icon.svg';
import CarrotDown from '../assets/icons/carrot-down.svg';


const ClickableText = ({text, style, containerStyle, image, onClick}) => {
    const [clickableText, setClickableText] = React.useState(false);

    let textIcon;
    if (image) {
        if (image === 'cancel') {
            textIcon = Cancel;
        }
        else if (image === 'edit') {
            textIcon = Edit;
        }
        else if (image === 'reschedule') {
            textIcon = Reschedule;
        }
        else if (image === 'print') {
            textIcon = Print
        }
        else if (image === 'carrot-down') {
            textIcon = CarrotDown
        }
        return (
            <div className='app__clickabletext' style={containerStyle} >
                <div className='app__clickabletext-icon' onClick={onClick}>
                    <img src={textIcon} />
                </div>
                <div className='app__clickabletext-text' style={style} onClick={onClick}>
                    {text}
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='app__clickabletext' style={containerStyle}>
            <div className='app__clickabletext-text' style={style} onClick={onClick}>
                {text}
            </div>
        </div>
        );
        
    }    
}

export default ClickableText;