import './RescheduleHearingModal.css';
import React from "react";
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModaBody from './ModalBody';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphicStars from '../assets/icons/graphic-stars.svg';
import ModalFooter from "./ModalFooter";
import ClickableText from './ClickableText';
import Button from "./Button";
import DropdownSelect from './DropdownSelect';
import DatePickerInput from './DatePickerInput';
import ModalRoot from './ModalRoot';
import ModalService from '../services/ModalService';
import TimeWheel from './TimeWheel';
import http from '../http-common';
import {durations, defaultTimeArray, mutableTimeArray} from './DefaultData';
import RescheduleHearingConfirmationModal from './RescheduleHearingConfirmationModal';
import Loading from './Loading';
import UtilService from '../services/Utils';


export default function RescheduleHearingModal(props) {
    const {
        style, 
        containerStyle,
        meetingId,
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [isDurationSelected, setIsDurationSelected] = React.useState(false);
    const [isDateSelected, setIsDateSelected] = React.useState(false);
    const [meeting, setMeeting] = React.useState({});

    const getMeetingByMeetingId = async (meetingId) => {
      setIsLoading(true);
      if (!meetingId) {
          console.log('No meeting number.')
      } else {
          try {
              let response = await http.get('/get-meeting-by-id', {params: {meetingId: meetingId}});
              console.log(response.data);
              if (response.data[0]) {
                setMeeting(response.data[0]);
              } else {
                  setMeeting({});
              };
          } catch (err) {
              console.log(err);
          };
      };
      setIsLoading(false);
    };

    const [dateIsSelected, setDateIsSelected] = React.useState(false);
    const [durationIsSelected, setDurationIsSelected] = React.useState(false);

    const [availability, setAvailability] = React.useState({
      dateRange: [],
      duration: [],
      startDate: "",
      startTime: "",
      endTime: "",
      startTimeString: ""
    });

    const [timeWheelArray, setTimeWheelArray] = React.useState({});

    const findAvailability = async () => {
      setIsLoading(true);
      if (meeting) {
        setTimeWheelDuration(availability.duration);
        setTimeWheelDateRange(availability.dateRange);
        if (isDurationSelected && isDateSelected) {
          // do API call to determine what times are available here:
          let result = await getMeetingAvailability(availability.duration.split(' ')[0]);
          setTimeWheelArray(result);
          setTimeWheelShow(true);
        }
        else {
          alert('Please select a date range and duration.');
        }
      } else {
        alert('Please select a courtroom device before finding availability.');
      };
      setIsLoading(false);
    };

    const getMeetingAvailability = async (durationString) => {
      let tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
      let meetingAvailability = {};
      let busyTimes = [];
      let params = {
        courtroomDevice: meeting.courtroomDevice
      };
      try {
        let response = await http.get('/get-meeting-availability', {params: params});
        busyTimes = response.data;
        let busyKeys = Object.keys(busyTimes);
        console.log("busyTimes = ",busyTimes)
        console.log("busyKeys = ",busyKeys)
        availability.dateRange.forEach((key) => {
              var dateKeyString = new Date(key).toDateString()
              if(busyKeys.indexOf(dateKeyString) === -1){
                tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
                tempObject[durationString] = tempObject[durationString].filter((time) => 
                UtilService.hasTimeOccured(key,time.split('-')[0].trim()));
                meetingAvailability[dateKeyString] = tempObject;
              }
              else{
                tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
                busyTimes[dateKeyString].map((meeting) => {
                        tempObject[durationString] = (tempObject[durationString].filter((time) => 
                        UtilService.hasTimeOccured(key,time.split('-')[0].trim()) && 
                        !(time.split('-')[0].trim() >= meeting.startTime &&
                        time.split('-')[0].trim() < meeting.endTime ||
                        time.split('-')[1].trim() <= meeting.endTime &&
                        time.split('-')[1].trim() > meeting.startTime)));
                });
                meetingAvailability[dateKeyString] = tempObject;
              }
        });
        return meetingAvailability;
      } catch (err) {
        console.log(err);
      };
    };

    // helper functions for availability state
    const setDateRange = (selectedDateRange) => {
      setAvailability(current => ({
        ...current, dateRange: selectedDateRange
      }));
      if (selectedDateRange.includes(undefined)){
          setIsDateSelected(false);
      }
      else {
          setIsDateSelected(true);
      }
    };

    const setStartDate = (selectedStartDate) => {
      setAvailability(current => ({
        ...current, startDate: selectedStartDate
      }));
    };

    const setStartTime = (selectedStartTime) => {
      setAvailability(current => ({
        ...current, startTime: selectedStartTime
      }));
    };

    const setEndTime = (selectedEndTime) => {
      setAvailability(current => ({
        ...current, endTime: selectedEndTime
      }));
    };    

    const setStartTimeString = (selectedStartTimeString) => {
      setAvailability(current => ({
        ...current, startTimeString: selectedStartTimeString
      }));
    };    

    const setDuration = (selectedDuration) => {
      setAvailability(current => ({
        ...current, duration: selectedDuration
      }));
      setIsDurationSelected(true);
    };

    const [timeWheelParams, setTimeWheelParams] = React.useState({
      duration: "", 
      dateRange: [],
      show: false
    });

    // helper functions for timewheel params state
    const setTimeWheelDuration = (newDuration) => {
      setTimeWheelParams(current => ({
        ...current, duration: newDuration
      }));
    };

    const setTimeWheelDateRange = (newDateRange) => {
      setTimeWheelParams(current => ({
        ...current, dateRange: newDateRange
      }));
    };    

    const setTimeWheelShow = (bool) => {
      setTimeWheelParams(current => ({
        ...current, show: bool
      }));
    };

    const openRescheduleHearingConfirmationModal = () => {
        ModalService.open(RescheduleHearingConfirmationModal, {meeting: meeting, availability: availability});

    }

    React.useEffect(() => {
      if (meetingId) {
        let response = getMeetingByMeetingId(meetingId);
      };
    }, []);

    return (
        <Modal containerStyle={{minHeight: '723px', width: '963px', alignItems: 'center', boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
        style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
            <Loading type={'spinningBubbles'} color={'#003366'} 
                containerStyle={{display: isLoading ? "flex" : "none"}}/>
            <ModalRoot/> 
            <ModalHeader containerStyle={{display: 'flex', flexDirection: 'column', width: '372px', height: '348px', alignItems: 'center'}} onClick={props.close}>
                <div className="app__reschedulehearingmodal-header">
                    <div className="app__reschedulehearingmodal-header-logo">
                        <img src={SealLogo} alt="seal logo" />
                    </div>
                    <div className="app__reschedulehearingmodal-tagline">
                        <img src={LTagline} alt='tagline'/>
                    </div>
                    <div className="app__reschedulehearingmodal-graphicstars">
                        <img src={GraphicStars} alt="Graphic stars" />
                    </div>
                    <div className="app__reschedulehearingmodal-generic-inputbox" style={containerStyle}>
                        <div style={style}>RESCHEDULE HEARING</div>
                    </div>
                </div>
            </ModalHeader>
            <ModaBody containerStyle={{width: "963px", height: '100vh', flex: '0', paddingLeft: '8em'}}>
                <div className="app__reschedulehearingmodal-body-container">
                {meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' &&
                    <div className="app__reschedulehearingmodal-casenumber-container">
                        <div className="app__reschedulehearingmodal-casenumber-font">
                            <p>Case Number: </p>
                        </div>
                        <div className="app__reschedulehearingmodal-casenumber-number">
                          <p>{meeting.caseNumbers ? meeting.caseNumbers.join(', ') : "No case numbers found"}</p>
                        </div>
                    </div>}
                    <div className="app__reschedulehearingmodal-dateandtime">
                    <p>{meeting.startTime ? new Date(meeting.startTime).toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) + ' at ' + 
                                    new Date(meeting.startTime).toLocaleTimeString() : "No meeting start time found."}</p>
                    </div>
                </div>
                <div className="app__reschedulehearingmodal-lookupnewavailability-container">
                    <div className="app__reschedulehearingmodal-duration">
                        <DatePickerInput placeholder={"Select Date Range"}
                        containerStyle={{width: '230px', height: '4em', marginRight: '1em'}}
                        style={{height: '100%'}}
                        type="range"
                        setDateState={setDateRange}/>
                        <DropdownSelect title={"Duration*"} placeholder={"Select Duration"}
                        id={'duration'}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        dropdownOptions={durations}
                        setState={setDuration}/>
                        <Button text={"LOOK UP AVAILABILITY"} 
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{fontWeight: 'bold', width: '200px', backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                        onClick={findAvailability}/>
                    </div>
                    <div className='app__proposenewtimemodal-container-section-content' style={{display: (timeWheelParams.show) ? "flex" : "none", paddingTop: '1em'}}>
                        <div className='app__proposenewtimemodal-container-section-content-timewheel' style={{maxHeight: '300px', overflow: 'auto', border: '1px solid lightgray'}}>
                          <TimeWheel
                            selectedDuration={timeWheelParams.duration}
                            durationArray={defaultTimeArray[parseInt(timeWheelParams.duration).toString()]}
                            datesObject={timeWheelArray}
                            selectedStartDate={timeWheelParams.dateRange[0]}
                            selectedEndDate={timeWheelParams.dateRange[1]}
                            setState={[setStartDate, setStartTime, setEndTime, setStartTimeString]}/>
                        </div>
                    </div>
                </div>
            </ModaBody>
            <ModalFooter containerStyle={{marginTop: '0em', height: 'auto', borderTop: '0px'}}>
                <div className="app__reschedulehearingmodal-footer-container">
                    <div className="app__reschedulehearingmodal-footer-content">
                        <Button text={"RESCHEDULE VIRTUAL HEARING"}
                            style={{fontWeight: 'bold', height: '4em', width: '318px', 
                            font: 'normal normal bold 14px/19px Open Sans', letterSpacing: '0px', color: '#FFFFFF',
                             opacity: '1', backgroundColor: '#3576a5', border: '1px solid #3576a5'}}
                             originalColor='#3576a5' onHoverColor='#206392' onClick={ openRescheduleHearingConfirmationModal }/>
                        <ClickableText text={'CANCEL'} style={{marginTop: '1em', font: 'normal normal 600 14px/19px Open Sans',
                        letterSpacing: '1.25px', color: '#4392CD', opacity: '1'}} onClick = { props.close}/>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}
