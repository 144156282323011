import React from "react";
import './HearingDetails.css';
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModalBody from './ModalBody';
import ModalFooter from "./ModalFooter";
import ClickableText from './ClickableText';
import Button from "./Button";
import Table from './Table';
import http from '../http-common';
import UtilService from '../services/Utils';


const HearingDetails = (props) => {
    const {
        style, 
        containerStyle,
        hearingDetailsType,
        meetingId,
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [meetingParticipants, setMeetingParticipants] = React.useState([]);
    const [facilityParticipants, setFacilityParticipants] = React.useState([]);
    const [offender, setOffender] = React.useState([]);
    const [interpreter, setInterpreter] = React.useState([]);
    const [meeting, setMeeting] = React.useState({});

    const getMeetingByMeetingId = async (meetingId) => {
        setIsLoading(true);
        if (!meetingId) {
            console.log('No meeting ID.')
        } else {
            try {
                let response = await http.get('/get-meeting-by-id', {params: {meetingId: meetingId}});
                console.log(response.data);
                if (response.data[0]) {
                  setMeeting(response.data[0]);
                } else {
                    setMeeting({});
                };
            } catch (err) {
                console.log(err);
            };
        };
        setIsLoading(false);
      };

    const getMeetingDetails = async (meetingId) => {
        setIsLoading(true);
        try {
            await http.get('/get-meeting-facilityparticipants', {params: {meetingId: meetingId}}).then(response => {
                console.log(response);
                setFacilityParticipants(response.data);
            }).catch(e => {
                console.log(e);
            });
            await http.get('/get-meeting-offender', {params: {meetingId: meetingId}}).then(response => {
                console.log(response);
                setOffender(response.data);
            }).catch(e => {
                console.log(e);
            });
            await http.get('/get-meeting-interpreter', {params: {meetingId: meetingId}}).then(response => {
                console.log(response);
                setInterpreter(response.data);
            }).catch(e => {
                console.log(e);
            });

        } catch (err) {
            console.log(err);
        };
        setIsLoading(false);
    };

    const printDetails = () => {
        let html = `<!DOCTYPE html>
        <html lang="en">
        <head> `;
        html += document.head.innerHTML + "</head><body>";
        html += document.getElementById("modal-container").innerHTML + "</body></html>";
        let fakeIFrame = window.document.createElement("iframe");
        document.body.appendChild(fakeIFrame);
        let fakeContent = fakeIFrame.contentWindow;
        fakeContent.document.open();
        fakeContent.document.write(html);
        fakeContent.document.close();
        fakeContent.focus();
        fakeIFrame.addEventListener("load", () => {
            fakeContent.print();
            fakeIFrame.remove()
        });
    };

    React.useEffect(() => {
        console.log('HearingDetails useEffect render on initial load');
        getMeetingByMeetingId(meetingId);
        getMeetingDetails(meetingId);
      }, []);

    if (hearingDetailsType === 'upcoming') {
        return (
            <Modal containerStyle={{height: 'auto', width: 'auto', marginTop: '5em', alignItems: 'center', 
            boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
            style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
                <ModalHeader style={{width: '100%', height: '100%', marginTop: '50px', marginBottom: '50px'}}
                    containerStyle={{display: 'flex', width: '100%', height: 'auto',
                    alignItems: 'center', justifyContent: 'center'}} 
                    onClick={props.close}
                    borderStyle={{borderBottom: '0 !important'}}>
                                
                    <div className="app__hearingdetails-header">
                        <div className="app__hearingdetails-header-title">Upcoming Hearings Details</div>
                        {meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' && 
                        <div className="app__hearingdetails-header-subtitle"><b>Case Number(s): </b>
                            {meeting.caseNumbers ? meeting.caseNumbers.map((caseNumber, index) =>
                                    <div key={`${index}-${caseNumber}`}>{caseNumber}</div>
                                ) : "No case numbers found." }
                        </div>
                        }
                    </div>
     
                </ModalHeader>
                <ModalBody containerStyle={{width: "1400px", height: 'auto', paddingLeft: '50px', paddingRight: '50px'}}>
                    <div className='app__hearingdetails-body'>
                        <div className='app__hearingdetails-body-tableinfo'>
                            <div className='app__hearingdetails-body-tableinfo-courtroom'>
                                <b>Courtroom: </b> {meeting.courtroom ? meeting.courtroom : "No courtroom found."}
                            </div>
                            <div className='app__hearingdetails-body-tableinfo-date'>
                                <b>Time: </b> {(meeting.startTime ? UtilService.displayDate(meeting.startTime) : "No meeting start time found.")}
                            </div>
                            <div className='app__hearingdetails-body-right'>
                                <div className="app__hearingdetails-settings-right-cancel">
                                    <ClickableText text={'PRINT HEARING DETAILS'}
                                        style={{}}
                                        onClick={printDetails}
                                        image={"print"} />
                                </div>
                            </div>
                        </div>
                        
                        <div className='app__hearingdetails-body-table'>
                            <div className='app__hearingdetails-body-table-upcoming'>
                                <Table tableType={'upcominghearingdetails'} data={[...facilityParticipants, ...offender, ...interpreter]}/>
                            </div>                 
                        </div>
                    </div>
                    
                </ModalBody>
                <ModalFooter containerStyle={{marginTop: '0em', height: 'auto', borderTop: '0px'}}>
        
                </ModalFooter>
            </Modal>
        );
    }
    else if (hearingDetailsType === 'past') {
        console.log('past');
        return (
            <Modal containerStyle={{height: 'auto', width: 'auto', marginTop: '5em', alignItems: 'center', 
            boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
            style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
                <ModalHeader style={{width: '100%', height: '100%', marginTop: '50px', marginBottom: '50px'}}
                    containerStyle={{display: 'flex', width: '100%', height: 'auto',
                    alignItems: 'center', justifyContent: 'center'}} 
                    onClick={props.close}
                    borderStyle={{borderBottom: '0 !important'}}>
                                
                    <div className="app__hearingdetails-header">
                        <div className="app__hearingdetails-header-title">Past Hearings Details</div>
                        {meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' && 
                        <div className="app__hearingdetails-header-subtitle"><b>Case Number(s): </b>
                            {meeting.caseNumbers ? meeting.caseNumbers.map((caseNumber, index) =>
                                    <div key={`${index}-${caseNumber}`}>{caseNumber}</div>
                                ) : "No case numbers found." }
                        </div>
                        }
                    </div>
     
                </ModalHeader>
                <ModalBody containerStyle={{width: "1400px", height: 'auto', paddingLeft: '50px', paddingRight: '50px'}}>
                    <div className='app__hearingdetails-body'>
                        <div className='app__hearingdetails-body-tableinfo'>
                            <div className='app__hearingdetails-body-tableinfo-courtroom'>
                                <b>Courthouse: </b> {meeting.courtroom ? meeting.courtroom : "No courthouse found."}
                            </div>
                            <div className='app__hearingdetails-body-tableinfo-date'>
                            <b>Time: </b> {(meeting.startTime ? UtilService.displayDate(meeting.startTime) : "No meeting start time found.")}
                            </div>
                            <div className='app__hearingdetails-body-right'>
                                <div className="app__hearingdetails-settings-right-cancel">
                                    <ClickableText text={'PRINT HEARING DETAILS'}
                                        style={{}}
                                        onClick={printDetails}
                                        image={"print"} />
                                </div>
                            </div>
                        </div>
                        <div className='app__hearingdetails-body-table'>
                            <div className='app__hearingdetails-body-table-upcoming'>
                                <Table tableType={'pasthearingdetails'} data={[...facilityParticipants, ...offender, ...interpreter]}/>
                            </div>                 
                        </div>
                    </div>
                    
                </ModalBody>
                <ModalFooter containerStyle={{marginTop: '0em', height: 'auto', borderTop: '0px'}}>
        
                </ModalFooter>
            </Modal>
        );
    };  
};

export default HearingDetails;
