import './EditHearingConfirmationModal.css';
import React from "react";
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModaBody from './ModalBody';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphiStars from '../assets/icons/graphic-stars.svg';
import ModalFooter from "./ModalFooter";
import ClickableText from './ClickableText';
import Button from "./Button";
import ModalRoot from './ModalRoot';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import http from '../http-common';
import Loading from './Loading';


export default function EditHearingConfirmationModal(props) {
    const {
        style, 
        containerStyle,
        oldMeeting,
        currentOffender,
        newHearingInformation,
        newOffender,
        newAvailability,
        newCourtroomPeople,
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);

    const isBulkHearingEvent = () => {
        let hearingEventType = newHearingInformation.eventType
        return (hearingEventType === 'Bulk Hearing') ? true : false;
    }

    const isFirstAppearanceEvent = () => {
        let hearingEventType = newHearingInformation.eventType
        return (hearingEventType  === 'First Appearance') ? true : false;
    }

    const wasBulkHearingEvent = () => {
        let hearingEventType = oldMeeting.eventType
        return (hearingEventType === 'Bulk Hearing') ? true : false;
    }

    const wasFirstAppearanceEvent = () => {
        let hearingEventType = oldMeeting.eventType
        return (hearingEventType  === 'First Appearance') ? true : false;
    }
    const editMeeting = async () => {
        setIsLoading(true);
        let newTitle=""
        let offenderDisplayName="";
        
        if(!isBulkHearingEvent() && !isFirstAppearanceEvent())
        {
            newTitle = newHearingInformation.courtroom + ' hearing for  - Case #(s) ' + newHearingInformation.caseNumbers;
            offenderDisplayName = newOffender.firstName + ' ' + newOffender.lastName;

        }
        else{
            newTitle = newHearingInformation.eventType + " scheduled at "+newHearingInformation.courtroom;
        }
        console.log('edit meeting API call:')
        let webexParams = {
            title: newTitle,
            startTime: newAvailability.startTime,
            endTime: newAvailability.endTime,
            password: oldMeeting.password,
            meetingId: oldMeeting.meetingId,
            _id: oldMeeting._id
        };
        console.log('there are the webex params for edit meeting', webexParams);
        try {
            await http.post('/cancel-hearing', webexParams).then(response => {
                console.log(response);
                if(response.data.error) {
                    console.log(response.data.error);
                    alert(response.data.error.message + 'Meeting was not able to be edit.');
                }
                else {
                    console.log(response.data);
                    let mongoParams = {
                        _id: oldMeeting._id,
                        meetingId: response.data.meetingId,
                        meetingNumber: response.data.meetingNumber,
                        startTime: newAvailability.startTime,
                        caseNumbers: (isFirstAppearanceEvent() || isBulkHearingEvent()) ? "" : newHearingInformation.caseNumbers,
                        eventType: newHearingInformation.eventType,
                        description: newHearingInformation.description,
                        courtroom: newHearingInformation.courtroom,
                        courtroomDevice: newHearingInformation.courtroomDevice,
                        facilityName: newOffender.facilityName,
                        offenderDisplayName: offenderDisplayName,
                        districtAttorneyName: (newCourtroomPeople.districtAttorneys.length > 0) ? newCourtroomPeople.districtAttorneys[0].displayName : "",
                        hearingStatus: "Pending",
                        result: "",
                        webLink: response.data.webLink,
                        password: response.data.password,
                        title: newTitle,
                        endTime: newAvailability.endTime
                    };
                    console.log('Edit meeting', mongoParams);
                    http.put('/update-meeting', mongoParams).then(response => {
                        if(response.data.error) {
                            console.log(response.data.error);
                            alert(response.data.error.message + '\nMeeting was not able to be saved to the database.');
                        }
                        else {
                            console.log(response);
                        };
                    });
                    if(!isFirstAppearanceEvent() && !isBulkHearingEvent() && !wasFirstAppearanceEvent() && !wasBulkHearingEvent())
                    {
                        let updateOffenderParams = {
                            _id: currentOffender._id,
                            meetingId: mongoParams.meetingId,
                            firstName: newOffender.firstName,
                            middleName: newOffender.middleName,
                            lastName: newOffender.lastName,
                            dateOfBirth: newOffender.dateOfBirth,
                            facilityName: newOffender.facilityName,
                            facilityOwnerEmail: newOffender.facilityOwnerEmail
                        };
                        console.log('Udpate Offender params in edit meeting', updateOffenderParams)
                        http.put('/update-offender', updateOffenderParams).then(response => {
                            if(response.data.error) {
                                console.log('update offender error', response.data.error);
                                alert(response.data.error.message + '\nMeeting offender were not able to be updated to the database.');
                            } else {
                                console.log('update offender success', response);
                            };
                        });
                    }
                    if(!isFirstAppearanceEvent() && !isBulkHearingEvent() && (wasFirstAppearanceEvent() || wasBulkHearingEvent())){
                        let newOffenderParams = {
                            meetingId: mongoParams.meetingId,
                            firstName: newOffender.firstName,
                            middleName: newOffender.middleName,
                            lastName: newOffender.lastName,
                            dateOfBirth: newOffender.dateOfBirth,
                            facilityName: newOffender.facilityName,
                            facilityOwnerEmail: newOffender.facilityOwnerEmail
                        };
                        console.log('New Offender params in edit meeting', newOffenderParams)
                        http.post('/save-offender', newOffenderParams).then(response => {
                            if (response.data.error) {
                                console.log(response.data.error);
                                alert(response.data.error.message + '\nMeeting offender was not able to be saved to the database.');
                            }
                            else {
                                console.log(response);
                            };
                         });
                    }
                    http.post('/remove-meeting-participants', {params: {meetingId: mongoParams.meetingId}}).then(response => {
                        console.log(response);
                        if(response.data.error) {
                            console.log('Removing old facility participants error', response.data.error);                        }
                        else {
                            console.log('Removed old facility participants', response);
                        };
                    })
                    http.post('/save-facility-participants', newCourtroomPeople).then(response => {
                        if (response.data.error) {
                            console.log(response.data.error);
                            alert(response.data.error.message + '\nNew Courtroom People was not able to be saved to the database.');
                        }
                        else {
                            console.log(response);
                        };
                    });
                    let emailParams = {
                        facilityName: newOffender.facilityName,
                        meetingId: response.data.meetingId,
                        eventType: newHearingInformation.eventType,
                        courtroom: newHearingInformation.courtroom,
                        courtroomDevice: newHearingInformation.courtroomDevice,
                        startTime: newAvailability.startTime,
                        endTime: newAvailability.endTime,
                        offenderDisplayName: offenderDisplayName,
                        facilityOwnerEmail: newOffender.facilityOwnerEmail
                    };
                    http.post('/send-email-notification', emailParams).then(response => {
                        if (response.data.error) {
                            console.log(response.data.error);
                            alert(response.data.error.message + '\nEmail notification was unable to be sent.');
                        }
                        else {
                            console.log(response);
                        };
                    });
                    setIsLoading(false);
                    alert('Webex meeting: "' + response.data.title + '" was successfully edited!');
                    window.location.reload();
                }
            }).catch(e => {
                console.log('edit meeting catch outside', e);
                alert(e);
            });
        } catch (err) {
            alert(err);
            console.log(err);
        };
        setIsLoading(false);
    };

    React.useEffect(() => {
        console.log('useEffect render on initial load');
      }, []);

    return (
        <Modal containerStyle={{minHeight: '689px', width: '515px', marginTop: '5em', alignItems: 'center', boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
            style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
            <Loading type={'spinningBubbles'} color={'#003366'} 
                containerStyle={{display: isLoading ? "flex" : "none"}}/>
            <ModalRoot/>
            <ModalHeader containerStyle={{display: 'flex', flexDirection: 'column', width: '372px', height: '348px', alignItems: 'center'}} onClick={props.close}>
                <div className="app__editHearingconfirmationmodal-header">
                    <div className="app__editHearingconfirmationmodal-header-logo">
                        <img src={SealLogo} alt="seal logo" />
                    </div>
                    <div className="app__editHearingconfirmationmodal-tagline">
                        <img src={LTagline} alt='tagline'/>
                    </div>
                    <div className="app__editHearingconfirmationmodal-graphicstars">
                        <img src={GraphiStars} alt="Graphin stars" />
                    </div>
                    <div className="app__editHearingconfirmationmodal-generic-inputbox" style={containerStyle}>
                        <div style={style}>ARE YOU SURE YOU WANT TO EDIT THIS HEARING?</div>
                    </div>
                </div>
            </ModalHeader>
            <ModaBody containerStyle={{width: "300px", height: '200px', flex: '0'}}>
                <div className="app__editHearingconfirmationmodal-body-container">
                    {isBulkHearingEvent() && isFirstAppearanceEvent() && 
                    <div className="app__editHearingconfirmationmodal-casenumber-container">
                        <div className="app__editHearingconfirmationmodal-casenumber-font">
                            <p>Case Number: </p>
                        </div>
                        <div className="app__editHearingconfirmationmodal-casenumber-number">
                            <p>{oldMeeting.caseNumbers ? oldMeeting.caseNumbers.join(', ') : 'No case number found'}</p>
                        </div>
                    </div>}
                    <div className="app__editHearingconfirmationmodal-dateandtime">
                        <p>{(new Date(oldMeeting.startTime)).toString()}</p>
                    </div>
                </div>
            </ModaBody>
            <ModalFooter containerStyle={{marginTop: '3em', height: 'auto', borderTop: '0px'}}>
                <div className="app__editHearingconfirmationmodal-footer-container">
                    <div className="app__editHearingconfirmationmodal-footer-content">
                        <Button text={"YES, EDIT"}
                            style={{fontWeight: 'bold', height: '4em', width: '318px', 
                            font: 'normal normal bold 14px/19px Open Sans', letterSpacing: '0px', color: '#FFFFFF',
                             opacity: '1', backgroundColor: '#3576a5', border: '1px solid #3576a5'}}
                             originalColor='#3576a5' onHoverColor='#206392' 
                             onClick={editMeeting}/>
                        <ClickableText text={'CANCEL'} style={{marginTop: '1em', font: 'normal normal 600 14px/19px Open Sans',
                        letterSpacing: '1.25px', color: '#4392CD', opacity: '1'}} onClick = { props.close}/>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}
