import React from "react";
import ReusableLogoHeader from './ReusableLogoHeader';
import Button from "./Button";
import Navbar from "./Navbar";
import './WebexLinkConfirmation.css'
import { useCookies } from "react-cookie";
import LoginPage from './LoginPage';


const WebexLinkConfirmation = () => {
    const [userCookie, setUserCookie] = useCookies();
    const [webexLinkConfirmation, setWebexLinkConfirmation] = React.useState(false);

    React.useEffect(() => {
        if (!userCookie.userProfile) {
            return;
        } else {
            console.log('WebexLinkConfirmation page useEffect render on initial load');
        };
    }, []);

    if (userCookie.userProfile && userCookie.userProfile.emails[0].split('@')[1] === 'nccourts.org') {
        return (
            <div className="app__webexlinkconfirmation">
                <div className='app__webexlinkconfirmation-navbar'>
                    <Navbar/>
                </div>
                <div className="app__webexlinkconfirmation-main-container">
                    <div className="app__webexlinkconfirmation-main-page">
                        <ReusableLogoHeader text={"CONFIRMATION"} />
                        <div className="app__webexlinkconfirmation-header">
                            <p>Scheduled Hearing Details</p>
                        </div>
                        <div className="app__webexlinkconfirmation-container">
                            <div className="app__webexlinkconfirmation-confirmationpage-number">
                                <p><b>Confirmation Number:</b> 12-CF-4324234234234-A</p>
                            </div>
                            <div className="app__webexlinkconfirmation-border"></div>
                            <div className="app__webexlinkconfirmation-date-and-time">
                                <p><b>Date and Time:</b> 07/06/2022 on Friday at 8:30am</p>
                            </div>
                            <div className="app__webexlinkconfirmation-border"></div>
                            <div className="app__webexlinkconfirmation-event-type">
                                <p><b>Event Type:</b> Jury Trial</p>
                            </div>
                            <div className="app__webexlinkconfirmation-border"></div>
                            <div className="app__webexlinkconfirmation-type">
                                <p><b>Type:</b> Felony Other Felony</p>
                            </div>
                            <div className="app__webexlinkconfirmation-border"></div>
                            <div className="app__webexlinkconfirmation-description">
                                <p><b>Description:</b> Lorem ipsum lorem ipsum lorem ipsum.</p>
                            </div>
                            <div className="app__webexlinkconfirmation-border"></div>
                            <div className="app__webexlinkconfirmation-contact-details">
                                <p><b>Contact:</b> Firstname Lastname</p>
                                <p><b>Phone Number:</b> (555) 555-5555</p>
                                <p><b>Email:</b> firstname@email.com</p>
                            </div>
                        </div>
                        <div className="app__webexlinkconfirmation-header-two">
                            <p>Webex Meeting Invitation</p>
                        </div>
                        <div className="app__webexlinkconfirmation-meetinginfo-container">
                            <div className="app__webexlinkconfirmation-meetinginfo-join-container">
                                <div className="app__webexlinkconfirmation-meetinginfo-join-text">
                                    <p><b>When it's time, join your Webex meeting here.</b></p>
                                </div>
                                <div className="app__webexlinkconfirmation-meetinginfo-join-button">
                                <Button text={"JOIN MEETING"} 
                                    containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                                    style={{fontWeight: 'bold', width: '144px', backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}} originalColor='#3576a5' onHoverColor='#206392'/>
                                </div>
                            </div>
                            <div className="app__webexlinkconfirmation-border"></div>
                            <div className="app__webexlinkconfirmation-meetinginfo-link-container">
                                <div className="app__webexlinkconfirmation-meetinginfo-link-header">
                                    <p>More Ways to join:</p>
                                </div>
                                <div className="app__webexlinkconfirmation-meetinginfo-link-section">
                                    <div className="app__webexlinkconfirmation-meetinginfo-link-section-text">
                                    <p><b>Join from the meeting link:</b></p>
                                    </div>
                                    <div className="app__webexlinkconfirmation-meetinginfo-link-section-link">
                                        <p>https://webex.com/j.php?MTID=m52ec41765d3ddc887c7a3a4a1d57689a</p>
                                    </div>
                                </div>
                                <div className="app__webexlinkconfirmation-meetinginfo-number-section">
                                    <div className="app__webexlinkconfirmation-meetinginfo-number-section-text">
                                    <p><b>Join by meeting number:</b></p>
                                    </div>
                                    <div className="app__webexlinkconfirmation-meetinginfo-number-section-container-accesscode">
                                            <p>Meeting number (access code): 2431 909 1615</p>
                                    </div>
                                    <div className="app__webexlinkconfirmation-meetinginfo-number-section-container-password">
                                            <p>Meeting password: jM4pTjMZy22</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>       
        );
    } else {
        return (
            <LoginPage loginRequest={true}/>
        );
    };
};

export default WebexLinkConfirmation;