import ClickableText from './ClickableText';
import './ModalHeader.css';

export default function ModalHeader(props) {
    const {
        style,
        containerStyle,
        onClick
    } = props;
    return (
        <div className="modal-header" style={style}>
            <div className="modal-header-main" style={containerStyle}>
                <div className="modal-header-cancel-button" onClick={onClick}>
                    <ClickableText  image={ "cancel" } />
                </div>
                <div className="modal-header-content">
                    { props.children }
                </div>
            </div>
        </div>
    );
}