import React from 'react';
import _ from "lodash";
import './ToggleSwitch.css';


const ToggleSwitch = ({title, style, containerStyle, initialChecked, isDisplay, setIsDisplay, setCheckedState}) => {
    const [toggleSwitch, setToggleSwitch] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(initialChecked);
    
    const handleChange = (e) => {
        const { checked } = e.target;
        setIsChecked(checked);
        console.log("checked " + checked);
        setIsDisplay(!isDisplay);
        setCheckedState(false);
    };

    return (
        <div className='app__toggleswitch' style={containerStyle}>
            <label className='app__toggleswitch-switch'>
                <input type='checkbox' name={title} id={title} onChange={handleChange} 
                checked={isChecked}/>
                <span className='app__toggleswitch-switch-slider' style={style}></span>
            </label>
        </div>
        
    )
}

export default ToggleSwitch;