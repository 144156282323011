import React from 'react';
import _ from "lodash";
import './DropdownSelect.css';

const DropdownSelect = ({title, placeholder, id, style, containerStyle, dropdownType, dropdownOptions, isDisabled=false, setState, setIsSelected}) => {
    const handleChange = (e) => {
      document.getElementById(id).style['color'] = 'black';
      setState(e.target.value);
      if (setIsSelected) {
        handleIsSelected(e.target.value);
      };
    };

    const handleIsSelected = (deviceName) => {
      document.getElementById(id).style['color'] = 'black';
      setIsSelected({
        isSelected: true,
        deviceName: deviceName
      });
    };

    const handleParticipantChange = (e) => {
      document.getElementById(id).style['color'] = 'black';
      setState(dropdownOptions[e.target.value]);
      if (setIsSelected) {
        handleIsSelected(e.target.value);
      };
    };

    const handleFacilityChange = (e) => {
      document.getElementById(id).style['color'] = 'black';
      setState[0](e.target.value);
      setState[1](e.target.value);
    }

    const defaultOptions = [
      {option: "Default option 1"},
      {option: "Default option 2"},
      {option: "Default option 3"},
      {option: "Default option 4"},
    ]

    if (dropdownOptions) {
      if (dropdownType === 'courtrooms') {
        return (
          <div className='app__dropdownselect' style={containerStyle}>
            <div className='app__dropdownselect-title'>{title}</div>
            <div className='app__dropdownselect-select'>
                <select id={id} style={style} onChange={handleChange} defaultValue={placeholder} required disabled={isDisabled}>
                    <option disabled hidden>{placeholder}</option>
                    {dropdownOptions && Array.from(dropdownOptions).map((dropdownOption, index) => 
                      <option key={`${index}-${dropdownOption}`} value={index}>{dropdownOption.displayName}</option>)}
                </select>
            </div>
          </div>
        );
      }
      else if (dropdownType === 'facility') {
        return (
          <div className='app__dropdownselect' style={containerStyle}>
            <div className='app__dropdownselect-title'>{title}</div>
            <div className='app__dropdownselect-select'>
                <select id={id} style={style} onChange={handleFacilityChange} defaultValue={placeholder} required disabled={isDisabled}>
                    <option disabled hidden>{placeholder}</option>
                    {dropdownOptions && Array.from(dropdownOptions).map((dropdownOption, index) => 
                      <option key={`${index}-${dropdownOption}`} value={index}>{dropdownOption.displayName}</option>)}
                </select>
            </div>
          </div>
        );
      }
      else if (dropdownType === 'participant') {
        return (
          <div className='app__dropdownselect' style={containerStyle}>
            <div className='app__dropdownselect-title'>{title}</div>
            <div className='app__dropdownselect-select'>
                <select id={id} style={style} onChange={handleParticipantChange} defaultValue={placeholder} required disabled={isDisabled}>
                    <option disabled hidden>{placeholder}</option>
                    {dropdownOptions && Array.from(dropdownOptions).map((dropdownOption, index) => 
                      <option key={`${index}-${dropdownOption}`} value={index}>{dropdownOption.displayName}</option>)}
                </select>
            </div>
          </div>
        );
      }
      else {
        return (
          <div className='app__dropdownselect' style={containerStyle}>
            <div className='app__dropdownselect-title'>{title}</div>
            <div className='app__dropdownselect-select'>
                <select id={id} style={style} onChange={handleChange} defaultValue={placeholder} required disabled={isDisabled}>
                    <option disabled hidden>{placeholder}</option>
                    {dropdownOptions && Array.from(dropdownOptions).map((dropdownOption, index) => 
                      <option key={`${index}-${dropdownOption}`} value={dropdownOption.displayName}>{dropdownOption.displayName}</option>)}
                </select>
            </div>
          </div>
        );
      }
    }
  }

export default DropdownSelect;