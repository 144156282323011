import './CancelHearingModal.css';
import React from "react";
import http from '../http-common';
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModaBody from './ModalBody';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphiStars from '../assets/icons/graphic-stars.svg';
import ModalFooter from "./ModalFooter";
import ClickableText from './ClickableText';
import Button from "./Button";
import DropdownSelect from './DropdownSelect';
import TextArea from './TextArea';
import ModalService from '../services/ModalService';
import ModalRoot from './ModalRoot';
import CancelHearingConfirmationModal from './CancelHearingConfirmationModal';
import { cancelMeetingReasonOptions } from './DefaultData';
import Loading from './Loading';


export default function CancelHearingModal(props) {
    const {
        style, 
        containerStyle,
        meetingId,
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedReason, setSelectedReason] = React.useState("");
    const [changedDescription, setChangedDescription] = React.useState("");
    const [meeting, setMeeting] = React.useState({});
    const [cancellationReasonOptions, setCancellationReasonOptions] = React.useState([]);
    
    const getCancellationReason = async() => {
        let response = await http.get('/get-cancellationreason');
        console.log('cancellation reason',response.data);
        setCancellationReasonOptions(response.data);
    }


    const getMeetingByMeetingId = async (meetingId) => {
        setIsLoading(true);
        if (!meetingId) {
            console.log('No meeting number.')
        } else {
            try {
                let response = await http.get('/get-meeting-by-id', {params: {meetingId: meetingId}});
                console.log(response.data);
                if (response.data[0]) {
                  setMeeting(response.data[0]);
                } else {
                    setMeeting({});
                };
            } catch (err) {
                console.log(err);
            }
        };
        setIsLoading(false);
      };

    const openCancelHearingConfirmationModal = () => {
        ModalService.open(CancelHearingConfirmationModal, {
            meeting: meeting,
            selectedReason: selectedReason,
            changedDescription: changedDescription
        });
    };

    React.useEffect(() => {
        if (meetingId) {
          let response = getMeetingByMeetingId(meetingId);
        };
        getCancellationReason();
      }, []);

    return (
        <div>
            <Modal containerStyle={{height: '888Px', width: '515px', marginTop: '2em', alignItems: 'center', boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
                style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
                <Loading type={'spinningBubbles'} color={'#003366'} 
                    containerStyle={{display: isLoading ? "flex" : "none"}}/>
                <ModalRoot/>
                <ModalHeader containerStyle={{display: 'flex', flexDirection: 'column', width: '372px', height: '348px', alignItems: 'center'}} onClick={props.close}>
                    <div className="app__cancelHearingnmodal-header">
                        <div className="app__cancelHearingnmodal-header-logo">
                            <img src={SealLogo} alt="seal logo" />
                        </div>
                        <div className="app__cancelHearingnmodal-tagline">
                            <img src={LTagline} alt='tagline'/>
                        </div>
                        <div className="app__cancelHearingnmodal-graphicstars">
                            <img src={GraphiStars} alt="Graphin stars" />
                        </div>
                        <div className="app__cancelHearingnmodal-generic-inputbox" style={containerStyle}>
                            <div style={style}>CANCEL HEARING</div>
                        </div>
                    </div>
                </ModalHeader>
                <ModaBody containerStyle={{width: "372px", height: '100vh', flex: '0'}}>
                    <div className="app__cancelHearingnmodal-body-container">
                        <div className="app__cancelHearingnmodal-case-details" style={{marginLeft: '1em'}}>
                        {meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' &&
                            <div className="app__cancelHearingnmodal-casenumber-container">
                                <div className="app__cancelHearingnmodal-casenumber-font">
                                    <p>Case Number: </p>
                                </div>
                                <div className="app__cancelHearingnmodal-casenumber-number">
                                    <div>{meeting.caseNumbers ? meeting.caseNumbers.map((caseNumber, index) =>
                                        <div key={`${index}-${caseNumber}`}>{caseNumber}</div>
                                    ) : "No case numbers found." }</div>
                                </div>
                            </div>
                        }
                            <div className="app__cancelHearingnmodal-dateandtime">
                                <p>{meeting.startTime ? new Date(meeting.startTime).toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) + ' at ' + 
                                new Date(meeting.startTime).toLocaleTimeString() : "No meeting start time found."}</p>
                            </div>
                        </div>
                        <div className="app__cancelHearingnmodal-input-container">
                            <div className="app__cancelHearingnmodal-reason">
                                <DropdownSelect title={"Reason*"} placeholder={"Select your reason to cancel meeting"}
                                    id={'reason'}
                                    containerStyle={{marginRight: '1em', marginBottom: '0.5em'}}
                                    style={{width: '318px', height: '49px'}}
                                    dropdownOptions={cancelMeetingReasonOptions}
                                    setState={setSelectedReason}/>
                            </div>
                            <div className="app__cancelHearingnmodal-description">
                                <TextArea title={"Description"} placeholder={"Add description here"}
                                    containerStyle={{width: 'auto', minWidth: '60%', marginBottom: '1em'}}
                                    style={{width: '100%', minWidth: '318px', minHeight: '10em'}}
                                    setState={setChangedDescription}/>
                            </div>
                        </div>
                    </div>
                </ModaBody>
                <ModalFooter containerStyle={{marginTop: '1em', height: 'auto', borderTop: '0px', marginRight: '0.5em'}}>
                {/* <ModalFooter containerStyle={{width: '350px', height: '100px', alignItems: 'center'}}> */}
                    <div className="app__cancelHearingnmodal-footer-container">
                        <div className="app__cancelHearingnmodal-footer-content">
                            <Button text={"CANCEL VIRTUAL HEARING"}
                                // containerStyle={{marginLeft: '2em', marginBottom: '1em'}}
                                style={{fontWeight: 'bold', height: '4em', width: '318px', 
                                font: 'normal normal bold 14px/19px Open Sans', letterSpacing: '0px', color: '#FFFFFF',
                                opacity: '1', backgroundColor: '#3576a5', border: '1px solid #3576a5'}}
                                originalColor='#3576a5' onHoverColor='#206392' onClick={ openCancelHearingConfirmationModal }/>
                            <ClickableText text={'DO NOT CANCEL'} style={{marginTop: '1em', font: 'normal normal 600 14px/19px Open Sans',
                            letterSpacing: '1.25px', color: '#4392CD', opacity: '1'}} onClick = { props.close}/>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}
