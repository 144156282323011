import React from 'react';
import _ from "lodash";
import './Pill.css';

import CancelIcon from '../assets/icons/close-circle.svg';

const Pill = ({text, caseNumbers, setCaseNumbers}) => {

    const handleDelete = (e) => {
        const currentCaseNumber = document.getElementById(text).innerHTML;
        setCaseNumbers(currentCaseNumber)
    };

    return (
        <div className='app__pill'>
            <div className='app__pill-text' id={text} value={text}>
                {text}
            </div>
            <div className='app__pill-icon'>
                <img src={CancelIcon} onClick={handleDelete}/>
            </div>
        </div>
    )
}

export default Pill;