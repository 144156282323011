import React from 'react';
import _ from "lodash";
import './InputField.css';

import AddIcon from '../assets/icons/add-icon.svg';


const InputField = ({title, placeholder, maxLength, style, containerStyle, caseNumbersField, setCaseNumbers, setState}) => {
    const [inputValue, setInputValue] = React.useState("");

    const handleChange = (e) => {
      if (maxLength) {
        if (e.target.value.length > maxLength) {
          document.getElementById(title).style['color'] = 'red';
          return;
        } else {
          document.getElementById(title).style['color'] = 'black';
          setInputValue(e.target.value);
          if (setState) {
            setState(e.target.value);
          };
        } 
      } else {
        setInputValue(e.target.value);
        if (setState) {
          setState(e.target.value);
        };
      }
    }

    const handleCaseNumberSubmit = (e) => {
      e.preventDefault();
      if (document.getElementById(title).value.length > 15) {
        document.getElementsByClassName("app__inputfield-form-warning")[0].style['display'] = 'block';
        return;
      };
      document.getElementsByClassName("app__inputfield-form-warning")[0].style['display'] = 'none';
      const newCaseNumber = document.getElementById(title).value.trim();
      console.log('this is new case number' , newCaseNumber);
      if (newCaseNumber.length === 0) {
        setInputValue("");
        return;
      }
      else {
        setCaseNumbers(newCaseNumber);
        setInputValue("");
      };
    };

    if (caseNumbersField) {
      return (
        <div className='app__inputfield' style={containerStyle}>
          <div className='app__inputfield-title'>{title}</div>
          <div className='app__inputfield-form-icon'>
            <form onSubmit={handleCaseNumberSubmit}>
              <input type="text" name={title} id={title} placeholder={placeholder} style={style} value={inputValue} onChange={handleChange}/>
                <span><img src={AddIcon} onClick={handleCaseNumberSubmit} width="30px" height="30px"/></span>
            </form>
            <div className='app__inputfield-form-warning' style={{color: 'red', fontSize: '13px', display: 'none'}}>
              *Case Number cannot be longer than 15 character.
              </div>
          </div>
        </div>
      );
    }
    else {
      if (title.includes("Password")) {
        return (
          <div className='app__inputfield' style={containerStyle}>
            <div className='app__inputfield-title'>{title}</div>
            <div className='app__inputfield-form'>
                  <input id={title} type="text" name={title} placeholder={placeholder} style={style}
                  onChange={handleChange} value={inputValue}/>
            </div>
          </div>
        );
      } else {
        return (
          <div className='app__inputfield' style={containerStyle}>
            <div className='app__inputfield-title'>{title}</div>
            <div className='app__inputfield-form'>
                  <input id={title} type="text" name={title} placeholder={placeholder} style={style}
                  onChange={handleChange} value={inputValue}/>
            </div>
          </div>
        );
      }
    }
  }

export default InputField;
