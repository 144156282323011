import React from "react";
import Modal from './Modal.jsx';
import ModalBody  from './ModalBody.jsx';
import ModalHeader from './ModalHeader.jsx';
import ModalFooter from './ModalFooter.jsx';
import './FeedbackModal.css';
import http from '../http-common';
import TextArea from './TextArea';

export default function FeedbackModal(props) {
    const {userProfile} = props;
    const [feedback, setFeedback] = React.useState("");
    // const [isLoading, setIsLoading] = React.useState(false);

    const sendFeedback = async () => {
      // setIsLoading(true);
      const feedbackParam = {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.userName,
        feedback: feedback
      }
      try {
        http.post('/feedback', feedbackParam).then(response => {
            console.log(response);
            if (response.data.error) {
                console.log(response.data.error);
                // setIsLoading(false);
                alert(response.data.error.message + '\nFeedback is not available at this time');
            } else {
                console.log(response);
                if (response.status === 200) {
                    // setIsLoading(false);
                    alert('Success code: ' + response.status + '\nFeedback Received!');
                    window.location.reload();
                };
            };
        });
    } catch (err) {
        // setIsLoading(false);
        alert(err);
        console.log(err);
  };
    };
    
    return (
        <Modal containerStyle={{height: '650px', minHeight: '400px', width: '715px', marginTop: '5em', alignItems: 'center', boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
        style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
          <div className="app__feedbackHeader">
            <ModalHeader>
                <h2> Leave Feedback </h2>
            </ModalHeader>
            </div>
            <ModalBody>
              <div className="app__cancelHearingnmodal-description">
                <TextArea title={"Your Feedback Is Important!"} placeholder={"Add description here"}
                    containerStyle={{height: 'auto', width: 'auto', minWidth: '100%', marginBottom: '1em'}}
                    style={{height: '200px', width: '600px', minWidth: '600px', minHeight: '10em'}}
                    setState={setFeedback}/>
              </div>
            </ModalBody>
            <ModalFooter>
                <button onClick={ sendFeedback } className="btn  btn-primary">Send Feedback</button>
                <button onClick={ props.close } className="btn  btn-primary">Cancel</button>
            </ModalFooter>
        </Modal>
    );
}
