// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalRoot_modalRoot__b4ifx {\n    position: fixed;\n    top: 0;\n    z-index: 50;\n    width: 100vw;\n    height: 100vw;\n    /* background: #0E2133CC 0% 0% no-repeat padding-box; */\n    background: #0E2133CC;\n    opacity: 2;\n}", "",{"version":3,"sources":["webpack://./src/components/ModalRoot.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uDAAuD;IACvD,qBAAqB;IACrB,UAAU;AACd","sourcesContent":[".modalRoot {\n    position: fixed;\n    top: 0;\n    z-index: 50;\n    width: 100vw;\n    height: 100vw;\n    /* background: #0E2133CC 0% 0% no-repeat padding-box; */\n    background: #0E2133CC;\n    opacity: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalRoot": "ModalRoot_modalRoot__b4ifx"
};
export default ___CSS_LOADER_EXPORT___;
