import React from 'react';
import http from '../http-common';
import { useNavigate } from "react-router-dom";
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import DropdownSelect from './DropdownSelect';
import InputField from './InputField';
import TextArea from './TextArea';
import Button from './Button';
import Pill from './Pill';
import DatePickerInput from './DatePickerInput';
import DropdownSelectCheckbox from './DropdownSelectCheckbox';
import TimeWheel from './TimeWheel';
import {eventTypes, participantTypes, participantLanguages, participantCounties, durations, defaultTimeArray, mutableTimeArray} from './DefaultData';
import Loading from './Loading';
import moment from 'moment';
import { useCookies } from "react-cookie";
import LoginPage from './LoginPage';
import "./AdminPage.css";
import CRUDTable, {
    Fields,
    Field,
    CreateForm,
    UpdateForm,
    DeleteForm
  } from "react-crud-table";
const AdminPage = () => {

let navigate = useNavigate();
const [userCookie, setUserCookie] = useCookies();

const DescriptionRenderer = ({ field }) => <textarea {...field} />;


const SORTERS = {
NUMBER_ASCENDING: mapper => (a, b) => mapper(a) - mapper(b),
NUMBER_DESCENDING: mapper => (a, b) => mapper(b) - mapper(a),
STRING_ASCENDING: mapper => (a, b) => mapper(a).localeCompare(mapper(b)),
STRING_DESCENDING: mapper => (a, b) => mapper(b).localeCompare(mapper(a)),
};

const getSorter = (data) => {
  const mapper = x => x[data.field];
  let sorter = SORTERS.STRING_ASCENDING(mapper);

  if (data.field === 'id') {
    sorter = data.direction === 'ascending' ?
      SORTERS.NUMBER_ASCENDING(mapper) : SORTERS.NUMBER_DESCENDING(mapper);
  } else {
    sorter = data.direction === 'ascending' ?
      SORTERS.STRING_ASCENDING(mapper) : SORTERS.STRING_DESCENDING(mapper);
  }

  return sorter;
};

const individualService = {
    fetchItems: async (payload) => {
      let result = [];
      try {
        await http.get('/get-individuals').then(response => {
          result = response.data;
        }).catch(e => {
          console.log(e);
        });
      } catch (err) {
        console.log(err);
      };
      result = result.sort(getSorter(payload.sort));
      return Promise.resolve(result);
    },
    create: async (individual) => {
        individual.status = "Active"
        individual.__v = "0"
        try {
            await http.post('/add-courtroom-person',individual).then(response => {
                console.log(response);
            }).catch(e => {
                console.log(e);
            });
            } catch (err) {
            console.log(err);
            };
        
      return Promise.resolve(individual);
    },
    update: async (individual) => {
        individual.status = "Active"
        individual.__v = "0"
        try {
            await http.put('/courtroom-person',individual).then(response => {
                console.log(response);
            }).catch(e => {
                console.log(e);
            });
            } catch (err) {
            console.log(err);
            };
        
      return Promise.resolve(individual);
    },
    delete: async (individual) => {
        individual.status = "Active"
        individual.__v = "0"
        try {
            await http.delete('/courtroom-person',individual).then(response => {
                console.log(response);
            }).catch(e => {
                console.log(e);
            });
            } catch (err) {
            console.log(err);
            };
        
      return Promise.resolve(individual);
    }

  };

  const facilityService = {
    fetchItems: async (payload) => {
      let result = [];
      try {
        await http.get('/get-facilities').then(response => {
          result = response.data;
        }).catch(e => {
          console.log(e);
        });
      } catch (err) {
        console.log(err);
      };
      result = result.sort(getSorter(payload.sort));
      return Promise.resolve(result);
    },
    create: async (facility) => {
        facility.__v = "0"
        try {
            await http.post('/add-facilities',facility).then(response => {
                console.log(response);
            }).catch(e => {
                console.log(e);
            });
            } catch (err) {
            console.log(err);
            };
        
      return Promise.resolve(facility);
    },
    update: async (facility) => {
        facility.__v = "0"
        try {
            await http.put('/facilities',facility).then(response => {
                console.log(response);
            }).catch(e => {
                console.log(e);
            });
            } catch (err) {
            console.log(err);
            };
        
      return Promise.resolve(facility);
    },
    delete: async (facility) => {
        facility.__v = "0"
        try {
            await http.delete('/facilities',facility).then(response => {
                console.log(response);
            }).catch(e => {
                console.log(e);
            });
            } catch (err) {
            console.log(err);
            };
        
      return Promise.resolve(facility);
    }
  };

    
    const styles = {
      container: { margin: "auto", width: "fit-content", display:"flex", flexDirection:"column", gap: "50px" }
    };

    if (userCookie.userProfile) {
        return (
            
            <div style={styles.container}>
                <CRUDTable
                fetchItems={(payload) => individualService.fetchItems(payload)}
                >
                <Fields>
                    <Field
                    name="courtroomId"
                    label="Courtroom ID"
                    placeholder="Courtroom ID"
                    render={DescriptionRenderer}
                    hideFromTable
                    />
                    <Field
                    name="displayName"
                    label="Display Name"
                    placeholder="Display Name"
                    />
                    <Field
                    name="email"
                    label="Email"
                    placeholder="Email"
                    />
                    <Field
                    name="role"
                    label="Role"
                    placeholder="Role"
                    />
                </Fields>
                <CreateForm
                    title="Individual Creation"
                    trigger="Create Individual"
                    onSubmit={(individual) => individualService.create(individual)}
                    submitText="Create"
                    validate={(values) => {
                    const errors = {};
                    if (!values.displayName) {
                        errors.displayName = 'Please, provide display name';
                    }
                    if (!values.email) {
                        errors.email = 'Please, provide email';
                    }
                    if (!values.role) {
                        errors.role = 'Please, provide role';
                    }
                    return errors;
                    }}
                />
                <UpdateForm
                    title="Individual Update"
                    trigger="Update Individual"
                    onSubmit={(individual) => individualService.update(individual)}
                    submitText="Create"
                    validate={(values) => {
                    const errors = {};
                    if (!values.displayName) {
                        errors.displayName = 'Please, provide display name';
                    }
                    if (!values.email) {
                        errors.email = 'Please, provide email';
                    }
                    if (!values.role) {
                        errors.role = 'Please, provide role';
                    }
                    return errors;
                    }}
                />
                    <DeleteForm
                        title="Individual Delete"
                        message="Are you sure you want to delete this individual?"
                        trigger="Delete"
                        onSubmit={individual => individualService.delete(individual)}
                        submitText="Delete"
                        validate={values => {
                        const errors = {};
                        if (!values.id) {
                            errors.id = "Please, provide id";
                        }
                        return errors;
                        }}
                    />
                </CRUDTable>
                <CRUDTable
                fetchItems={(payload) => facilityService.fetchItems(payload)}
                >
                <Fields>
                    <Field
                    name="displayName"
                    label="Display Name"
                    placeholder="Display Name"
                    />
                    <Field
                    name="ownerEmail"
                    label="Owner Email"
                    placeholder="Owner Email"
                    />
                </Fields>
                <CreateForm
                    title="Facility Creation"
                    trigger="Create Facility"
                    onSubmit={(facility) => facilityService.create(facility)}
                    submitText="Create"
                    validate={(values) => {
                    const errors = {};
                    if (!values.displayName) {
                        errors.displayName = 'Please, provide display name';
                    }
                    if (!values.ownerEmail) {
                    errors.ownerEmail = 'Please, provide email';
                    }
                    return errors;
                    }}
                />
                <UpdateForm
                    title="Facility Update"
                    trigger="Update Facility"
                    onSubmit={(facility) => facilityService.update(facility)}
                    submitText="Update"
                    validate={(values) => {
                    const errors = {};
                    if (!values.displayName) {
                        errors.displayName = 'Please, provide display name';
                    }
                    if (!values.ownerEmail) {
                    errors.ownerEmail = 'Please, provide email';
                    }
                    return errors;
                    }}
                />
                    <DeleteForm
                        title="Task Delete Process"
                        message="Are you sure you want to delete this facility?"
                        trigger="Delete"
                        onSubmit={facility => facilityService.delete(facility)}
                        submitText="Delete"
                        validate={values => {
                        const errors = {};
                        if (!values.id) {
                            errors.id = "Please, provide id";
                        }
                        return errors;
                        }}
                    />
                </CRUDTable>
            </div>    
        );
      } else {
        return (
          <LoginPage loginRequest={true}/>
        );
      };
}

export default AdminPage;
