import React from "react";
import ReusableLogoHeader from './ReusableLogoHeader';
import Button from "./Button";
import Navbar from "./Navbar";
import './ScheduleHearingConfirmationPage.css'
import http from '../http-common'
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import LoginPage from './LoginPage';


const ScheduleHearingConfirmationPage = () => {
    let navigate = useNavigate();
    const [userCookie, setUserCookie] = useCookies();
    const [isLoading, setIsLoading] = React.useState(false);
    const [userDisplayName, setUserDisplayName] = React.useState("");
    const [userEmails, setUserEmails] = React.useState("");
    const hearingInformation = JSON.parse(window.localStorage.getItem('schedule-hearing-hearingInformation'));
    const offender = JSON.parse(window.localStorage.getItem('schedule-hearing-offender'));
    const availability = JSON.parse(window.localStorage.getItem('schedule-hearing-availability'));
    const courtroomPeople = JSON.parse(window.localStorage.getItem('schedule-hearing-courtroomPeople'));
    const meetingParticipants = {
        meetingId: "",
        clerks: [],
        districtAttorneys: [],
        offender: {},
        devices: []  
    };
    courtroomPeople.clerks.map((clerk, index) => {
        meetingParticipants.clerks.push(clerk);
    });
    courtroomPeople.districtAttorneys.map((districtAttorney, index) => {
        meetingParticipants.districtAttorneys.push(districtAttorney);
    });
    courtroomPeople.devices.map((device, index) => {
        meetingParticipants.devices.push(device);
    });
    meetingParticipants.offender = offender;
    let title=""
    let offenderDisplayName="";
    if(hearingInformation.eventType !== 'First Appearance' && hearingInformation.eventType !== 'Bulk Hearings')
    {
        title = hearingInformation.courtroom + ' hearing for ' + offender.firstName + ' ' + offender.lastName + ' - Case #(s) ' + hearingInformation.caseNumbers;
        offenderDisplayName = offender.firstName + ' ' + offender.lastName;
    }
    else{
        title = hearingInformation.eventType + " scheduled at "+hearingInformation.courtroom;
    }
    

    const scheduleHearing = () => {
        setIsLoading(true);
        console.log('scheduleHearing API call:')
        let webexParams = {
          title: title,
          startTime: availability.startTime,
          endTime: availability.endTime,
          hostEmail:meetingParticipants.devices[0].email || ""
        }
        console.log("Webex Params: ",webexParams);
        try {
            http.post('/schedule-meetings', webexParams).then(response => {
                  if (response.data.error) {
                    console.log(response.data.error);
                    alert(response.data.error.message + '\nMeeting was not able to be scheduled.');
                    setIsLoading(false);
                  }
                  else {
                    console.log(response.data);
                    let mongoParams = {
                        meetingId: response.data.id,
                        meetingNumber: response.data.meetingNumber,
                        startTime: availability.startTime,
                        caseNumbers: (hearingInformation.caseNumbers || []),
                        eventType: hearingInformation.eventType,
                        description: hearingInformation.description,
                        courtroom: hearingInformation.courtroom,
                        courtroomDevice: hearingInformation.courtroomDevice,
                        facilityName: offender.facilityName,
                        offenderDisplayName: (offenderDisplayName.length > 0) ? offenderDisplayName : "",
                        districtAttorneyName: (courtroomPeople.districtAttorneys.length > 0) ? courtroomPeople.districtAttorneys[0].displayName : "",
                        hearingStatus: "Pending",
                        result: "",
                        webLink: response.data.webLink,
                        password: response.data.password,
                        title: title,
                        endTime: availability.endTime,
                        meetingDeviceId: courtroomPeople.devices[0].deviceIdWorkspace
                    };
                    console.log(mongoParams);
                    http.post('/save-meeting', mongoParams).then(response => {
                        if (response.data.error) {
                            console.log(response.data.error);
                            alert(response.data.error.message + '\nMeeting was not able to be saved to the database.');
                        }
                        else {
                            console.log(response);
                        };
                    });
                    meetingParticipants.meetingId = mongoParams.meetingId;
                    let meetingParticipantsParams = meetingParticipants;
                    if(hearingInformation.eventType !== 'First Appearance' && hearingInformation.eventType !== 'Bulk Hearings')
                    {
                        http.post('/save-offender', meetingParticipantsParams).then(response => {
                        if (response.data.error) {
                            console.log(response.data.error);
                            alert(response.data.error.message + '\nMeeting offender was not able to be saved to the database.');
                        }
                        else {
                            console.log(response);
                        };
                     });
                    }
                    http.post('/save-facility-participants', meetingParticipantsParams).then(response => {
                        if (response.data.error) {
                            console.log(response.data.error);
                            alert(response.data.error.message + '\nMeeting Facility Participants was not able to be saved to the database.');
                        }
                        else {
                            console.log(response);
                        };
                    });
                    let emailParams = {
                        facilityName: offender.facilityName,
                        meetingId: mongoParams.meetingId,
                        eventType: hearingInformation.eventType,
                        courtroom: hearingInformation.courtroom,
                        courtroomDevice: hearingInformation.courtroomDevice,
                        startTime: availability.startTime,
                        endTime: availability.endTime,
                        offenderDisplayName: offenderDisplayName,
                        facilityOwnerEmail: offender.facilityOwnerEmail
                    };
                    http.post('/send-email-notification', emailParams).then(response => {
                        if (response.data.error) {
                            console.log(response.data.error);
                            alert(response.data.error.message + '\nEmail notification was unable to be sent.');
                        }
                        else {
                            console.log(response);
                        };
                    });
                    alert('Webex meeting: "' + response.data.title + '" was successfully scheduled!');
                    setIsLoading(false);
                    navigate('/hearings');
                };

                }).catch(e => {
                  console.log(e);
                  alert(e);
                  setIsLoading(false);
                });
        } catch (err) {
                alert(err);
                console.log(err);
                setIsLoading(false);
        };
    };

    React.useEffect(() => {
        if (!userCookie.userProfile) {
            console.log('no userProfile found!');
            return;
        } else {
            setUserDisplayName(userCookie.userProfile.displayName);
            setUserEmails(userCookie.userProfile.emails);
            console.log('ScheduleHearingConfirmationPage page useEffect render on initial load');
        };
    }, []);

    if (userCookie.userProfile && userCookie.userProfile.emails[0].split('@')[1] === 'nccourts.org') {
        return (
            <div className="app__schedulehearingconfirmationpage">
                <div id='loading' style={{display: isLoading ? "initial" : "none"}}>
                    <Loading type={'spinningBubbles'} color={'#003366'}/>
                </div>
                <div className="app__schedulehearingconfirmationpage-main-navbar">
                        <Navbar/>
                </div>
                <div className="app__confirmationpage-main-container">
                    <div className="app__confirmationpage-main-page">
                        <ReusableLogoHeader text={"CONFIRMATION"} />
                        <div className="app__confirmationpage-header">
                            <p>Scheduled Hearing Details</p>
                        </div>
                        <div className="app__confirmationpage-container">
                        {hearingInformation.eventType !== 'First Appearance' && hearingInformation.eventType !== 'Bulk Hearings' &&
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <div><b>Case Number(s): </b>{hearingInformation.caseNumbers.map((caseNumber, index) =>
                                        <div key={`${index}-${caseNumber}`}>{caseNumber}</div>
                                    )}
                                    </div>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
                        }
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <p><b>Date and Time: </b>{availability.startDate} at {availability.startTimeString}</p>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <p><b>Event Type: </b>{hearingInformation.eventType}</p>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
        
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <p><b>Description: </b>{hearingInformation.description}</p>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
                            <div className="app__confirmationpage-section-last">
                                <div className="app__confirmationpage-section-text">
                                    <div className="app__confirmationpage-section-text-contact">
                                        <p><b>Scheduler Contact Information:</b></p>
                                        <p><b>Name:</b> {userDisplayName}</p>
                                        <p><b>Email:</b> {userEmails}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app__confirmationpage-button-container">
                                <Button text={"CONFIRM"}
                                    containerStyle={{}}
                                    style={{fontWeight: 'bold', height: '4em', width: '250px',
                                    backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                                    originalColor={'#3576a5'} onHoverColor={'#206392'} onClick={scheduleHearing}/>
                        </div>
                    </div>    
                </div>
            </div>
        );
    } else {
        return (
            <LoginPage loginRequest={true} />
        );
    };
};

export default ScheduleHearingConfirmationPage;
