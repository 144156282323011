import React from "react";
import http from '../http-common'
import {useNavigate} from 'react-router-dom';
import './Sidebar.css';
import InputEditField from './InputEditField';
import Button from './Button';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import WebexBlackLogo from '../assets/icons/webex-black-logo.svg';
import Loading from "./Loading";


const Sidebar = ({selectedMeeting}) => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [meetingId, setMeetingId] = React.useState("");
    const [meetingPwd, setMeetingPwd] = React.useState("");

    const joinMeeting = async () => {
      setIsLoading(true);
      let webexParams = {
        meetingId: meetingId,
        password: meetingPwd
      };
      try {
        let response = await http.post('/join-meeting', webexParams)
        console.log(response);
        if (response.status === 200) {
          if (response.data.joinLink) {
            setIsLoading(false);
            window.open(
              response.data.joinLink,
              '_blank'
            );
          } else {
              setIsLoading(false);
              alert('Unable to join the meeting. Please check your Meeting Number and Password and try again.')
          }
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      };
    };

    return (
        <div className='app__hearingschedulermain-sidebar'>
          <div id='loading' style={{display: isLoading ? "initial" : "none"}}>
            <Loading type={'spinningBubbles'} color={'#003366'}/>
          </div>
          <div className='app__hearingschedulermain-sidebar-logo'>
            <img src={SealLogo} alt='seal logo'/>
          </div>
          <div className='app__hearingschedulermain-sidebar-ltagline'>
            <img src={LTagline} alt='l tagline'/>
          </div>
          <div className='app__hearingschedulermain-sidebar-content'>
            <div className='app__hearingschedulermain-sidebar-content-input'>
              <div className='app__hearingschedulermain-sidebar-content-bar'></div>
              <div className='app__hearingschedulermain-sidebar-content-title'>Join WebEx Meeting</div>
              <InputEditField title={"Meeting ID*"} placeholder={"1234567890X"}
                maxLength={100}
                containerStyle={{marginTop: '1em', width: '250px'}}
                style={{width: '100%'}}
                defaultValue={selectedMeeting.meetingId}
                setState={setMeetingId}/>
              <InputEditField title={"Meeting Password*"} placeholder={"********"}
                maxLength={20}
                containerStyle={{marginTop: '1em', width: '250px'}}
                style={{width: '100%'}}
                defaultValue={selectedMeeting.password}
                setState={setMeetingPwd}/>
              <Button text={'JOIN HEARING'}
                containerStyle={{marginRight: '0', marginBottom: '2em', marginTop: '1em', width: '250px'}}
                style={{height: '4em', backgroundColor: '#003366', border: '1px solid #003366', color: 'white', fontWeight: 'bold', width: '100%'}}
                originalColor={'#003366'}
                onHoverColor={'#01203f'}
                onClick={joinMeeting}/>
              <div className='app__hearingschedulermain-sidebar-content-bar'></div>
            </div>
          </div>
          <div className='app__hearingschedulermain-sidebar-webexlogo'>
            <img src={WebexBlackLogo} alt='webex logo'/>
          </div>
        </div>
    )
}

export default Sidebar;
