import React, { useEffect } from 'react';
import Button from './Button';
import './Table.css';
import HearingDetails from './HearingDetails';
import ModalService from '../services/ModalService';
import ModalRoot from './ModalRoot';
import UtilService from '../services/Utils';

const Table = ({tableType, data, setState}) => {
    const [caseNumber, setCaseNumber] = React.useState("");
    const [checked, setChecked] = React.useState([]);

    const onCheck = (e) => {
        console.log(e.target.value);
        setChecked(e.target.value);
        window.localStorage.setItem("selectedMeeting", e.target.value);
        if (setState) {
            if (Array.isArray(setState)) {
                console.log('it is an array')
                setState[0](e.target.checked);
                setState[1](JSON.parse(e.target.value));
            } else {
                setState(e.target.checked);
            };
        };
    };

    const goToLink = (url) => {
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
        );
    };

    if (tableType === 'upcominghearings') {
        if (data) {
            return (
                <div className='app__table'>
                    <table className='app__table-table'>
                        <thead className='app__table-table-rowheader'>
                            <tr>
                                <th className='app__table-table-tableheader'>Select</th>
                                <th className='app__table-table-tableheader'>Start Time</th>
                                <th className='app__table-table-tableheader'>Case Number(s)</th>
                                <th className='app__table-table-tableheader'>Event Type</th>
                                <th className='app__table-table-tableheader'>Defendant</th>
                                <th className='app__table-table-tableheader'>ADA</th>
                                <th className='app__table-table-tableheader'>Description</th>
                                <th className='app__table-table-tableheader'>Courtroom Device</th>
                                <th className='app__table-table-tableheader'>Facility Name</th>
                                <th className='app__table-table-tableheader'>Hearing Status</th>
                                <th className='app__table-table-tableheader'>Hearing</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((meeting, index) =>
                                <tr key={`${index}-${meeting}`} className='app__table-table-tablerowitems'>
                                    <td className='app__table-table-tablecell' id={index}>
                                    <input className='app__table-table-tablecell-input' type='radio' id={index} name='select[]'
                                        value={JSON.stringify(meeting)}
                                        style={{width: '36px', height: '36px'}}
                                        onChange={onCheck}/>
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>{UtilService.displayDate(meeting.startTime)}</td>
                                    {(meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' && 
                                    <td className='app__table-table-tablecell' id={index}>{meeting.caseNumbers.length === 1 ?
                                        <div>{meeting.caseNumbers[0]}</div> :
                                        <div>{meeting.caseNumbers[0] + ' + ' + (meeting.caseNumbers.length-1) + ' more'}</div>
                                        }
                                    </td> )|| 
                                    <td className='app__table-table-tablecell' id={index}>
                                       N/A
                                    </td>
                                    }
                                    <td className='app__table-table-tablecell' id={index}>{meeting.eventType}</td>
                                    <td className='app__table-table-tablecell' id={index}>{meeting.offenderDisplayName ? meeting.offenderDisplayName:'N/A'}</td>
                                    <td className='app__table-table-tablecell' id={index}>{meeting.districtAttorneyName ? meeting.districtAttorneyName:'N/A'}</td>
                                    <td className='app__table-table-tablecell' id={index}>{meeting.description}</td>
                                    <td className='app__table-table-tablecell' id={index}>{meeting.courtroomDevice}</td>
                                    <td className='app__table-table-tablecell' id={index}>{meeting.facilityName}</td>
                                    <td className='app__table-table-tablecell' id={index}>{meeting.hearingStatus}</td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        <Button text={"JOIN"} 
                                        containerStyle={{padding: '0 0 0 0', width: 'auto'}}
                                        style={{width: '100px', backgroundColor: 'white', color: '#3576A5', border: '1px solid #3576A5',
                                            fontWeight: 'bold'}}
                                        originalColor={'white'} onHoverColor={'lightgray'}
                                        onClick={() => goToLink(meeting.webLink)}/>
                                    </td>                 
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) 
        }
    }
    else if (tableType === 'pasthearings') {
        if (data) {
            return (
                <div className='app__table'>
                    <table className='app__table-table'>
                        <thead className='app__table-table-rowheader'>
                            <tr>
                                <th className='app__table-table-tableheader'>Select</th>
                                <th className='app__table-table-tableheader'>Start Time</th>
                                <th className='app__table-table-tableheader'>Case Number(s)</th>
                                <th className='app__table-table-tableheader'>Event Type</th>
                                <th className='app__table-table-tableheader'>Offender</th>
                                <th className='app__table-table-tableheader'>ADA</th>
                                <th className='app__table-table-tableheader'>Description</th>
                                <th className='app__table-table-tableheader'>Courtroom Device</th>
                                <th className='app__table-table-tableheader'>Facility Name</th>
                                <th className='app__table-table-tableheader'>Hearing Status</th>
                                <th className='app__table-table-tableheader'>Result</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map((meeting, index) =>
                            <tr key={`${index}-${meeting}`} className='app__table-table-tablerowitems'>
                                <td className='app__table-table-tablecell' id={index}>
                                    <input className='app__table-table-tablecell-input' type='radio' id={index} name='select[]'
                                        value={JSON.stringify(meeting)}
                                        style={{width: '36px', height: '36px'}}
                                        onChange={onCheck}/>
                                </td>
                                <td className='app__table-table-tablecell' id={index}>{UtilService.displayDate(meeting.startTime)}</td>
                                <td className='app__table-table-tablecell' id={index}>{meeting.caseNumbers.length === 1 ?
                                    <div>{meeting.caseNumbers[0]}</div> :
                                    <div>{meeting.caseNumbers[0] + ' + ' + (meeting.caseNumbers.length-1) + ' more'}</div>
                                    }
                                </td>
                                <td className='app__table-table-tablecell' id={index}>{meeting.eventType}</td>
                                <td className='app__table-table-tablecell' id={index}>{meeting.offenderDisplayName ? meeting.offenderDisplayName:'N/A'}</td>
                                    <td className='app__table-table-tablecell' id={index}>{meeting.districtAttorneyName ? meeting.districtAttorneyName:'N/A'}</td>
                                <td className='app__table-table-tablecell' id={index}>{meeting.description}</td>
                                <td className='app__table-table-tablecell' id={index}>{meeting.courtroomDevice}</td>
                                <td className='app__table-table-tablecell' id={index}>{meeting.facilityName}</td>
                                <td className='app__table-table-tablecell' id={index}>{meeting.hearingStatus}</td>
                                <td className='app__table-table-tablecell' id={index}>{(meeting.result) ? meeting.result : "No Result"}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            )
        }
    }  
    else if (tableType === 'upcominghearingdetails') {
        if (data) {
            return (
                <div className='app__table' style={{border: '1px solid #0E2133'}}>
                    <table className='app__table-table'>
                        <thead className='app__table-table-rowheader'>
                            <tr>
                                <th className='app__table-table-tableheader'>Role</th>
                                <th className='app__table-table-tableheader'>Name</th>
                                <th className='app__table-table-tableheader'>Phone</th>
                                <th className='app__table-table-tableheader'>Email</th>
                                <th className='app__table-table-tableheader'>Status</th>
                                {/* <th className='app__table-table-tableheader'>Join Hearing</th>
                                <th className='app__table-table-tableheader'>Expel Hearing</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((participant, index) =>
                                <tr key={`${index}-${participant}`} className='app__table-table-tablerowitems'>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.role}
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.displayName}
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.phone}
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.email}
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.status}
                                    </td>
                                    {/* <td className='app__table-table-tablecell' id={index}>
                                        <Button text={'JOIN'} 
                                        containerStyle={{padding: '0 0 0 0', width: 'auto'}}
                                        style={{width: '100px', backgroundColor: 'white', color: '#3576A5', border: '1px solid #3576A5',
                                            fontWeight: 'bold'}}
                                        originalColor={'white'} onHoverColor={'lightgray'}/>
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        <Button text={'EXPEL'} 
                                        containerStyle={{padding: '0 0 0 0', width: 'auto'}}
                                        style={{width: '100px', backgroundColor: 'white', color: '#3576A5', border: '1px solid #3576A5',
                                            fontWeight: 'bold'}}
                                        originalColor={'white'} onHoverColor={'lightgray'}/>
                                    </td>                  */}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
    }
    else if (tableType === 'pasthearingdetails') {
        if (data) {
            return (
                <div className='app__table' style={{border: '1px solid #0E2133'}}>
                    <table className='app__table-table'>
                        <thead className='app__table-table-rowheader'>
                            <tr>
                                <th className='app__table-table-tableheader'>Role</th>
                                <th className='app__table-table-tableheader'>Name</th>
                                <th className='app__table-table-tableheader'>Phone</th>
                                <th className='app__table-table-tableheader'>Email</th>
                                <th className='app__table-table-tableheader'>Status</th>
                                {/* <th className='app__table-table-tableheader'>Join Hearing</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((participant, index) =>
                                <tr key={`${index}-${participant}`} className='app__table-table-tablerowitems'>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.role}
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.displayName}
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.phone}
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.email}
                                    </td>
                                    <td className='app__table-table-tablecell' id={index}>
                                        {participant.status}
                                    </td>        
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
    }  
}

export default Table;
