import React from 'react';
import './App.css';
import LoginPage from './components/LoginPage';


const App = () => {
  return (
    <div className='App'>
      <LoginPage />
    </div>
  )
}

export default App;
