import React from "react";
import ReusableLogoHeader from './ReusableLogoHeader';
import Button from "./Button";
import Navbar from "./Navbar";
import './NewTimeProposedWindow.css';
import { useCookies } from "react-cookie";
import LoginPage from './LoginPage';


const NewTimeProposedWindow = () => {
    const [userCookie, setUserCookie] = useCookies();
    const [newTimeProposedWindow, setNewTimeProposedWindow ] = React.useState(false);

    React.useEffect(() => {
        if (!userCookie.userProfile) {
            return;
        } else {
            console.log('New Time Proposed Window page useEffect render on initial load');
        };
      }, []);

    if (userCookie.userProfile && userCookie.userProfile.emails[0].split('@')[1] === 'nccourts.org') {  
        return (
            <div className="app__newtimeproposedwindow">
                <div className='app__newtimeproposedwindow-navbar'>
                    <Navbar/>
                </div>
                <div className="app__newtimeproposedwindow-main-container">
                    <div className="app__newtimeproposedwindow-main-page">
                        <ReusableLogoHeader text={"NEW TIME PROPOSED"} />
                        <div className="app__newtimeproposedwindow-header">
                            <p>By FirstName LastName</p>
                        </div>
                        <div className="app__newtimeproposedwindow-container">
                            <div className="app__newtimeproposedwindow-confirmation-number">
                                <p><b>Confirmation Number:</b> 12-CF-4324234234234-A</p>
                            </div>
                            <div className="app__newtimeproposedwindow-border"></div>
                            <div className="app__newtimeproposedwindow-date-and-time">
                                <p><b>Date and Time:</b> 07/06/2022 on Friday at 8:30am</p>
                            </div>
                            <div className="app__newtimeproposedwindow-border"></div>
                            <div className="app__newtimeproposedwindow-new-time-proposed">
                                <p><b>New Time Proposed:</b> XX/XX/XXX on Friday at X:XXam</p>
                            </div>
                            <div className="app__newtimeproposedwindow-border"></div>
                            <div className="app__newtimeproposedwindow-comments">
                                <p><b>Comments:</b> I have 3 other available times this day - 9:20AM, 10:00AM, 2:20PM, pick one. Please invite john.smith@ncdps.gov to this meeting once it's scheduled.</p>
                            </div>
                            <div className="app__newtimeproposedwindow-border"></div>
                            <div className="app__newtimeproposedwindow-contact-details">
                                <p><b>Contact:</b> Firstname Lastname</p>
                                <p><b>Phone Number:</b> (555) 555-5555</p>
                                <p><b>Email:</b> firstname@email.com</p>
                            </div>
                        </div>
                        <div className="app__newtimeproposedwindow-button-container">
                            <div className="app__newtimeproposedwindow-button-content">
                                <Button text={"PROPOSE NEW TIME"}
                                    containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                                    style={{fontWeight: 'bold', height: '4em', width: '250px', backgroundColor: 'white', border: '1px solid #3576a5', color: '#3576a5'}}
                                    originalColor={'white'}
                                    onHoverColor={'lightgray'}/>
                                <Button text={"CONFIRM"}
                                    containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                                    style={{fontWeight: 'bold', height: '4em', width: '250px', 
                                    backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                                    originalColor='#3576a5' onHoverColor='#206392' route={ '/webexlink-confirmation' }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <LoginPage loginRequest={true} />
        );
    };
};

export default NewTimeProposedWindow;