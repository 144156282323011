import React, {useState, range, getYear, getMonth} from 'react';
import DatePicker from 'react-datepicker';
import _ from "lodash";
import './DatePickerInput.css';
import "react-datepicker/dist/react-datepicker.css";


const DatePickerInput = ({placeholder, containerStyle, type, empty, setDateState}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [date, setDate] = useState(new Date());
  const [emptyDate, setEmptyDate] = useState(null);
  const [startDate, endDate] = dateRange;
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  if (type === 'range') {
    return (
      <div className='app__datepicker' style={containerStyle}>
        <div className='app__datepicker-title'>Date / Date Range*</div>
        <DatePicker wrapperClassName='.app__datepicker-datepicker'
          placeholderText={placeholder}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          minDate={new Date()}
          onChange={(update) => {
            setDateRange(update);
            console.log('update here');
            if (update[0] && update[1]) {
              let temp = update.map(time => time.toLocaleDateString("en-US"))
              console.log(temp);
              setDateState(temp);
            }
            else {
              setDateState([]);
            }
          }}
          isClearable={true}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        >
        </DatePicker>
      </div>
    );
  }
  else {
    if (empty === false) {
      return (
        <div className='app__datepicker' style={containerStyle}>
          <div className='app__datepicker-title'>Date*</div>
          <DatePicker selected={date} 
            onChange={(date) => {
              setDate(date);
              // console.log(date.toLocaleDateString("en-US", options));
              setDateState(date.toLocaleDateString("en-US", options));
            }}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      );
    }
    else if (empty === true) {
      const years = range(1990, getYear(new Date()) + 1, 1);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return (
        <div className='app__datepicker' style={containerStyle}>
          <div className='app__datepicker-title'>Date*</div>
          <DatePicker selected={emptyDate}
            placeholderText={placeholder}
            onChange={(date) => {
              setEmptyDate(date);
              // console.log(date.toLocaleDateString("en-US", options));
              setDateState(date.toLocaleDateString("en-US", options));
            }}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      );
    }
  }

  
};

export default DatePickerInput;