import React from 'react';
import http from '../http-common';
import { useNavigate } from "react-router-dom";
import './HearingSchedulerPage.css';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import DropdownSelect from './DropdownSelect';
import InputField from './InputField';
import TextArea from './TextArea';
import Button from './Button';
import Pill from './Pill';
import Fab from '@mui/material/Fab';
import AddCommentIcon from '@mui/icons-material/AddComment';
import FeedbackModal from './FeedbackModal.jsx';
import ModalService from '../services/ModalService.js';
import ModalRoot from './ModalRoot';
import DatePickerInput from './DatePickerInput';
import DropdownSelectCheckbox from './DropdownSelectCheckbox';
import TimeWheel from './TimeWheel';
import {eventTypes, participantTypes, participantLanguages, participantCounties, durations, defaultTimeArray, mutableTimeArray} from './DefaultData';
import Loading from './Loading';
import moment from 'moment';
import { useCookies } from "react-cookie";
import LoginPage from './LoginPage';
import UtilService from '../services/Utils';

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
  mr: 1
};


const HearingSchedulerPage = () => {
    let navigate = useNavigate();
    const [userCookie, setUserCookie] = useCookies();

    const [isLoading, setIsLoading] = React.useState(false);

    const [courtrooms, setCourtrooms] = React.useState([]);
    const [isCourtroomSelected, setIsCourtroomSelected] = React.useState(false);

    const [courtroomDevices, setCourtroomDevices] = React.useState([]);
    const [isCourtroomDeviceSelected, setIsCourtroomDeviceSelected] = React.useState({
      isSelected: false,
      deviceName: "",
    });
    const initialCourtroomPeople = {
      clerks: [],
      districtAttorneys: [],
      devices: []
    };
    const [courtroomPeople, setCourtroomPeople] = React.useState(initialCourtroomPeople);
    const setCourtroomPeopleArrays = (person) => {
      setCourtroomPeople(current => ({
        ...current, clerks: [person]
      }));
    };

    const setCourtroomDA = (person) => {
      let da = {
        displayName: person,
        role: "District Attorney",
        phone: "N/A",
        email:"N/A"

      }
        setCourtroomPeople(current => ({
          ...current, districtAttorneys: [da]
        }));
    };
    
    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }

    const [facilities, setFacilities] = React.useState([]);

    const [dateIsSelected, setDateIsSelected] = React.useState(false);
    
    const [durationIsSelected, setDurationIsSelected] = React.useState(false);

    const printState = () => {
      console.log('hearingInformation: ');
      console.log(hearingInformation);
      console.log('offender: ');
      console.log(offender);
      console.log('participants: ');
      console.log('availability: ');
      console.log(availability);
      console.log('courtroomPeople: ');
      console.log(courtroomPeople);
    };

    const requestHearing = () => {
      setIsLoading(true);
      let check = [
        hearingInformation.caseNumbers, 
        hearingInformation.courtroom, 
        hearingInformation.courtroomDevice, 
        hearingInformation.eventType,
        offender.firstName, 
        offender.lastName, 
        offender.dateOfBirth, 
        offender.facilityName,
        availability.dateRange, 
        availability.duration,
        availability.startTime, 
        availability.endTime];
    
      for (var i = 0; i < check.length; i++) {
        if (!check[i] || check[i] === null || check[i] === undefined || check[i].length === 0) {
          if (i >= 11) {
            alert('Please select a meeting time slot.');
            setIsLoading(false);
            return;
          };
          if(i===7){
            alert('Please select a facility.');
            setIsLoading(false);
            return;
          }
          if((i >=4 && i <= 6) || i===0){
            if(hearingInformation.eventType === 'First Appearance' || hearingInformation.eventType === 'Bulk Hearings'){
              continue;
            }
          }
          // console.log('missing ' + i);
          alert('Please fill in all the required fields.');
          setIsLoading(false);
          return;
        };
        if (i === 6 && (hearingInformation.eventType !== 'First Appearance' && hearingInformation.eventType !== 'Bulk Hearings')) {
          if (!moment(check[i], "MM/DD/YYYY", true).isValid()) {
            alert('Please enter a valid DOB for the Offender.');
            setIsLoading(false);
            return;
          };
        };
      };
      setIsLoading(false);
      navigate('/hearing-confirmation');
    };

// --------------------------- API CALLS --------------------------- //

    const getAndUpdateCourtrooms = () => {
      setIsLoading(true);
      let courtroomArray = [];
      try {
          http.get('/courtrooms').then(response => {
              courtroomArray = response.data.items;
              setCourtrooms(courtroomArray);
              try {
                  http.post('/update-courtrooms', courtroomArray).then(response => {
                  }).catch(e => {
                      console.log(e);
                  });
              } catch (err) {
                  console.log(err);
              }
          }).catch(e => {
              console.log(e);
          });
      } catch (err) {
          console.log(err);
      };
      setIsLoading(false);
    }

    const getCourtroomDevices = async (location_id) => {
      setIsLoading(true);
      let deviceIdData = [];
      let courtroomDeviceArray = [];
      let params = {
        location_id: location_id
      };
      try {
          await http.get('/workspaces', {params: params}).then(response => {
            console.log("Court room devices array");
            console.log(response.data.items);
            courtroomDeviceArray = response.data.items;
            console.log('this is courtroom workspace array', courtroomDeviceArray);
              // Object.keys(courtroomDeviceArray).forEach(key => {
              //   let newArray = (key, courtroomDeviceArray[key]);
              //   console.log('this is new array', newArray);
              //   let newArrayId = newArray.id;
              //   let params = {
              //     workspace_Id : newArrayId
              //   };
              //   try {
              //     http.get('/get-devices-by-workspace-id', {params: params}).then(response => {
              //       console.log('in get devices by workspace api call',response.data.items);
              //       deviceIdData.push(response.data.items['0'].id);
              //       console.log('this is device id data', deviceIdData);
              //     })
              //     // deviceIdData = response.data['items'];
              //     // console.log(deviceIdData);
              //   }
              //   catch (err) {
              //     console.log(err);
              //   }
                
              // })
              // console.log('array of device id data',deviceIdData);
            setCourtroomDevices(courtroomDeviceArray);
          }).catch(e => {
              console.log(e);
          });
      } catch (err) {
          console.log(err);
      };
      setIsLoading(false);

    };

    const getCourtroomPeople = async (location_id) => {
      setIsLoading(true);
      setCourtroomPeople(initialCourtroomPeople);
      let courtroomPeopleArray = [];
      let body = {
        location_id: location_id
      };
      try {
        await http.post('/get-courtroom-people', body).then(response => {
          courtroomPeopleArray = response.data;
          courtroomPeopleArray.map((courtroomPerson => {
            if(courtroomPerson.role === 'Clerk'){
              setCourtroomPeopleArrays(courtroomPerson);
            }
          }));
        }).catch(e => {
          console.log(e);
        });
      } catch (err) {
          console.log(err);
      };
      setIsLoading(false);
    };

    const getFacilities = async () => {
      setIsLoading(true);
      let facilityArray = [];
      try {
        await http.get('/get-facilities').then(response => {
          facilityArray = response.data;
          console.log(facilityArray);
          setFacilities(facilityArray);
        }).catch(e => {
          console.log(e);
        });
      } catch (err) {
        console.log(err);
      };
      setIsLoading(false);
    };

    const getClerk = async () => {
      setIsLoading(true);
      //Check if user is logged in i.e user cookie is available
     if(userCookie.userProfile){
      //Fetch all the clerks available in the database
      let body = {
          role: 'Clerk'
        };
        try {
          await http.post('/get-courtroom-people-by-role', body).then(response => {
            if(response.data){
              var clerkData = response.data;
              //If clerks are available then check if the currently logged in user is 
              //available in the database as a clerk -> if so then use that data
              clerkData.map((person => {
                if(person.email.toLowerCase() === userCookie.userProfile.emails[0].toLowerCase()){
                  setCourtroomPeopleArrays(person);
                }
                else{
                  //Set static data if logged in user is not available as a clerk in the database
                  //Use info from user cookie
                  setCourtroomPeopleArrays({
                    role:"Clerk",
                    displayName:userCookie.userProfile.displayName,
                    email:userCookie.userProfile.emails[0],
                    firstName:userCookie.userProfile.firstName,
                    lastName:userCookie.userProfile.lastName,
                    phone:"N/A",
                    courtroomId:"N/A"
                  })
                }
              }));
            }
            else{
              //If clerks are not found then use static data
              setCourtroomPeopleArrays({
                role:"Clerk",
                displayName:userCookie.userProfile.displayName,
                email:userCookie.userProfile.emails[0],
                firstName:userCookie.userProfile.firstName,
                lastName:userCookie.userProfile.lastName,
                phone:"N/A",
                courtroomId:"N/A"
              })
            }
          }).catch(e => {
            //If an error occurs then use static cookie data
            setCourtroomPeopleArrays({
              role:"Clerk",
              displayName:userCookie.userProfile.displayName,
              email:userCookie.userProfile.emails[0],
              firstName:userCookie.userProfile.firstName,
              lastName:userCookie.userProfile.lastName,
              phone:"N/A",
              courtroomId:"N/A"
            })
            console.log(e);
          });
        } catch (err) {
            console.log(err);
        };
      
     }
     setIsLoading(false);
    };
// ------------------------ END OF API CALLS ----------------------------------//

// ------------------------------- HEARING INFORMATION STATE CODE --------------------------- //
 
    const [hearingInformation, setHearingInformation] = React.useState({
      caseNumbers: [],
      courtroom: "",
      courtroomDevice: "",
      eventType: "",
      type: "",
      description: ""
    });

    // helper functions for hearingInformation state
    const setCaseNumbers = (newCaseNumber) => {
      setHearingInformation(current => ({
        ...current, caseNumbers: [...current.caseNumbers, newCaseNumber]
      }));
    };

    const setCourtroom = (selectedCourtroomIndex) => {
      setHearingInformation(current => ({
        ...current, courtroom: courtrooms[selectedCourtroomIndex].displayName
      }));
      setIsCourtroomSelected(true);
      getCourtroomDevices(courtrooms[selectedCourtroomIndex].id);
      //If cookie is not available then select clerk available in the database for a selected courthouse
      if(!userCookie.userProfile){
        getCourtroomPeople(courtrooms[selectedCourtroomIndex].id);
      }
    };

    const setCourtroomDevice = (newCourtroomDevice) => {
      setHearingInformation(current => ({
        ...current, courtroomDevice: newCourtroomDevice
      }));
      let selectedDevice = courtroomDevices.find(x => x.displayName === newCourtroomDevice)
      console.log(selectedDevice);
      let selectedDeviceId = selectedDevice.id;
      console.log(selectedDeviceId);

      let params = {
        workspace_Id : selectedDeviceId
      };
      let deviceIdByWorkspace = '';
      let device = {};
      function checkNested(obj /*, level1, level2, ... levelN*/) {
        var args = Array.prototype.slice.call(arguments, 1);

        for (var i = 0; i < args.length; i++) {
          if (!obj || !obj.hasOwnProperty(args[i])) {
            return false;
          }
          obj = obj[args[i]];
        }
        return true;
      }
      try {
        http.get('/get-workspace-by-workspace-id', {params: params}).then(response => {
          console.log('in get devices by workspace api call',response.data);
          let deviceEmail = '';
          if (checkNested(response.data, 'notes')) {
            deviceEmail = response.data.notes.split(/\r\n|\r|\n/)[0];
            }
          else {
            deviceEmail = response.data.calendar.emailAddress;
          }
          // let deviceEmail = response.data.notes.split(/\r\n|\r|\n/)[0] || courtroomPeople.clerks[0].email
          // let deviceEmail = tags.filter(tag => isValidEmail(tag))['0'] || courtroomPeople.clerks[0].email
          console.log("This is Device Email: ", deviceEmail)
          console.log("courtroomPeople: ")
          console.log(courtroomPeople)
          console.log("userProfile")
          console.log(userCookie.userProfile)
          // deviceIdByWorkspace = response.data.items['0'].id;
          // console.log('this is device id data', deviceIdByWorkspace);
          device = {
            displayName: selectedDevice.displayName,
            // deviceIdWorkspace: deviceIdByWorkspace || '',
            deviceIdWorkspace: '',
            role: "Device",
            phone: "N/A",
            // email:selectedDevice.sipAddress || 'N/A'
            email: deviceEmail
          }
          console.log(device);
          setCourtroomPeople(current => ({
            ...current, devices: [device]
          }));
        })
      }
      catch (err) {
        console.log(err);
      }

      console.log(device);
    };

    const setEventType = (newEventType) => {
      setHearingInformation(current => ({
        ...current, eventType: newEventType
      }));
    };

    const setType = (newType) => {
      setHearingInformation(current => ({
        ...current, type: newType
      }));
    };

    const setDescription = (newDescription) => {
      setHearingInformation(current => ({
        ...current, description: newDescription
      }));
    };

    const removeCaseNumber = (caseNumber) => {
      setHearingInformation(
        current => ({
        ...current, caseNumbers: current.caseNumbers.filter((number) => number !== caseNumber)
      }));
    };
// ------------------------------- END OF HEARING INFORMATION STATE CODE --------------------------- //

// ------------------------------- OFFENDER STATE CODE ------------------------------------//
    const [offender, setOffender] = React.useState({
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      offenderId: "",
      facilityName: "",
      facilityOwnerEmail: ""
    });

    const setOffenderFirstName = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, firstName: newOffenderInfo
      }));
    };

    const setOffenderMiddleName = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, middleName: newOffenderInfo
      }));
    };

    const setOffenderLastName = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, lastName: newOffenderInfo
      }));
    };

    const setOffenderDateOfBirth = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, dateOfBirth: newOffenderInfo
      }));
    };

    const setOffenderOffenderId = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, offenderId: newOffenderInfo
      }));
    };

    const setOffenderFacilityName = (selectedFacilityIndex) => {
      setOffender(current => ({
        ...current, facilityName: facilities[selectedFacilityIndex].displayName
      }));
    };

    const setOffenderFacilityOwnerEmail = (selectedFacilityIndex) => {
      setOffender(current => ({
        ...current, facilityOwnerEmail: facilities[selectedFacilityIndex].ownerEmail
      }));
    };
// ------------------------------- END OF OFFENDER STATE CODE ------------------------------------//

// --------------------------------- AVAILABILITY STATE CODE ------------------------------- //
    const [availability, setAvailability] = React.useState({
      dateRange: [],
      duration: [],
      startDate: "",
      startTime: "",
      endTime: "",
      startTimeString: ""
    });

    const [timeWheelArray, setTimeWheelArray] = React.useState({});

    const findAvailability = async () => {
      setIsLoading(true);
      if (isCourtroomDeviceSelected.isSelected) {
        setTimeWheelDuration(availability.duration);
        setTimeWheelDateRange(availability.dateRange);
        if (durationIsSelected && dateIsSelected) {
          // do API call to determine what times are available here:
          let result = await getMeetingAvailability(availability.duration.split(' ')[0]);
          setTimeWheelArray(result);
          setTimeWheelShow(true);
        }
        else {
          alert('Please select a date range and duration.');
        }
      } else {
        alert('Please select a courtroom device before finding availability.');
      };
      setIsLoading(false);
    };

    const getMeetingAvailability = async (durationString) => {
      console.log("Duration String",durationString)
      let tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
      let meetingAvailability = {};
      let busyTimes = [];
      let params = {
        courtroomDevice: isCourtroomDeviceSelected.deviceName
      };
      try {
        let response = await http.get('/get-meeting-availability', {params: params});
        busyTimes = response.data;
        let busyKeys = Object.keys(busyTimes);
        console.log("busyTimes = ",busyTimes)
        console.log("busyKeys = ",busyKeys)
        availability.dateRange.forEach((key) => {
              var dateKeyString = new Date(key).toDateString()
              if(busyKeys.indexOf(dateKeyString) === -1){
                tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
                tempObject[durationString] = tempObject[durationString].filter((time) => 
                UtilService.hasTimeOccured(key,time.split('-')[0].trim()));
                meetingAvailability[dateKeyString] = tempObject;
              }
              else{
                tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
                busyTimes[dateKeyString].map((meeting) => {
                        tempObject[durationString] = (tempObject[durationString].filter((time) => 
                        UtilService.hasTimeOccured(key,time.split('-')[0].trim()) && 
                        !(time.split('-')[0].trim() >= meeting.startTime &&
                        time.split('-')[0].trim() < meeting.endTime ||
                        time.split('-')[1].trim() <= meeting.endTime &&
                        time.split('-')[1].trim() > meeting.startTime)));
                });
                meetingAvailability[dateKeyString] = tempObject;
              }
        });
        return meetingAvailability;
      } catch (err) {
        console.log(err);
      };
    };

    // helper functions for availability state
    const setDateRange = (selectedDateRange) => {
      setAvailability(current => ({
        ...current, dateRange: selectedDateRange
      }));
      if (selectedDateRange.includes(undefined)){
        setDateIsSelected(false);
      }
      else {
        setDateIsSelected(true);
      }
    };

    const setDuration = (selectedDuration) => {
      setAvailability(current => ({
        ...current, duration: selectedDuration
      }));
      setDurationIsSelected(true);
    };

    const setStartDate = (selectedStartDate) => {
      setAvailability(current => ({
        ...current, startDate: selectedStartDate
      }));
    };

    const setStartTime = (selectedStartTime) => {
      setAvailability(current => ({
        ...current, startTime: selectedStartTime
      }));
    };

    const setEndTime = (selectedEndTime) => {
      setAvailability(current => ({
        ...current, endTime: selectedEndTime
      }));
    };    

    const setStartTimeString = (selectedStartTimeString) => {
      setAvailability(current => ({
        ...current, startTimeString: selectedStartTimeString
      }));
    };    

    const [timeWheelParams, setTimeWheelParams] = React.useState({
      duration: "", 
      dateRange: [],
      show: false
    });

    // helper functions for timewheel params state
    const setTimeWheelDuration = (newDuration) => {
      setTimeWheelParams(current => ({
        ...current, duration: newDuration
      }));
    };

    const setTimeWheelDateRange = (newDateRange) => {
      setTimeWheelParams(current => ({
        ...current, dateRange: newDateRange
      }));
    };    

    const setTimeWheelShow = (bool) => {
      setTimeWheelParams(current => ({
        ...current, show: bool
      }));
    };
// --------------------------------- END OF AVAILABILITY STATE CODE ------------------------------- //
React.useEffect(() => {
  if (!userCookie.userProfile) {
    return;
  } else {
    console.log('useEffect render on initial load');
    getAndUpdateCourtrooms();
    getFacilities();
    getClerk(); //Get a list of all the Clerks
  };
}, []);

React.useEffect(() => {
  window.localStorage.setItem('schedule-hearing-hearingInformation', JSON.stringify(hearingInformation));
  window.localStorage.setItem('schedule-hearing-offender', JSON.stringify(offender));
  window.localStorage.setItem('schedule-hearing-availability', JSON.stringify(availability));
  window.localStorage.setItem('schedule-hearing-courtroomPeople', JSON.stringify(courtroomPeople));
}, [hearingInformation, offender, availability, courtroomPeople]);

// ------------------------------ RENDERING OF COMPONENT --------------------------- //
if (userCookie.userProfile && userCookie.userProfile.emails[0].split('@')[1] === 'nccourts.org') {
  return (
    <div className='app__hearingschedulermain'>
      <div id='loading' style={{display: isLoading ? "initial" : "none"}}>
        <Loading type={'spinningBubbles'} color={'#003366'}/>
      </div>
      <div className='app__hearingschedulermain-navbar'>
        <Navbar/>
      </div>
      <ModalRoot/>
      <div className='app__hearingschedulermain-pagecontent'>
        <div className='app__hearingschedulermain-sidebar'>
          <Sidebar selectedMeeting={""}/>
        </div>
        <div className='app__hearingschedulermain-main'>

          <div className='app__hearingschedulermain-container-header'>
            <div className='app__hearingschedulermain-container-header-title'>Schedule Hearing</div>
            <div className='app__hearingschedulermain-container-header-button'>
              <Button text={'UPCOMING HEARINGS'}
                containerStyle={{marginRight: '0', marginBottom: '0'}}
                style={{height: '4em', width: '100%', 
                  fontWeight: 'bold', backgroundColor: '#3576a5', border: '1px solid #3576a5'}} 
                originalColor={'#3576a5'}
                onHoverColor={'#206392'}
                route={'/hearings'}/>
            </div>
          </div>

          <div className='app__hearingschedulermain-container'>
            <div className='app__hearingschedulermain-container-section'>
              <div className='app__hearingschedulermain-container-section-header'>
                <div className='app__hearingschedulermain-subtitle'>Hearing Information</div>
                <div className='app__hearingschedulermain-subtitle-line'></div>
              </div>
              <div className='app__hearingschedulermain-subtitle-description'
                style={{fontSize: '1em', color: 'grey', paddingBottom: '1em'}}>
                Enter the requested information in the fields below.
              </div>
              <div className='app__hearingschedulermain-container-section-content'>
                <DropdownSelect title={"Event Type*"} placeholder={"Select Event Type"}
                  id={'eventType'}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  dropdownOptions={eventTypes}
                  setState={setEventType}/>
                <DropdownSelect title={"Courthouse*"} placeholder={"Select Courthouse"}
                  id={'courtroom'}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  dropdownType={'courtrooms'}
                  dropdownOptions={courtrooms}
                  setState={setCourtroom}/>
                <DropdownSelect title={"Courtroom Device*"} placeholder={"Select Device"}
                  id={'courtroomDevice'}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  dropdownType={'courtroomDevices'}
                  dropdownOptions={courtroomDevices}
                  isDisabled={!isCourtroomSelected}
                  setState={setCourtroomDevice}
                  setIsSelected={setIsCourtroomDeviceSelected}/>
                {(hearingInformation.eventType === 'First Appearance' || hearingInformation.eventType === 'Bulk Hearings') &&
                <DropdownSelect title={"Facility Name*"} placeholder={"Select Facility"}
                  id={'facilityName'}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  dropdownType={'facility'}
                  dropdownOptions={facilities}
                  setState={[setOffenderFacilityName, setOffenderFacilityOwnerEmail]}/>
                }
              </div>
              {hearingInformation.eventType !== 'First Appearance' && hearingInformation.eventType !== 'Bulk Hearings' && <React.Fragment><div className='app__hearingschedulermain-container-section-content'>
                <InputField title={"Case Number(s)*"} 
                  placeholder={"Type Case Number then ENTER to add"}
                  containerStyle={{marginRight: '1em'}}
                  style={{width: '476px'}}
                  caseNumbersField={true}
                  caseNumbers={hearingInformation.caseNumbers}
                  setCaseNumbers={setCaseNumbers}/>
              </div>
              <div className='app__hearingschedulermain-container-section-content'>
                <div className='app__hearingschedulermain-container-section-content-pills'>
                  {hearingInformation.caseNumbers.map((caseNumber, index) => <Pill key={`${index}-${caseNumber}`} text={caseNumber} caseNumbers={hearingInformation.caseNumbers} setCaseNumbers={removeCaseNumber}/>)}
                </div>
              </div></React.Fragment>}
              <div className='app__hearingschedulermain-container-section-content'>
                <TextArea title={"Description"} 
                  placeholder={"Provide a brief description of the hearing."}
                  containerStyle={{width: 'auto', minWidth: '60%', marginBottom: '1em'}}
                  style={{width: '100%', minWidth: '230px', minHeight: '7em'}}
                  setState={setDescription}/>
              </div>
            </div>
            {hearingInformation.eventType !== 'First Appearance' && hearingInformation.eventType !== 'Bulk Hearings' && 
            <React.Fragment>            
              <div className='app__hearingschedulermain-container-section'>
              <div className='app__hearingschedulermain-container-section-header'>
                <div className='app__hearingschedulermain-subtitle'>Defendant</div>
                <div className='app__hearingschedulermain-subtitle-line'></div>
              </div>
              <div className='app__hearingschedulermain-container-section-content'>
                <InputField title={"First Name*"} placeholder={"First Name"}
                  maxLength={15}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  setState={setOffenderFirstName}/>
                <InputField title={"Middle Name"} placeholder={"Middle Name"}
                  maxLength={15}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  setState={setOffenderMiddleName}/>
                <InputField title={"Last Name*"} placeholder={"Last Name"}
                  maxLength={15}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  setState={setOffenderLastName}/>
                <InputField title={"Date Of Birth*"} placeholder={"MM/DD/YYYY"}
                  maxLength={10}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  setState={setOffenderDateOfBirth}/>
                <DropdownSelect title={"Facility Name*"} placeholder={"Select Facility"}
                  id={'facilityName'}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  dropdownType={'facility'}
                  dropdownOptions={facilities}
                  setState={[setOffenderFacilityName, setOffenderFacilityOwnerEmail]}/>
              </div>
             </div>
            </React.Fragment>}

            <div className='app__hearingschedulermain-container-section'>
              <div className='app__hearingschedulermain-container-section-header'>
                <div className='app__hearingschedulermain-subtitle'>Participants</div>
                <div className='app__hearingschedulermain-subtitle-line'></div>
              </div>
              <div className='app__hearingschedulermain-container-section-content'>
              <div className='app__hearingshcedulermain-container-section-content-participants'>
                  <div><b>Scheduler: </b> 
                  {courtroomPeople.clerks.map((clerk, index) => 
                    <p key={`${index}-${clerk}`} style={{display: "inline"}}>{clerk.displayName}</p>
                  )}
                  </div>
                </div>
                <div className='app__hearingshcedulermain-container-section-content-participants'>
                  <InputField title={"District Attorney"} placeholder={"Enter District Attorney Name"}
                  maxLength={25}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  setState={setCourtroomDA}/>
                </div>
              </div>
            </div>

            <div className='app__hearingschedulermain-container-section'>
              <div className='app__hearingschedulermain-container-section-header'>
                <div className='app__hearingschedulermain-subtitle'>Search Availability</div>
                <div className='app__hearingschedulermain-subtitle-line'></div>
              </div>
              <div className='app__hearingschedulermain-container-section-content'>
                <DatePickerInput placeholder={"Select Date Range"}
                  containerStyle={{width: '230px', height: '4em', marginRight: '1em'}}
                  style={{height: '100%'}}
                  type="range"
                  setDateState={setDateRange}/>
                <DropdownSelect title={"Duration*"} placeholder={"Select Duration"}
                  id={'duration'}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{width: '230px'}}
                  dropdownOptions={durations}
                  setState={setDuration}/>
                <Button text={"LOOK UP AVAILABILITY"} 
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{fontWeight: 'bold', width: '200px', backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                  onClick={findAvailability}/>
              </div>
              <div className='app__hearingschedulermain-container-section-content' style={{display: (timeWheelParams.show) ? "flex" : "none"}}>
                <div className='app__hearingschedulermain-container-section-content-timewheel' style={{maxHeight: '300px', overflow: 'auto', border: '1px solid lightgray'}}>
                  <TimeWheel
                  selectedDuration={timeWheelParams.duration}
                  durationArray={defaultTimeArray[parseInt(timeWheelParams.duration).toString()]}
                  datesObject={timeWheelArray}
                  selectedStartDate={timeWheelParams.dateRange[0]}
                  selectedEndDate={timeWheelParams.dateRange[1]}
                  setState={[setStartDate, setStartTime, setEndTime, setStartTimeString]}/>
                </div>
              </div>
            </div>

            <div className='app__hearingschedulermain-container-section'>
              <div className='app__hearingschedulermain-container-section-content'>
                <Button text={"CANCEL"}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{fontWeight: 'bold', height: '4em', width: '250px', 
                    backgroundColor: 'white', border: '1px solid #3576a5', color: '#3576a5'}}
                  originalColor={'white'}
                  onHoverColor={'lightgray'}
                  route={'/hearings'}/>
                <Button text={"REQUEST VIRTUAL HEARING"}
                  containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                  style={{fontWeight: 'bold', height: '4em', width: '250px', 
                    backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                  originalColor='#3576a5' onHoverColor='#206392'
                  onClick={requestHearing}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>       
  );
} else {
  return (
    <LoginPage loginRequest={true}/>
  );
};
    
}

export default HearingSchedulerPage;
