export const eventTypes = [
    {displayName: "Advisement"},
    {displayName: "Plea"},
    {displayName: "Bond Motion"},
    {displayName: "First Appearance"},
    {displayName: "Bulk Hearings"},
    {displayName: "Accept or Reject (PT Conference)"},
    {displayName: "Probation – Admission"},
    {displayName: "Status"},
    {displayName: "Hearing"}
  ];
  
  export const types = [
    {displayName: "Felony Other Felony"},
  ];
  
  export const participantTypes = [
    {displayName: "Foreign Language Interpreter"},
  ];
  
  export const participantLanguages = [
    {displayName: "Spanish"},
    {displayName: "Chinese"},
    {displayName: "French"},
    {displayName: "German"}
  ];
  
  export const participantCounties = [
    {displayName: "County 1"},
    {displayName: "County 2"},
    {displayName: "County 3"},
    {displayName: "County 4"},
  ];
  
  export const durations = [
    // {displayName: "5 Minutes"},
    {displayName: "10 Minutes"},
    {displayName: "15 Minutes"},
    {displayName: "20 Minutes"},
    {displayName: "30 Minutes"},
    {displayName: "45 Minutes"},
    {displayName: "60 Minutes"},
  ];
  
  export const participantsServicesOptions = [
    { value: 'court proceeding', label: 'Court Proceeding'},
    { value: 'custody mediation or orientation', label: 'Custody Mediation or Orientation'},
    { value: 'child planning conference', label: 'Child Planning Conference'},
    { value: 'district attorney', label: 'District Attorney'},
    { value: 'public defender', label: 'Public Defender'},
    { value: 'assigned counsel', label: 'Assigned Counsel'},
    { value: 'gal program', label: 'GAL Program'},
  ];
  
  export const cancelMeetingReasonOptions = [
    {displayName: 'Cancelled'},
    {displayName: 'Continued'},
    {displayName: 'Already Occured'},
    {displayName: 'Other'}
  ]
  export const defaultTimeArray = {
      "5": [
        "09:00 AM - 09:05 AM",
        "09:05 AM - 09:10 AM",
        "09:10 AM - 09:15 AM",
        "09:15 AM - 09:20 AM",
        "09:20 AM - 09:25 AM",
        "09:25 AM - 09:30 AM",
        "09:30 AM - 09:35 AM",
        "09:35 AM - 09:40 AM",
        "09:40 AM - 09:45 AM",
        "09:45 AM - 09:50 AM",
        "09:50 AM - 09:55 AM",
        "09:55 AM - 10:00 AM",
  
        "10:00 AM - 10:05 AM",
        "10:05 AM - 10:10 AM",
        "10:10 AM - 10:15 AM",
        "10:15 AM - 10:20 AM",
        "10:20 AM - 10:25 AM",
        "10:25 AM - 10:30 AM",
        "10:30 AM - 10:35 AM",
        "10:35 AM - 10:40 AM",
        "10:40 AM - 10:45 AM",
        "10:45 AM - 10:50 AM",
        "10:50 AM - 10:55 AM",
        "10:55 AM - 11:00 AM",
  
        "11:00 AM - 11:05 AM",
        "11:05 AM - 11:10 AM",
        "11:10 AM - 11:15 AM",
        "11:15 AM - 11:20 AM",
        "11:20 AM - 11:25 AM",
        "11:25 AM - 11:30 AM",
        "11:30 AM - 11:35 AM",
        "11:35 AM - 11:40 AM",
        "11:40 AM - 11:45 AM",
        "11:45 AM - 11:50 AM",
        "11:50 AM - 11:55 AM",
        "11:55 AM - 12:00 PM",
  
        "12:00 PM - 12:05 PM",
        "12:05 PM - 12:10 PM",
        "12:10 PM - 12:15 PM",
        "12:15 PM - 12:20 PM",
        "12:20 PM - 12:25 PM",
        "12:25 PM - 12:30 PM",
        "12:30 PM - 12:35 PM",
        "12:35 PM - 12:40 PM",
        "12:40 PM - 12:45 PM",
        "12:45 PM - 12:50 PM",
        "12:50 PM - 12:55 PM",
        "12:55 PM - 01:00 PM",
  
        "01:00 PM - 01:05 PM",
        "01:05 PM - 01:10 PM",
        "01:10 PM - 01:15 PM",
        "01:15 PM - 01:20 PM",
        "01:20 PM - 01:25 PM",
        "01:25 PM - 01:30 PM",
        "01:30 PM - 01:35 PM",
        "01:35 PM - 01:40 PM",
        "01:40 PM - 01:45 PM",
        "01:45 PM - 01:50 PM",
        "01:50 PM - 01:55 PM",
        "01:55 PM - 02:00 PM",
  
        "02:00 PM - 02:05 PM",
        "02:05 PM - 02:10 PM",
        "02:10 PM - 02:15 PM",
        "02:15 PM - 02:20 PM",
        "02:20 PM - 02:25 PM",
        "02:25 PM - 02:30 PM",
        "02:30 PM - 02:35 PM",
        "02:35 PM - 02:40 PM",
        "02:40 PM - 02:45 PM",
        "02:45 PM - 02:50 PM",
        "02:50 PM - 02:55 PM",
        "02:55 PM - 03:00 PM",
  
        "03:00 PM - 03:05 PM",
        "03:05 PM - 03:10 PM",
        "03:10 PM - 03:15 PM",
        "03:15 PM - 03:20 PM",
        "03:20 PM - 03:25 PM",
        "03:25 PM - 03:30 PM",
        "03:30 PM - 03:35 PM",
        "03:35 PM - 03:40 PM",
        "03:40 PM - 03:45 PM",
        "03:45 PM - 03:50 PM",
        "03:50 PM - 03:55 PM",
        "03:55 PM - 04:00 PM",
  
        "04:00 PM - 04:05 PM",
        "04:05 PM - 04:10 PM",
        "04:10 PM - 04:15 PM",
        "04:15 PM - 04:20 PM",
        "04:20 PM - 04:25 PM",
        "04:25 PM - 04:30 PM",
        "04:30 PM - 04:35 PM",
        "04:35 PM - 04:40 PM",
        "04:40 PM - 04:45 PM",
        "04:45 PM - 04:50 PM",
        "04:50 PM - 04:55 PM",
        "04:55 PM - 05:00 PM",
      ],
      "10": [
        "09:00 AM - 09:10 AM",
        "09:05 AM - 09:15 AM",
        "09:10 AM - 09:20 AM",
        "09:15 AM - 09:25 AM",
        "09:20 AM - 09:30 AM",
        "09:25 AM - 09:35 AM",
        "09:30 AM - 09:40 AM",
        "09:35 AM - 09:45 AM",
        "09:40 AM - 09:50 AM",
        "09:45 AM - 09:55 AM",
        "09:50 AM - 10:00 AM",
        "09:55 AM - 10:05 AM",
  
        "10:00 AM - 10:10 AM",
        "10:05 AM - 10:15 AM",
        "10:10 AM - 10:20 AM",
        "10:15 AM - 10:25 AM",
        "10:20 AM - 10:30 AM",
        "10:25 AM - 10:35 AM",
        "10:30 AM - 10:40 AM",
        "10:35 AM - 10:45 AM",
        "10:40 AM - 10:50 AM",
        "10:45 AM - 10:55 AM",
        "10:50 AM - 11:00 AM",
        "10:55 AM - 11:05 AM",
  
        "11:00 AM - 11:10 AM",
        "11:05 AM - 11:15 AM",
        "11:10 AM - 11:20 AM",
        "11:15 AM - 11:25 AM",
        "11:20 AM - 11:30 AM",
        "11:25 AM - 11:35 AM",
        "11:30 AM - 11:40 AM",
        "11:35 AM - 11:45 AM",
        "11:40 AM - 11:50 AM",
        "11:45 AM - 11:55 AM",
        "11:50 AM - 12:00 PM",
        "11:55 AM - 12:05 PM",
  
        "12:00 PM - 12:10 PM",
        "12:05 PM - 12:15 PM",
        "12:10 PM - 12:20 PM",
        "12:15 PM - 12:25 PM",
        "12:20 PM - 12:30 PM",
        "12:25 PM - 12:35 PM",
        "12:30 PM - 12:40 PM",
        "12:35 PM - 12:45 PM",
        "12:40 PM - 12:50 PM",
        "12:45 PM - 12:55 PM",
        "12:50 PM - 01:00 PM",
        "12:55 PM - 01:05 PM",
  
        "01:00 PM - 01:10 PM",
        "01:05 PM - 01:15 PM",
        "01:10 PM - 01:20 PM",
        "01:15 PM - 01:25 PM",
        "01:20 PM - 01:30 PM",
        "01:25 PM - 01:35 PM",
        "01:30 PM - 01:40 PM",
        "01:35 PM - 01:45 PM",
        "01:40 PM - 01:50 PM",
        "01:45 PM - 01:55 PM",
        "01:50 PM - 02:00 PM",
        "01:55 PM - 02:05 PM",
  
        "02:00 PM - 02:10 PM",
        "02:05 PM - 02:15 PM",
        "02:10 PM - 02:20 PM",
        "02:15 PM - 02:25 PM",
        "02:20 PM - 02:30 PM",
        "02:25 PM - 02:35 PM",
        "02:30 PM - 02:40 PM",
        "02:35 PM - 02:45 PM",
        "02:40 PM - 02:50 PM",
        "02:45 PM - 02:55 PM",
        "02:50 PM - 03:00 PM",
        "02:55 PM - 03:05 PM",
  
        "03:00 PM - 03:10 PM",
        "03:05 PM - 03:15 PM",
        "03:10 PM - 03:20 PM",
        "03:15 PM - 03:25 PM",
        "03:20 PM - 03:30 PM",
        "03:25 PM - 03:35 PM",
        "03:30 PM - 03:40 PM",
        "03:35 PM - 03:45 PM",
        "03:40 PM - 03:50 PM",
        "03:45 PM - 03:55 PM",
        "03:50 PM - 04:00 PM",
        "03:55 PM - 04:05 PM",
  
        "04:00 PM - 04:10 PM",
        "04:05 PM - 04:15 PM",
        "04:10 PM - 04:20 PM",
        "04:15 PM - 04:25 PM",
        "04:20 PM - 04:30 PM",
        "04:25 PM - 04:35 PM",
        "04:30 PM - 04:40 PM",
        "04:35 PM - 04:45 PM",
        "04:40 PM - 04:50 PM",
        "04:45 PM - 04:55 PM",
        "04:50 PM - 05:00 PM",
      ],
      "15": [
        "09:00 AM - 09:15 AM",
        "09:05 AM - 09:20 AM",
        "09:10 AM - 09:25 AM",
        "09:15 AM - 09:30 AM",
        "09:20 AM - 09:35 AM",
        "09:25 AM - 09:40 AM",
        "09:30 AM - 09:45 AM",
        "09:35 AM - 09:50 AM",
        "09:40 AM - 09:55 AM",
        "09:45 AM - 10:00 AM",
        "09:50 AM - 10:05 AM",
        "09:55 AM - 10:10 AM",
  
        "10:00 AM - 10:15 AM",
        "10:05 AM - 10:20 AM",
        "10:10 AM - 10:25 AM",
        "10:15 AM - 10:30 AM",
        "10:20 AM - 10:35 AM",
        "10:25 AM - 10:40 AM",
        "10:30 AM - 10:45 AM",
        "10:35 AM - 10:50 AM",
        "10:40 AM - 10:55 AM",
        "10:45 AM - 11:00 AM",
        "10:50 AM - 11:05 AM",
        "10:55 AM - 11:10 AM",
  
        "11:00 AM - 11:15 AM",
        "11:05 AM - 11:20 AM",
        "11:10 AM - 11:25 AM",
        "11:15 AM - 11:30 AM",
        "11:20 AM - 11:35 AM",
        "11:25 AM - 11:40 AM",
        "11:30 AM - 11:45 AM",
        "11:35 AM - 11:50 AM",
        "11:40 AM - 11:55 AM",
        "11:45 AM - 12:00 PM",
        "11:50 AM - 12:05 PM",
        "11:55 AM - 12:10 PM",
  
        "12:00 PM - 12:15 PM",
        "12:05 PM - 12:20 PM",
        "12:10 PM - 12:25 PM",
        "12:15 PM - 12:30 PM",
        "12:20 PM - 12:35 PM",
        "12:25 PM - 12:40 PM",
        "12:30 PM - 12:45 PM",
        "12:35 PM - 12:50 PM",
        "12:40 PM - 12:55 PM",
        "12:45 PM - 01:00 PM",
        "12:50 PM - 01:05 PM",
        "12:55 PM - 01:10 PM",
  
        "01:00 PM - 01:15 PM",
        "01:05 PM - 01:20 PM",
        "01:10 PM - 01:25 PM",
        "01:15 PM - 01:30 PM",
        "01:20 PM - 01:35 PM",
        "01:25 PM - 01:40 PM",
        "01:30 PM - 01:45 PM",
        "01:35 PM - 01:50 PM",
        "01:40 PM - 01:55 PM",
        "01:45 PM - 02:00 PM",
        "01:50 PM - 02:05 PM",
        "01:55 PM - 02:10 PM",
  
        "02:00 PM - 02:15 PM",
        "02:05 PM - 02:20 PM",
        "02:10 PM - 02:25 PM",
        "02:15 PM - 02:30 PM",
        "02:20 PM - 02:35 PM",
        "02:25 PM - 02:40 PM",
        "02:30 PM - 02:45 PM",
        "02:35 PM - 02:50 PM",
        "02:40 PM - 02:55 PM",
        "02:45 PM - 03:00 PM",
        "02:50 PM - 03:05 PM",
        "02:55 PM - 03:10 PM",
  
        "03:00 PM - 03:15 PM",
        "03:05 PM - 03:20 PM",
        "03:10 PM - 03:25 PM",
        "03:15 PM - 03:30 PM",
        "03:20 PM - 03:35 PM",
        "03:25 PM - 03:40 PM",
        "03:30 PM - 03:45 PM",
        "03:35 PM - 03:50 PM",
        "03:40 PM - 03:55 PM",
        "03:45 PM - 04:00 PM",
        "03:50 PM - 04:05 PM",
        "03:55 PM - 04:10 PM",
  
        "04:00 PM - 04:15 PM",
        "04:05 PM - 04:20 PM",
        "04:10 PM - 04:25 PM",
        "04:15 PM - 04:30 PM",
        "04:20 PM - 04:35 PM",
        "04:25 PM - 04:40 PM",
        "04:30 PM - 04:45 PM",
        "04:35 PM - 04:50 PM",
        "04:40 PM - 04:55 PM",
        "04:45 PM - 05:00 PM",
      ],
      "30": [
        "09:00 AM - 09:30 AM",
        "09:05 AM - 09:35 AM",
        "09:10 AM - 09:40 AM",
        "09:15 AM - 09:45 AM",
        "09:20 AM - 09:50 AM",
        "09:25 AM - 09:55 AM",
        "09:30 AM - 10:00 AM",
        "09:35 AM - 10:05 AM",
        "09:40 AM - 10:10 AM",
        "09:45 AM - 10:15 AM",
        "09:50 AM - 10:20 AM",
        "09:55 AM - 10:25 AM",
  
        "10:00 AM - 10:30 AM",
        "10:05 AM - 10:35 AM",
        "10:10 AM - 10:40 AM",
        "10:15 AM - 10:45 AM",
        "10:20 AM - 10:50 AM",
        "10:25 AM - 10:55 AM",
        "10:30 AM - 11:00 AM",
        "10:35 AM - 11:05 AM",
        "10:40 AM - 11:10 AM",
        "10:45 AM - 11:15 AM",
        "10:50 AM - 11:20 AM",
        "10:55 AM - 11:25 AM",
  
        "11:00 AM - 11:30 AM",
        "11:05 AM - 11:35 AM",
        "11:10 AM - 11:40 AM",
        "11:15 AM - 11:45 AM",
        "11:20 AM - 11:50 AM",
        "11:25 AM - 11:55 AM",
        "11:30 AM - 12:00 PM",
        "11:35 AM - 12:05 PM",
        "11:40 AM - 12:10 PM",
        "11:45 AM - 12:15 PM",
        "11:50 AM - 12:20 PM",
        "11:55 AM - 12:25 PM",
  
        "12:00 PM - 12:30 PM",
        "12:05 PM - 12:35 PM",
        "12:10 PM - 12:40 PM",
        "12:15 PM - 12:45 PM",
        "12:20 PM - 12:50 PM",
        "12:25 PM - 12:55 PM",
        "12:30 PM - 01:00 PM",
        "12:35 PM - 01:05 PM",
        "12:40 PM - 01:10 PM",
        "12:45 PM - 01:15 PM",
        "12:50 PM - 01:20 PM",
        "12:55 PM - 01:25 PM",
  
        "01:00 PM - 01:30 PM",
        "01:05 PM - 01:35 PM",
        "01:10 PM - 01:40 PM",
        "01:15 PM - 01:45 PM",
        "01:20 PM - 01:50 PM",
        "01:25 PM - 01:55 PM",
        "01:30 PM - 02:00 PM",
        "01:35 PM - 02:05 PM",
        "01:40 PM - 02:10 PM",
        "01:45 PM - 02:15 PM",
        "01:50 PM - 02:20 PM",
        "01:55 PM - 02:25 PM",
  
        "02:00 PM - 02:30 PM",
        "02:05 PM - 02:35 PM",
        "02:10 PM - 02:40 PM",
        "02:15 PM - 02:45 PM",
        "02:20 PM - 02:50 PM",
        "02:25 PM - 02:55 PM",
        "02:30 PM - 03:00 PM",
        "02:35 PM - 03:05 PM",
        "02:40 PM - 03:10 PM",
        "02:45 PM - 03:15 PM",
        "02:50 PM - 03:20 PM",
        "02:55 PM - 03:25 PM",
  
        "03:00 PM - 03:30 PM",
        "03:05 PM - 03:35 PM",
        "03:10 PM - 03:40 PM",
        "03:15 PM - 03:45 PM",
        "03:20 PM - 03:50 PM",
        "03:25 PM - 03:55 PM",
        "03:30 PM - 04:00 PM",
        "03:35 PM - 04:05 PM",
        "03:40 PM - 04:10 PM",
        "03:45 PM - 04:15 PM",
        "03:50 PM - 04:20 PM",
        "03:55 PM - 04:25 PM",
  
        "04:00 PM - 04:30 PM",
        "04:05 PM - 04:35 PM",
        "04:10 PM - 04:40 PM",
        "04:15 PM - 04:45 PM",
        "04:20 PM - 04:50 PM",
        "04:25 PM - 04:55 PM",
        "04:30 PM - 05:00 PM",
        
      ],
      "60": [
        "09:00 AM - 10:00 AM",
        "09:05 AM - 10:05 AM",
        "09:10 AM - 10:10 AM",
        "09:15 AM - 10:15 AM",
        "09:20 AM - 10:20 AM",
        "09:25 AM - 10:25 AM",
        "09:30 AM - 10:30 AM",
        "09:35 AM - 10:35 AM",
        "09:40 AM - 10:40 AM",
        "09:45 AM - 10:45 AM",
        "09:50 AM - 10:50 AM",
        "09:55 AM - 10:55 AM",
  
        "10:00 AM - 11:00 AM",
        "10:05 AM - 11:05 AM",
        "10:10 AM - 11:10 AM",
        "10:15 AM - 11:15 AM",
        "10:20 AM - 11:20 AM",
        "10:25 AM - 11:25 AM",
        "10:30 AM - 11:30 AM",
        "10:35 AM - 11:35 AM",
        "10:40 AM - 11:40 AM",
        "10:45 AM - 11:45 AM",
        "10:50 AM - 11:50 AM",
        "10:55 AM - 11:55 AM",
  
        "11:00 AM - 12:00 PM",
        "11:05 AM - 12:05 PM",
        "11:10 AM - 12:10 PM",
        "11:15 AM - 12:15 PM",
        "11:20 AM - 12:20 PM",
        "11:25 AM - 12:25 PM",
        "11:30 AM - 12:30 PM",
        "11:35 AM - 12:35 PM",
        "11:40 AM - 12:40 PM",
        "11:45 AM - 12:45 PM",
        "11:50 AM - 12:50 PM",
        "11:55 AM - 12:55 PM",
  
        "12:00 PM - 01:00 PM",
        "12:05 PM - 01:05 PM",
        "12:10 PM - 01:10 PM",
        "12:15 PM - 01:15 PM",
        "12:20 PM - 01:20 PM",
        "12:25 PM - 01:25 PM",
        "12:30 PM - 01:30 PM",
        "12:35 PM - 01:35 PM",
        "12:40 PM - 01:40 PM",
        "12:45 PM - 01:45 PM",
        "12:50 PM - 01:50 PM",
        "12:55 PM - 01:55 PM",
  
        "01:00 PM - 02:00 PM",
        "01:05 PM - 02:05 PM",
        "01:10 PM - 02:10 PM",
        "01:15 PM - 02:15 PM",
        "01:20 PM - 02:20 PM",
        "01:25 PM - 02:25 PM",
        "01:30 PM - 02:30 PM",
        "01:35 PM - 02:35 PM",
        "01:40 PM - 02:40 PM",
        "01:45 PM - 02:45 PM",
        "01:50 PM - 02:50 PM",
        "01:55 PM - 02:55 PM",
  
        "02:00 PM - 03:00 PM",
        "02:05 PM - 03:05 PM",
        "02:10 PM - 03:10 PM",
        "02:15 PM - 03:15 PM",
        "02:20 PM - 03:20 PM",
        "02:25 PM - 03:25 PM",
        "02:30 PM - 03:30 PM",
        "02:35 PM - 03:35 PM",
        "02:40 PM - 03:40 PM",
        "02:45 PM - 03:45 PM",
        "02:50 PM - 03:50 PM",
        "02:55 PM - 03:55 PM",
  
        "03:00 PM - 04:00 PM",
        "03:05 PM - 04:05 PM",
        "03:10 PM - 04:10 PM",
        "03:15 PM - 04:15 PM",
        "03:20 PM - 04:20 PM",
        "03:25 PM - 04:25 PM",
        "03:30 PM - 04:30 PM",
        "03:35 PM - 04:35 PM",
        "03:40 PM - 04:40 PM",
        "03:45 PM - 04:45 PM",
        "03:50 PM - 04:50 PM",
        "03:55 PM - 04:55 PM",
  
        "04:00 PM - 05:00 PM"
      ],
      "20": [
        "09:00 AM - 09:20 AM",
        "09:05 AM - 09:25 AM",
        "09:10 AM - 09:30 AM",
        "09:15 AM - 09:35 AM",
        "09:20 AM - 09:40 AM",
        "09:25 AM - 09:45 AM",
        "09:30 AM - 09:50 AM",
        "09:35 AM - 09:55 AM",
        "09:40 AM - 10:00 AM",
        "09:45 AM - 10:05 AM",
        "09:50 AM - 10:10 AM",
        "09:55 AM - 10:15 AM",

        "10:00 AM - 10:20 AM",
        "10:05 AM - 10:25 AM",
        "10:10 AM - 10:30 AM",
        "10:15 AM - 10:35 AM",
        "10:20 AM - 10:40 AM",
        "10:25 AM - 10:45 AM",
        "10:30 AM - 10:50 AM",
        "10:35 AM - 10:55 AM",
        "10:40 AM - 11:00 AM",
        "10:45 AM - 11:05 AM",
        "10:50 AM - 11:10 AM",
        "10:55 AM - 11:15 AM",

        "11:00 AM - 11:20 AM",
        "11:05 AM - 11:25 AM",
        "11:10 AM - 11:30 AM",
        "11:15 AM - 11:35 AM",
        "11:20 AM - 11:40 AM",
        "11:25 AM - 11:45 AM",
        "11:30 AM - 11:50 AM",
        "11:35 AM - 11:55 AM",
        "11:40 AM - 12:00 PM",
        "11:45 AM - 12:05 PM",
        "11:50 AM - 12:10 PM",
        "11:55 AM - 12:15 PM",

        "12:00 PM - 12:20 PM",
        "12:05 PM - 12:25 PM",
        "12:10 PM - 12:30 PM",
        "12:15 PM - 12:35 PM",
        "12:20 PM - 12:40 PM",
        "12:25 PM - 12:45 PM",
        "12:30 PM - 12:50 PM",
        "12:35 PM - 12:55 PM",
        "12:40 PM - 01:00 PM",
        "12:45 PM - 01:05 PM",
        "12:50 PM - 01:10 PM",
        "12:55 PM - 01:15 PM",

        "01:00 PM - 01:20 PM",
        "01:05 PM - 01:25 PM",
        "01:10 PM - 01:30 PM",
        "01:15 PM - 01:35 PM",
        "01:20 PM - 01:40 PM",
        "01:25 PM - 01:45 PM",
        "01:30 PM - 01:50 PM",
        "01:35 PM - 01:55 PM",
        "01:40 PM - 02:00 PM",
        "01:45 PM - 02:05 PM",
        "01:50 PM - 02:10 PM",
        "01:55 PM - 02:15 PM",

        "02:00 PM - 02:20 PM",
        "02:05 PM - 02:25 PM",
        "02:10 PM - 02:30 PM",
        "02:15 PM - 02:35 PM",
        "02:20 PM - 02:40 PM",
        "02:25 PM - 02:45 PM",
        "02:30 PM - 02:50 PM",
        "02:35 PM - 02:55 PM",
        "02:40 PM - 03:00 PM",
        "02:45 PM - 03:05 PM",
        "02:50 PM - 03:10 PM",
        "02:55 PM - 03:15 PM",

        "03:00 PM - 03:20 PM",
        "03:05 PM - 03:25 PM",
        "03:10 PM - 03:30 PM",
        "03:15 PM - 03:35 PM",
        "03:20 PM - 03:40 PM",
        "03:25 PM - 03:45 PM",
        "03:30 PM - 03:50 PM",
        "03:35 PM - 03:55 PM",
        "03:40 PM - 04:00 PM",
        "03:45 PM - 04:05 PM",
        "03:50 PM - 04:10 PM",
        "03:55 PM - 04:15 PM",

        "04:00 PM - 04:20 PM",
        "04:05 PM - 04:25 PM",
        "04:10 PM - 04:30 PM",
        "04:15 PM - 04:35 PM",
        "04:20 PM - 04:40 PM",
        "04:25 PM - 04:45 PM",
        "04:30 PM - 04:50 PM",
        "04:35 PM - 04:55 PM",
        "04:40 PM - 05:00 PM"
      ],
      "45": [
        "09:00 AM - 09:45 AM",
        "09:05 AM - 09:50 AM",
        "09:10 AM - 09:55 AM",
        "09:15 AM - 10:00 AM",
        "09:20 AM - 10:05 AM",
        "09:25 AM - 10:10 AM",
        "09:30 AM - 10:15 AM",
        "09:35 AM - 10:20 AM",
        "09:40 AM - 10:25 AM",
        "09:45 AM - 10:30 AM",
        "09:50 AM - 10:35 AM",
        "09:55 AM - 10:40 AM",

        "10:00 AM - 10:45 AM",
        "10:05 AM - 10:50 AM",
        "10:10 AM - 10:55 AM",
        "10:15 AM - 11:00 AM",
        "10:20 AM - 11:05 AM",
        "10:25 AM - 11:10 AM",
        "10:30 AM - 11:15 AM",
        "10:35 AM - 11:20 AM",
        "10:40 AM - 11:25 AM",
        "10:45 AM - 11:30 AM",
        "10:50 AM - 11:35 AM",
        "10:55 AM - 11:40 AM",

        "11:00 AM - 11:45 AM",
        "11:05 AM - 11:50 AM",
        "11:10 AM - 11:55 AM",
        "11:15 AM - 12:00 PM",
        "11:20 AM - 12:05 PM",
        "11:25 AM - 12:10 PM",
        "11:30 AM - 12:15 PM",
        "11:35 AM - 12:20 PM",
        "11:40 AM - 12:25 PM",
        "11:45 AM - 12:30 PM",
        "11:50 AM - 12:35 PM",
        "11:55 AM - 12:40 PM",

        "12:00 PM - 12:45 PM",
        "12:05 PM - 12:50 PM",
        "12:10 PM - 12:55 PM",
        "12:15 PM - 01:00 PM",
        "12:20 PM - 01:05 PM",
        "12:25 PM - 01:10 PM",
        "12:30 PM - 01:15 PM",
        "12:35 PM - 01:20 PM",
        "12:40 PM - 01:25 PM",
        "12:45 PM - 01:30 PM",
        "12:50 PM - 01:35 PM",
        "12:55 PM - 01:40 PM",

        "01:00 PM - 01:45 PM",
        "01:05 PM - 01:50 PM",
        "01:10 PM - 01:55 PM",
        "01:15 PM - 02:00 PM",
        "01:20 PM - 02:05 PM",
        "01:25 PM - 02:10 PM",
        "01:30 PM - 02:15 PM",
        "01:35 PM - 02:20 PM",
        "01:40 PM - 02:25 PM",
        "01:45 PM - 02:30 PM",
        "01:50 PM - 02:35 PM",
        "01:55 PM - 02:40 PM",

        "02:00 PM - 02:45 PM",
        "02:05 PM - 02:50 PM",
        "02:10 PM - 02:55 PM",
        "02:15 PM - 03:00 PM",
        "02:20 PM - 03:05 PM",
        "02:25 PM - 03:10 PM",
        "02:30 PM - 03:15 PM",
        "02:35 PM - 03:20 PM",
        "02:40 PM - 03:25 PM",
        "02:45 PM - 03:30 PM",
        "02:50 PM - 03:35 PM",
        "02:55 PM - 03:40 PM",
        
        "03:00 PM - 03:45 PM",
        "03:05 PM - 03:50 PM",
        "03:10 PM - 03:55 PM",
        "03:15 PM - 04:00 PM",
        "03:20 PM - 04:05 PM",
        "03:25 PM - 04:10 PM",
        "03:30 PM - 04:15 PM",
        "03:35 PM - 04:20 PM",
        "03:40 PM - 04:25 PM",
        "03:45 PM - 04:30 PM",
        "03:50 PM - 04:35 PM",
        "03:55 PM - 04:40 PM",

        "04:00 PM - 04:45 PM",
        "04:05 PM - 04:50 PM",
        "04:10 PM - 04:55 PM",
        "04:15 PM - 05:00 PM"
      ]
    };

  export const mutableTimeArray = {
    "5": [
      "09:00 AM - 09:05 AM",
      "09:05 AM - 09:10 AM",
      "09:10 AM - 09:15 AM",
      "09:15 AM - 09:20 AM",
      "09:20 AM - 09:25 AM",
      "09:25 AM - 09:30 AM",
      "09:30 AM - 09:35 AM",
      "09:35 AM - 09:40 AM",
      "09:40 AM - 09:45 AM",
      "09:45 AM - 09:50 AM",
      "09:50 AM - 09:55 AM",
      "09:55 AM - 10:00 AM",

      "10:00 AM - 10:05 AM",
      "10:05 AM - 10:10 AM",
      "10:10 AM - 10:15 AM",
      "10:15 AM - 10:20 AM",
      "10:20 AM - 10:25 AM",
      "10:25 AM - 10:30 AM",
      "10:30 AM - 10:35 AM",
      "10:35 AM - 10:40 AM",
      "10:40 AM - 10:45 AM",
      "10:45 AM - 10:50 AM",
      "10:50 AM - 10:55 AM",
      "10:55 AM - 11:00 AM",

      "11:00 AM - 11:05 AM",
      "11:05 AM - 11:10 AM",
      "11:10 AM - 11:15 AM",
      "11:15 AM - 11:20 AM",
      "11:20 AM - 11:25 AM",
      "11:25 AM - 11:30 AM",
      "11:30 AM - 11:35 AM",
      "11:35 AM - 11:40 AM",
      "11:40 AM - 11:45 AM",
      "11:45 AM - 11:50 AM",
      "11:50 AM - 11:55 AM",
      "11:55 AM - 12:00 PM",

      "12:00 PM - 12:05 PM",
      "12:05 PM - 12:10 PM",
      "12:10 PM - 12:15 PM",
      "12:15 PM - 12:20 PM",
      "12:20 PM - 12:25 PM",
      "12:25 PM - 12:30 PM",
      "12:30 PM - 12:35 PM",
      "12:35 PM - 12:40 PM",
      "12:40 PM - 12:45 PM",
      "12:45 PM - 12:50 PM",
      "12:50 PM - 12:55 PM",
      "12:55 PM - 01:00 PM",

      "01:00 PM - 01:05 PM",
      "01:05 PM - 01:10 PM",
      "01:10 PM - 01:15 PM",
      "01:15 PM - 01:20 PM",
      "01:20 PM - 01:25 PM",
      "01:25 PM - 01:30 PM",
      "01:30 PM - 01:35 PM",
      "01:35 PM - 01:40 PM",
      "01:40 PM - 01:45 PM",
      "01:45 PM - 01:50 PM",
      "01:50 PM - 01:55 PM",
      "01:55 PM - 02:00 PM",

      "02:00 PM - 02:05 PM",
      "02:05 PM - 02:10 PM",
      "02:10 PM - 02:15 PM",
      "02:15 PM - 02:20 PM",
      "02:20 PM - 02:25 PM",
      "02:25 PM - 02:30 PM",
      "02:30 PM - 02:35 PM",
      "02:35 PM - 02:40 PM",
      "02:40 PM - 02:45 PM",
      "02:45 PM - 02:50 PM",
      "02:50 PM - 02:55 PM",
      "02:55 PM - 03:00 PM",

      "03:00 PM - 03:05 PM",
      "03:05 PM - 03:10 PM",
      "03:10 PM - 03:15 PM",
      "03:15 PM - 03:20 PM",
      "03:20 PM - 03:25 PM",
      "03:25 PM - 03:30 PM",
      "03:30 PM - 03:35 PM",
      "03:35 PM - 03:40 PM",
      "03:40 PM - 03:45 PM",
      "03:45 PM - 03:50 PM",
      "03:50 PM - 03:55 PM",
      "03:55 PM - 04:00 PM",

      "04:00 PM - 04:05 PM",
      "04:05 PM - 04:10 PM",
      "04:10 PM - 04:15 PM",
      "04:15 PM - 04:20 PM",
      "04:20 PM - 04:25 PM",
      "04:25 PM - 04:30 PM",
      "04:30 PM - 04:35 PM",
      "04:35 PM - 04:40 PM",
      "04:40 PM - 04:45 PM",
      "04:45 PM - 04:50 PM",
      "04:50 PM - 04:55 PM",
      "04:55 PM - 05:00 PM",
    ],
    "10": [
      "09:00 AM - 09:10 AM",
      "09:05 AM - 09:15 AM",
      "09:10 AM - 09:20 AM",
      "09:15 AM - 09:25 AM",
      "09:20 AM - 09:30 AM",
      "09:25 AM - 09:35 AM",
      "09:30 AM - 09:40 AM",
      "09:35 AM - 09:45 AM",
      "09:40 AM - 09:50 AM",
      "09:45 AM - 09:55 AM",
      "09:50 AM - 10:00 AM",
      "09:55 AM - 10:05 AM",

      "10:00 AM - 10:10 AM",
      "10:05 AM - 10:15 AM",
      "10:10 AM - 10:20 AM",
      "10:15 AM - 10:25 AM",
      "10:20 AM - 10:30 AM",
      "10:25 AM - 10:35 AM",
      "10:30 AM - 10:40 AM",
      "10:35 AM - 10:45 AM",
      "10:40 AM - 10:50 AM",
      "10:45 AM - 10:55 AM",
      "10:50 AM - 11:00 AM",
      "10:55 AM - 11:05 AM",

      "11:00 AM - 11:10 AM",
      "11:05 AM - 11:15 AM",
      "11:10 AM - 11:20 AM",
      "11:15 AM - 11:25 AM",
      "11:20 AM - 11:30 AM",
      "11:25 AM - 11:35 AM",
      "11:30 AM - 11:40 AM",
      "11:35 AM - 11:45 AM",
      "11:40 AM - 11:50 AM",
      "11:45 AM - 11:55 AM",
      "11:50 AM - 12:00 PM",
      "11:55 AM - 12:05 PM",

      "12:00 PM - 12:10 PM",
      "12:05 PM - 12:15 PM",
      "12:10 PM - 12:20 PM",
      "12:15 PM - 12:25 PM",
      "12:20 PM - 12:30 PM",
      "12:25 PM - 12:35 PM",
      "12:30 PM - 12:40 PM",
      "12:35 PM - 12:45 PM",
      "12:40 PM - 12:50 PM",
      "12:45 PM - 12:55 PM",
      "12:50 PM - 01:00 PM",
      "12:55 PM - 01:05 PM",

      "01:00 PM - 01:10 PM",
      "01:05 PM - 01:15 PM",
      "01:10 PM - 01:20 PM",
      "01:15 PM - 01:25 PM",
      "01:20 PM - 01:30 PM",
      "01:25 PM - 01:35 PM",
      "01:30 PM - 01:40 PM",
      "01:35 PM - 01:45 PM",
      "01:40 PM - 01:50 PM",
      "01:45 PM - 01:55 PM",
      "01:50 PM - 02:00 PM",
      "01:55 PM - 02:05 PM",

      "02:00 PM - 02:10 PM",
      "02:05 PM - 02:15 PM",
      "02:10 PM - 02:20 PM",
      "02:15 PM - 02:25 PM",
      "02:20 PM - 02:30 PM",
      "02:25 PM - 02:35 PM",
      "02:30 PM - 02:40 PM",
      "02:35 PM - 02:45 PM",
      "02:40 PM - 02:50 PM",
      "02:45 PM - 02:55 PM",
      "02:50 PM - 03:00 PM",
      "02:55 PM - 03:05 PM",

      "03:00 PM - 03:10 PM",
      "03:05 PM - 03:15 PM",
      "03:10 PM - 03:20 PM",
      "03:15 PM - 03:25 PM",
      "03:20 PM - 03:30 PM",
      "03:25 PM - 03:35 PM",
      "03:30 PM - 03:40 PM",
      "03:35 PM - 03:45 PM",
      "03:40 PM - 03:50 PM",
      "03:45 PM - 03:55 PM",
      "03:50 PM - 04:00 PM",
      "03:55 PM - 04:05 PM",

      "04:00 PM - 04:10 PM",
      "04:05 PM - 04:15 PM",
      "04:10 PM - 04:20 PM",
      "04:15 PM - 04:25 PM",
      "04:20 PM - 04:30 PM",
      "04:25 PM - 04:35 PM",
      "04:30 PM - 04:40 PM",
      "04:35 PM - 04:45 PM",
      "04:40 PM - 04:50 PM",
      "04:45 PM - 04:55 PM",
      "04:50 PM - 05:00 PM",
    ],
    "15": [
      "09:00 AM - 09:15 AM",
      "09:05 AM - 09:20 AM",
      "09:10 AM - 09:25 AM",
      "09:15 AM - 09:30 AM",
      "09:20 AM - 09:35 AM",
      "09:25 AM - 09:40 AM",
      "09:30 AM - 09:45 AM",
      "09:35 AM - 09:50 AM",
      "09:40 AM - 09:55 AM",
      "09:45 AM - 10:00 AM",
      "09:50 AM - 10:05 AM",
      "09:55 AM - 10:10 AM",

      "10:00 AM - 10:15 AM",
      "10:05 AM - 10:20 AM",
      "10:10 AM - 10:25 AM",
      "10:15 AM - 10:30 AM",
      "10:20 AM - 10:35 AM",
      "10:25 AM - 10:40 AM",
      "10:30 AM - 10:45 AM",
      "10:35 AM - 10:50 AM",
      "10:40 AM - 10:55 AM",
      "10:45 AM - 11:00 AM",
      "10:50 AM - 11:05 AM",
      "10:55 AM - 11:10 AM",

      "11:00 AM - 11:15 AM",
      "11:05 AM - 11:20 AM",
      "11:10 AM - 11:25 AM",
      "11:15 AM - 11:30 AM",
      "11:20 AM - 11:35 AM",
      "11:25 AM - 11:40 AM",
      "11:30 AM - 11:45 AM",
      "11:35 AM - 11:50 AM",
      "11:40 AM - 11:55 AM",
      "11:45 AM - 12:00 PM",
      "11:50 AM - 12:05 PM",
      "11:55 AM - 12:10 PM",

      "12:00 PM - 12:15 PM",
      "12:05 PM - 12:20 PM",
      "12:10 PM - 12:25 PM",
      "12:15 PM - 12:30 PM",
      "12:20 PM - 12:35 PM",
      "12:25 PM - 12:40 PM",
      "12:30 PM - 12:45 PM",
      "12:35 PM - 12:50 PM",
      "12:40 PM - 12:55 PM",
      "12:45 PM - 01:00 PM",
      "12:50 PM - 01:05 PM",
      "12:55 PM - 01:10 PM",

      "01:00 PM - 01:15 PM",
      "01:05 PM - 01:20 PM",
      "01:10 PM - 01:25 PM",
      "01:15 PM - 01:30 PM",
      "01:20 PM - 01:35 PM",
      "01:25 PM - 01:40 PM",
      "01:30 PM - 01:45 PM",
      "01:35 PM - 01:50 PM",
      "01:40 PM - 01:55 PM",
      "01:45 PM - 02:00 PM",
      "01:50 PM - 02:05 PM",
      "01:55 PM - 02:10 PM",

      "02:00 PM - 02:15 PM",
      "02:05 PM - 02:20 PM",
      "02:10 PM - 02:25 PM",
      "02:15 PM - 02:30 PM",
      "02:20 PM - 02:35 PM",
      "02:25 PM - 02:40 PM",
      "02:30 PM - 02:45 PM",
      "02:35 PM - 02:50 PM",
      "02:40 PM - 02:55 PM",
      "02:45 PM - 03:00 PM",
      "02:50 PM - 03:05 PM",
      "02:55 PM - 03:10 PM",

      "03:00 PM - 03:15 PM",
      "03:05 PM - 03:20 PM",
      "03:10 PM - 03:25 PM",
      "03:15 PM - 03:30 PM",
      "03:20 PM - 03:35 PM",
      "03:25 PM - 03:40 PM",
      "03:30 PM - 03:45 PM",
      "03:35 PM - 03:50 PM",
      "03:40 PM - 03:55 PM",
      "03:45 PM - 04:00 PM",
      "03:50 PM - 04:05 PM",
      "03:55 PM - 04:10 PM",

      "04:00 PM - 04:15 PM",
      "04:05 PM - 04:20 PM",
      "04:10 PM - 04:25 PM",
      "04:15 PM - 04:30 PM",
      "04:20 PM - 04:35 PM",
      "04:25 PM - 04:40 PM",
      "04:30 PM - 04:45 PM",
      "04:35 PM - 04:50 PM",
      "04:40 PM - 04:55 PM",
      "04:45 PM - 05:00 PM",
    ],
    "30": [
      "09:00 AM - 09:30 AM",
      "09:05 AM - 09:35 AM",
      "09:10 AM - 09:40 AM",
      "09:15 AM - 09:45 AM",
      "09:20 AM - 09:50 AM",
      "09:25 AM - 09:55 AM",
      "09:30 AM - 10:00 AM",
      "09:35 AM - 10:05 AM",
      "09:40 AM - 10:10 AM",
      "09:45 AM - 10:15 AM",
      "09:50 AM - 10:20 AM",
      "09:55 AM - 10:25 AM",

      "10:00 AM - 10:30 AM",
      "10:05 AM - 10:35 AM",
      "10:10 AM - 10:40 AM",
      "10:15 AM - 10:45 AM",
      "10:20 AM - 10:50 AM",
      "10:25 AM - 10:55 AM",
      "10:30 AM - 11:00 AM",
      "10:35 AM - 11:05 AM",
      "10:40 AM - 11:10 AM",
      "10:45 AM - 11:15 AM",
      "10:50 AM - 11:20 AM",
      "10:55 AM - 11:25 AM",

      "11:00 AM - 11:30 AM",
      "11:05 AM - 11:35 AM",
      "11:10 AM - 11:40 AM",
      "11:15 AM - 11:45 AM",
      "11:20 AM - 11:50 AM",
      "11:25 AM - 11:55 AM",
      "11:30 AM - 12:00 PM",
      "11:35 AM - 12:05 PM",
      "11:40 AM - 12:10 PM",
      "11:45 AM - 12:15 PM",
      "11:50 AM - 12:20 PM",
      "11:55 AM - 12:25 PM",

      "12:00 PM - 12:30 PM",
      "12:05 PM - 12:35 PM",
      "12:10 PM - 12:40 PM",
      "12:15 PM - 12:45 PM",
      "12:20 PM - 12:50 PM",
      "12:25 PM - 12:55 PM",
      "12:30 PM - 01:00 PM",
      "12:35 PM - 01:05 PM",
      "12:40 PM - 01:10 PM",
      "12:45 PM - 01:15 PM",
      "12:50 PM - 01:20 PM",
      "12:55 PM - 01:25 PM",

      "01:00 PM - 01:30 PM",
      "01:05 PM - 01:35 PM",
      "01:10 PM - 01:40 PM",
      "01:15 PM - 01:45 PM",
      "01:20 PM - 01:50 PM",
      "01:25 PM - 01:55 PM",
      "01:30 PM - 02:00 PM",
      "01:35 PM - 02:05 PM",
      "01:40 PM - 02:10 PM",
      "01:45 PM - 02:15 PM",
      "01:50 PM - 02:20 PM",
      "01:55 PM - 02:25 PM",

      "02:00 PM - 02:30 PM",
      "02:05 PM - 02:35 PM",
      "02:10 PM - 02:40 PM",
      "02:15 PM - 02:45 PM",
      "02:20 PM - 02:50 PM",
      "02:25 PM - 02:55 PM",
      "02:30 PM - 03:00 PM",
      "02:35 PM - 03:05 PM",
      "02:40 PM - 03:10 PM",
      "02:45 PM - 03:15 PM",
      "02:50 PM - 03:20 PM",
      "02:55 PM - 03:25 PM",

      "03:00 PM - 03:30 PM",
      "03:05 PM - 03:35 PM",
      "03:10 PM - 03:40 PM",
      "03:15 PM - 03:45 PM",
      "03:20 PM - 03:50 PM",
      "03:25 PM - 03:55 PM",
      "03:30 PM - 04:00 PM",
      "03:35 PM - 04:05 PM",
      "03:40 PM - 04:10 PM",
      "03:45 PM - 04:15 PM",
      "03:50 PM - 04:20 PM",
      "03:55 PM - 04:25 PM",

      "04:00 PM - 04:30 PM",
      "04:05 PM - 04:35 PM",
      "04:10 PM - 04:40 PM",
      "04:15 PM - 04:45 PM",
      "04:20 PM - 04:50 PM",
      "04:25 PM - 04:55 PM",
      "04:30 PM - 05:00 PM",
      
    ],
    "60": [
      "09:00 AM - 10:00 AM",
      "09:05 AM - 10:05 AM",
      "09:10 AM - 10:10 AM",
      "09:15 AM - 10:15 AM",
      "09:20 AM - 10:20 AM",
      "09:25 AM - 10:25 AM",
      "09:30 AM - 10:30 AM",
      "09:35 AM - 10:35 AM",
      "09:40 AM - 10:40 AM",
      "09:45 AM - 10:45 AM",
      "09:50 AM - 10:50 AM",
      "09:55 AM - 10:55 AM",

      "10:00 AM - 11:00 AM",
      "10:05 AM - 11:05 AM",
      "10:10 AM - 11:10 AM",
      "10:15 AM - 11:15 AM",
      "10:20 AM - 11:20 AM",
      "10:25 AM - 11:25 AM",
      "10:30 AM - 11:30 AM",
      "10:35 AM - 11:35 AM",
      "10:40 AM - 11:40 AM",
      "10:45 AM - 11:45 AM",
      "10:50 AM - 11:50 AM",
      "10:55 AM - 11:55 AM",

      "11:00 AM - 12:00 PM",
      "11:05 AM - 12:05 PM",
      "11:10 AM - 12:10 PM",
      "11:15 AM - 12:15 PM",
      "11:20 AM - 12:20 PM",
      "11:25 AM - 12:25 PM",
      "11:30 AM - 12:30 PM",
      "11:35 AM - 12:35 PM",
      "11:40 AM - 12:40 PM",
      "11:45 AM - 12:45 PM",
      "11:50 AM - 12:50 PM",
      "11:55 AM - 12:55 PM",

      "12:00 PM - 01:00 PM",
      "12:05 PM - 01:05 PM",
      "12:10 PM - 01:10 PM",
      "12:15 PM - 01:15 PM",
      "12:20 PM - 01:20 PM",
      "12:25 PM - 01:25 PM",
      "12:30 PM - 01:30 PM",
      "12:35 PM - 01:35 PM",
      "12:40 PM - 01:40 PM",
      "12:45 PM - 01:45 PM",
      "12:50 PM - 01:50 PM",
      "12:55 PM - 01:55 PM",

      "01:00 PM - 02:00 PM",
      "01:05 PM - 02:05 PM",
      "01:10 PM - 02:10 PM",
      "01:15 PM - 02:15 PM",
      "01:20 PM - 02:20 PM",
      "01:25 PM - 02:25 PM",
      "01:30 PM - 02:30 PM",
      "01:35 PM - 02:35 PM",
      "01:40 PM - 02:40 PM",
      "01:45 PM - 02:45 PM",
      "01:50 PM - 02:50 PM",
      "01:55 PM - 02:55 PM",

      "02:00 PM - 03:00 PM",
      "02:05 PM - 03:05 PM",
      "02:10 PM - 03:10 PM",
      "02:15 PM - 03:15 PM",
      "02:20 PM - 03:20 PM",
      "02:25 PM - 03:25 PM",
      "02:30 PM - 03:30 PM",
      "02:35 PM - 03:35 PM",
      "02:40 PM - 03:40 PM",
      "02:45 PM - 03:45 PM",
      "02:50 PM - 03:50 PM",
      "02:55 PM - 03:55 PM",

      "03:00 PM - 04:00 PM",
      "03:05 PM - 04:05 PM",
      "03:10 PM - 04:10 PM",
      "03:15 PM - 04:15 PM",
      "03:20 PM - 04:20 PM",
      "03:25 PM - 04:25 PM",
      "03:30 PM - 04:30 PM",
      "03:35 PM - 04:35 PM",
      "03:40 PM - 04:40 PM",
      "03:45 PM - 04:45 PM",
      "03:50 PM - 04:50 PM",
      "03:55 PM - 04:55 PM",

      "04:00 PM - 05:00 PM"
    ],
    "20": [
      "09:00 AM - 09:20 AM",
      "09:05 AM - 09:25 AM",
      "09:10 AM - 09:30 AM",
      "09:15 AM - 09:35 AM",
      "09:20 AM - 09:40 AM",
      "09:25 AM - 09:45 AM",
      "09:30 AM - 09:50 AM",
      "09:35 AM - 09:55 AM",
      "09:40 AM - 10:00 AM",
      "09:45 AM - 10:05 AM",
      "09:50 AM - 10:10 AM",
      "09:55 AM - 10:15 AM",

      "10:00 AM - 10:20 AM",
      "10:05 AM - 10:25 AM",
      "10:10 AM - 10:30 AM",
      "10:15 AM - 10:35 AM",
      "10:20 AM - 10:40 AM",
      "10:25 AM - 10:45 AM",
      "10:30 AM - 10:50 AM",
      "10:35 AM - 10:55 AM",
      "10:40 AM - 11:00 AM",
      "10:45 AM - 11:05 AM",
      "10:50 AM - 11:10 AM",
      "10:55 AM - 11:15 AM",

      "11:00 AM - 11:20 AM",
      "11:05 AM - 11:25 AM",
      "11:10 AM - 11:30 AM",
      "11:15 AM - 11:35 AM",
      "11:20 AM - 11:40 AM",
      "11:25 AM - 11:45 AM",
      "11:30 AM - 11:50 AM",
      "11:35 AM - 11:55 AM",
      "11:40 AM - 12:00 PM",
      "11:45 AM - 12:05 PM",
      "11:50 AM - 12:10 PM",
      "11:55 AM - 12:15 PM",

      "12:00 PM - 12:20 PM",
      "12:05 PM - 12:25 PM",
      "12:10 PM - 12:30 PM",
      "12:15 PM - 12:35 PM",
      "12:20 PM - 12:40 PM",
      "12:25 PM - 12:45 PM",
      "12:30 PM - 12:50 PM",
      "12:35 PM - 12:55 PM",
      "12:40 PM - 01:00 PM",
      "12:45 PM - 01:05 PM",
      "12:50 PM - 01:10 PM",
      "12:55 PM - 01:15 PM",

      "01:00 PM - 01:20 PM",
      "01:05 PM - 01:25 PM",
      "01:10 PM - 01:30 PM",
      "01:15 PM - 01:35 PM",
      "01:20 PM - 01:40 PM",
      "01:25 PM - 01:45 PM",
      "01:30 PM - 01:50 PM",
      "01:35 PM - 01:55 PM",
      "01:40 PM - 02:00 PM",
      "01:45 PM - 02:05 PM",
      "01:50 PM - 02:10 PM",
      "01:55 PM - 02:15 PM",

      "02:00 PM - 02:20 PM",
      "02:05 PM - 02:25 PM",
      "02:10 PM - 02:30 PM",
      "02:15 PM - 02:35 PM",
      "02:20 PM - 02:40 PM",
      "02:25 PM - 02:45 PM",
      "02:30 PM - 02:50 PM",
      "02:35 PM - 02:55 PM",
      "02:40 PM - 03:00 PM",
      "02:45 PM - 03:05 PM",
      "02:50 PM - 03:10 PM",
      "02:55 PM - 03:15 PM",

      "03:00 PM - 03:20 PM",
      "03:05 PM - 03:25 PM",
      "03:10 PM - 03:30 PM",
      "03:15 PM - 03:35 PM",
      "03:20 PM - 03:40 PM",
      "03:25 PM - 03:45 PM",
      "03:30 PM - 03:50 PM",
      "03:35 PM - 03:55 PM",
      "03:40 PM - 04:00 PM",
      "03:45 PM - 04:05 PM",
      "03:50 PM - 04:10 PM",
      "03:55 PM - 04:15 PM",
      
      "04:00 PM - 04:20 PM",
      "04:05 PM - 04:25 PM",
      "04:10 PM - 04:30 PM",
      "04:15 PM - 04:35 PM",
      "04:20 PM - 04:40 PM",
      "04:25 PM - 04:45 PM",
      "04:30 PM - 04:50 PM",
      "04:35 PM - 04:55 PM",
      "04:40 PM - 05:00 PM"
    ],
    "45": [
      "09:00 AM - 09:45 AM",
      "09:05 AM - 09:50 AM",
      "09:10 AM - 09:55 AM",
      "09:15 AM - 10:00 AM",
      "09:20 AM - 10:05 AM",
      "09:25 AM - 10:10 AM",
      "09:30 AM - 10:15 AM",
      "09:35 AM - 10:20 AM",
      "09:40 AM - 10:25 AM",
      "09:45 AM - 10:30 AM",
      "09:50 AM - 10:35 AM",
      "09:55 AM - 10:40 AM",

      "10:00 AM - 10:45 AM",
      "10:05 AM - 10:50 AM",
      "10:10 AM - 10:55 AM",
      "10:15 AM - 11:00 AM",
      "10:20 AM - 11:05 AM",
      "10:25 AM - 11:10 AM",
      "10:30 AM - 11:15 AM",
      "10:35 AM - 11:20 AM",
      "10:40 AM - 11:25 AM",
      "10:45 AM - 11:30 AM",
      "10:50 AM - 11:35 AM",
      "10:55 AM - 11:40 AM",

      "11:00 AM - 11:45 AM",
      "11:05 AM - 11:50 AM",
      "11:10 AM - 11:55 AM",
      "11:15 AM - 12:00 PM",
      "11:20 AM - 12:05 PM",
      "11:25 AM - 12:10 PM",
      "11:30 AM - 12:15 PM",
      "11:35 AM - 12:20 PM",
      "11:40 AM - 12:25 PM",
      "11:45 AM - 12:30 PM",
      "11:50 AM - 12:35 PM",
      "11:55 AM - 12:40 PM",

      "12:00 PM - 12:45 PM",
      "12:05 PM - 12:50 PM",
      "12:10 PM - 12:55 PM",
      "12:15 PM - 01:00 PM",
      "12:20 PM - 01:05 PM",
      "12:25 PM - 01:10 PM",
      "12:30 PM - 01:15 PM",
      "12:35 PM - 01:20 PM",
      "12:40 PM - 01:25 PM",
      "12:45 PM - 01:30 PM",
      "12:50 PM - 01:35 PM",
      "12:55 PM - 01:40 PM",

      "01:00 PM - 01:45 PM",
      "01:05 PM - 01:50 PM",
      "01:10 PM - 01:55 PM",
      "01:15 PM - 02:00 PM",
      "01:20 PM - 02:05 PM",
      "01:25 PM - 02:10 PM",
      "01:30 PM - 02:15 PM",
      "01:35 PM - 02:20 PM",
      "01:40 PM - 02:25 PM",
      "01:45 PM - 02:30 PM",
      "01:50 PM - 02:35 PM",
      "01:55 PM - 02:40 PM",

      "02:00 PM - 02:45 PM",
      "02:05 PM - 02:50 PM",
      "02:10 PM - 02:55 PM",
      "02:15 PM - 03:00 PM",
      "02:20 PM - 03:05 PM",
      "02:25 PM - 03:10 PM",
      "02:30 PM - 03:15 PM",
      "02:35 PM - 03:20 PM",
      "02:40 PM - 03:25 PM",
      "02:45 PM - 03:30 PM",
      "02:50 PM - 03:35 PM",
      "02:55 PM - 03:40 PM",
      
      "03:00 PM - 03:45 PM",
      "03:05 PM - 03:50 PM",
      "03:10 PM - 03:55 PM",
      "03:15 PM - 04:00 PM",
      "03:20 PM - 04:05 PM",
      "03:25 PM - 04:10 PM",
      "03:30 PM - 04:15 PM",
      "03:35 PM - 04:20 PM",
      "03:40 PM - 04:25 PM",
      "03:45 PM - 04:30 PM",
      "03:50 PM - 04:35 PM",
      "03:55 PM - 04:40 PM",
      
      "04:00 PM - 04:45 PM",
      "04:05 PM - 04:50 PM",
      "04:10 PM - 04:55 PM",
      "04:15 PM - 05:00 PM"
    ]
  };
