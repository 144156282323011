import React from "react";
import ReactLoading from 'react-loading';
import './Loading.css';

const Loading = ({type, color, containerStyle}) => {

    return (
        <div className='app__loading' style={containerStyle}>
            <div className='app__loading-gif'>
                <ReactLoading type={type} color={color} height={80} width={80}/>
            </div>
        </div>
    );
};

export default Loading;