

export default function ModalFooter(props) {
    const {
        containerStyle
    } = props;
    return (
        <div className="modal-footer" style={containerStyle}>
            { props.children }
        </div>
    );
}