import React from 'react';
import {createRoot} from 'react-dom/client'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import HearingSchedulerPage from './components/HearingSchedulerPage';
import HearingsPage from './components/HearingsPage';
import WebexLinkConfirmation from './components/WebexLinkConfirmation';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScheduleHearingConfirmationPage from './components/ScheduleHearingConfirmationPage';
import FacilityHearingsPage from './components/FacilityHearingsPage.jsx';
import NewTimeProposedWindow from './components/NewTimeProposedWindow.jsx';
import RedirectPage from './components/RedirectPage';
import ScheduleHearingConfirmationPageNonAdmin from './components/ScheduleHearingConfirmationPageNonAdmin';
import { CookiesProvider } from "react-cookie";
import AdminPage from './components/AdminPage';

console.log('this is an index level log');

const root = createRoot(
  document.getElementById('root')
);
root.render(
  <CookiesProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="login"
          element={<RedirectPage loc="https://remotehearings.nccourts.org/login"/>}/>
        <Route path="hearing-scheduler" element={<HearingSchedulerPage/>} />
        <Route path="hearings" element={<HearingsPage/>} />
        <Route path="webex-link-confirmation" element={<WebexLinkConfirmation/>} />
        <Route path="hearing-confirmation" element={<ScheduleHearingConfirmationPage/>} />
        <Route path="confirm-hearing" element={<ScheduleHearingConfirmationPageNonAdmin/>} />
        <Route path='facility-hearings' element={<FacilityHearingsPage/>} />
        <Route path='new-time-proposed-window' element={<NewTimeProposedWindow/>} />      
        <Route path='2wgm38eFwp' element={<AdminPage/>} />
      </Routes>
    </BrowserRouter>
  </CookiesProvider>
);

