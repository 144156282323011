import React from "react";
import './ProposeNewTimeModal.css';
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModaBody from './ModalBody';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphicStars from '../assets/icons/graphic-stars.svg';
import ModalFooter from "./ModalFooter";
import Button from "./Button";
import DropdownSelect from "./DropdownSelect";
import NewTimeConfirmationModal from "./NewTimeConfirmationModal.jsx";
import ModalService from "../services/ModalService";
import DatePickerInput from "./DatePickerInput";
import { durations, mutableTimeArray, defaultTimeArray } from "./DefaultData";
import TimeWheel from "./TimeWheel";
import http from '../http-common';
import Loading from "./Loading";
import UtilService from '../services/Utils';


export default function ProposeNewTimeModal(props) {
    const {
        style, 
        containerStyle,
        meetingId,
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [isDurationSelected, setIsDurationSelected] = React.useState(false);
    const [isDateSelected, setIsDateSelected] = React.useState(false);
    const [meeting, setMeeting] = React.useState({});

    const getMeetingByMeetingId = async (meetingId) => {
      setIsLoading(true);
      if (!meetingId) {
          console.log('No meeting ID.');
      } else {
          try {
              let response = await http.get('/get-meeting-by-id', {params: {meetingId: meetingId}});
              console.log(response.data);
              if (response.data[0]) {
                setMeeting(response.data[0]);
              } else {
                  setMeeting({});
              };
          } catch (err) {
              console.log(err);
          };
      };
      setIsLoading(false);
    };

    const [availability, setAvailability] = React.useState({
        dateRange: [],
        duration: [],
        startDate: "",
        startTime: "",
        endTime: "",
        startTimeString: ""
      });
  
    const [timeWheelArray, setTimeWheelArray] = React.useState({});

    const findAvailability = async () => {
      setIsLoading(true);
      if (meeting) {
        setTimeWheelDuration(availability.duration);
        setTimeWheelDateRange(availability.dateRange);
        if (isDurationSelected && isDateSelected) {
          // do API call to determine what times are available here:
          let result = await getMeetingAvailability(availability.duration.split(' ')[0]);
          setTimeWheelArray(result);
          setTimeWheelShow(true);
        }
        else {
          alert('Please select a date range and duration.');
        };
      } else {
        alert('Please select a courtroom device before finding availability.');
      };
      setIsLoading(false);
    };

    const getMeetingAvailability = async (durationString) => {
      let tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
      let meetingAvailability = {};
      let busyTimes = [];
      let params = {
        courtroomDevice: meeting.courtroomDevice
      };
      try {
        let response = await http.get('/get-meeting-availability', {params: params});
        busyTimes = response.data;
        let busyKeys = Object.keys(busyTimes);
        console.log("busyTimes = ",busyTimes)
        console.log("busyKeys = ",busyKeys)
        availability.dateRange.forEach((key) => {
              var dateKeyString = new Date(key).toDateString()
              if(busyKeys.indexOf(dateKeyString) === -1){
                tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
                tempObject[durationString] = tempObject[durationString].filter((time) => 
                UtilService.hasTimeOccured(key,time.split('-')[0].trim()));
                meetingAvailability[dateKeyString] = tempObject;
              }
              else{
                tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
                busyTimes[dateKeyString].map((meeting) => {
                        tempObject[durationString] = (tempObject[durationString].filter((time) => 
                        UtilService.hasTimeOccured(key,time.split('-')[0].trim()) && 
                        !(time.split('-')[0].trim() >= meeting.startTime &&
                        time.split('-')[0].trim() < meeting.endTime ||
                        time.split('-')[1].trim() <= meeting.endTime &&
                        time.split('-')[1].trim() > meeting.startTime)));
                });
                meetingAvailability[dateKeyString] = tempObject;
              }
        });
        return meetingAvailability;
      } catch (err) {
        console.log(err);
      };
    };

    // helper functions for availability state
    const setDateRange = (selectedDateRange) => {
      setAvailability(current => ({
        ...current, dateRange: selectedDateRange
      }));
      if (selectedDateRange.includes(undefined)){
          setIsDateSelected(false);
      }
      else {
          setIsDateSelected(true);
      }
    };

    const setStartDate = (selectedStartDate) => {
      setAvailability(current => ({
        ...current, startDate: selectedStartDate
      }));
    };

    const setStartTime = (selectedStartTime) => {
      setAvailability(current => ({
        ...current, startTime: selectedStartTime
      }));
    };

    const setEndTime = (selectedEndTime) => {
      setAvailability(current => ({
        ...current, endTime: selectedEndTime
      }));
    };    

    const setStartTimeString = (selectedStartTimeString) => {
      setAvailability(current => ({
        ...current, startTimeString: selectedStartTimeString
      }));
    };    

    const setDuration = (selectedDuration) => {
      setAvailability(current => ({
        ...current, duration: selectedDuration
      }));
      setIsDurationSelected(true);
    };

    const [timeWheelParams, setTimeWheelParams] = React.useState({
      duration: "", 
      dateRange: [],
      show: false
    });

    // helper functions for timewheel params state
    const setTimeWheelDuration = (newDuration) => {
      setTimeWheelParams(current => ({
        ...current, duration: newDuration
      }));
    };

    const setTimeWheelDateRange = (newDateRange) => {
      setTimeWheelParams(current => ({
        ...current, dateRange: newDateRange
      }));
    };    

    const setTimeWheelShow = (bool) => {
      setTimeWheelParams(current => ({
        ...current, show: bool
      }));
    };

    const openNewTimeConfirmationModal = () => {
        ModalService.open(NewTimeConfirmationModal, {meeting: meeting, availability: availability});
    };

    React.useEffect(() => {
      if (meetingId) {
        let response = getMeetingByMeetingId(meetingId);
      }
    }, []);

    return (
        <Modal containerStyle={{ heigh: '100vh', width: '515px', marginTop: '-2em', marginBottom: '-2em', alignItems: 'center', boxShadow: '0px 3px 6px #00000029', 
            opacity: '1', borderRadius: '0px', paddingBottom: '3em'}}
            style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
            <Loading type={'spinningBubbles'} color={'#003366'} 
              containerStyle={{display: isLoading ? "flex" : "none"}}/>
            <ModalHeader containerStyle={{display: 'flex', flexDirection: 'column', width: '372px', height: '348px', alignItems: 'center'}} onClick={props.close}>
                <div className="app__proposenewtimemodal-header">
                    <div className="app__proposenewtimemodal-header-logo">
                        <img src={SealLogo} alt="seal logo" />
                    </div>
                    <div className="app__proposenewtimemodal-tagline">
                        <img src={LTagline} alt='tagline'/>
                    </div>
                    <div className="app__proposenewtimemodal-graphicstars">
                        <img src={GraphicStars} alt="Graphin stars" />
                    </div>
                    <div className="app__proposenewtimemodal-generic-inputbox">
                        <div>PROPOSE NEW TIME</div>
                    </div>
                </div>
            </ModalHeader>
            <ModaBody containerStyle={{width: "350px", height: '100vh', flex: '0'}}>
                <div className="app__proposenewtimemodal-body-container">
                {meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' &&
                    <div className="app__proposenewtimemodal-casenumber-container">
                        <div className="app__proposenewtimemodal-casenumber-font">
                            <div style={{fontWeight: 'bold', marginRight: '4px'}}>Case Number: </div>
                        </div>
                        <div className="app__proposenewtimemodal-casenumber-number" style={{display: 'flex'}}>
                            <div>{meeting.caseNumbers ? meeting.caseNumbers.map((caseNumber, index) =>
                                    <div key={`${index}-${caseNumber}`}>{caseNumber}</div>
                                ) : "No case numbers found." }
                            </div>
                        </div>
                    </div>}
                    <div className="app__proposenewtimemodal-dateandtime">
                        <p>{meeting.startTime ? new Date(meeting.startTime).toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) + ' at ' + 
                                    new Date(meeting.startTime).toLocaleTimeString() : "No meeting start time found."}</p>
                    </div>
                    <div className="app__proposenewtimemodal-newtime-details-container">
                        <DatePickerInput placeholder={"Select Date Range"}
                            containerStyle={{width: '230px', height: '4em'}}
                            style={{height: '100%'}}
                            type="range"
                            setDateState={setDateRange}/>
                        <DropdownSelect title={"Duration*"} placeholder={"Select Duration"}
                            id={"duration"}
                            containerStyle={{paddingTop: '1.5em'}}
                            style={{width: '230px'}}
                            dropdownOptions={durations}
                            setState={setDuration}/>
                        <Button text={"LOOK UP AVAILABILITY"} 
                            containerStyle={{paddingTop: '1em'}}
                            style={{fontWeight: 'bold', width: '200px', backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                            onClick={findAvailability}/>
                        <div className='app__proposenewtimemodal-container-section-content' style={{display: (timeWheelParams.show) ? "flex" : "none", paddingTop: '1em'}}>
                            <div className='app__proposenewtimemodal-container-section-content-timewheel' style={{maxHeight: '300px', overflow: 'auto', border: '1px solid lightgray'}}>
                                <TimeWheel
                                    selectedDuration={timeWheelParams.duration}
                                    durationArray={defaultTimeArray[parseInt(timeWheelParams.duration).toString()]}
                                    datesObject={timeWheelArray}
                                    selectedStartDate={timeWheelParams.dateRange[0]}
                                    selectedEndDate={timeWheelParams.dateRange[1]}
                                    setState={[setStartDate, setStartTime, setEndTime, setStartTimeString]}/>
                            </div>
                        </div>
                        {/* <div className="app__proposenewtimemodal-comments" style={{paddingTop: '0.5em'}}>
                            <TextArea title={"Comments"} placeholder={"Add any comments."}
                                containerStyle={{width: 'auto', minWidth: '60%', marginBottom: '1em'}}
                                style={{width: '100%', minWidth: '318px', minHeight: '10em'}}/>
                        </div> */}
                    </div>
                </div>
            </ModaBody>
            <ModalFooter containerStyle={{borderTop: '0px', paddingTop: '1em'}}>
                <div className="app__proposenewtimemodal-footer-container">
                    <div className="app__proposenewtimemodal-footer-content">
                        <Button text={"REQUEST NEW TIME"}
                            style={{fontWeight: 'bold', height: '4em', width: '318px', 
                            font: 'normal normal bold 14px/19px Open Sans', letterSpacing: '0px', marginTop: '0em', 
                            color: '#FFFFFF', opacity: '1', backgroundColor: '#3576a5', border: '1px solid #3576a5'}}
                            originalColor='#3576a5' onHoverColor='#206392' onClick={ openNewTimeConfirmationModal }/>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}
