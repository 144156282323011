import moment from 'moment-timezone';

const UtilService =  {
    
    hasDateOccured(date){
        var inputDateTime = new Date(date)
        var currentDateTime = new Date()
        return currentDateTime < inputDateTime
    },

    hasTimeOccured(date,time){
        moment.tz.setDefault("America/New_York");
        var inputDateTimeString = new moment(date).format('YYYY-MM-DD')+' '+time
        var inputMoment = new moment(inputDateTimeString, 'YYYY-MM-DD h:m A')
        var currentMoment = new moment().add(moment.duration(1, 'hours'));
        return currentMoment.isBefore(inputMoment)
    },

    displayDate(date){
        var inputDate = new Date(date)
        var dayStr = inputDate.toDateString()
        var timeStr = inputDate.toLocaleTimeString()
        var timezoneStr = inputDate.toTimeString().match(/\((.+)\)/)[1]
        return `${dayStr} ${timeStr} (${timezoneStr})`
    }

}

export default UtilService;