import React from "react";
import './NewTimeConfirmationModal.css';
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModaBody from './ModalBody';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphiStars from '../assets/icons/graphic-stars.svg';
import ModalFooter from "./ModalFooter";
import ClickableText from './ClickableText';
import Button from "./Button";
import http from '../http-common';
import Loading from "./Loading";


export default function NewTimeConfirmationModal(props) {
    const {
        style, 
        containerStyle,
        meeting,
        availability,
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [offender, setOffender] = React.useState([]);
    const [facility, setFacility] = React.useState([]);

    const requestNewTime = async () => {
        setIsLoading(true);
        let webexParams = {
            title: meeting.title,
            startTime: availability.startTime,
            endTime: availability.endTime,
            password: meeting.password,
            meetingId: meeting.meetingId,
            _id: meeting._id
        };
        console.log('these are the webex params for reschedule meeting', webexParams);
        try {
            await http.post('/cancel-hearing', webexParams).then(response => {
                console.log('reschedule meeting response', response);
                if(response.data.error) {
                    console.log(response.data.error);
                    alert(response.data.error.message + 'Meeting was not able to reschedule.');
                }
                else {
                    console.log(response.data);
                    console.log("Meeting Data inside new time confirmation ",meeting)
                    let mongoParams = {
                        _id: meeting._id,
                        meetingId: meeting.meetingId,
                        meetingNumber: meeting.meetingNumber,
                        startTime: availability.startTime,
                        caseNumbers: meeting.caseNumbers,
                        eventType: meeting.eventType,
                        description: meeting.description,
                        courtroom: meeting.courtroom,
                        courtroomDevice: meeting.courtroomDevice,
                        facilityName: meeting.facilityName,
                        hearingStatus: "Pending - Rescheduled",
                        result: "",
                        weblink: response.data.weblink,
                        password: response.data.password,
                        title: meeting.title,
                        endTime: availability.endTime,
                        offenderDisplayName: meeting.offenderDisplayName,
                        districtAttorneyName: meeting.districtAttorneyName
                    };
                    console.log('reschedule hearing mongo params', mongoParams);
                    http.put('/update-meeting', mongoParams).then(response => {
                        if (response.data.error) {
                            console.log(response.data.error);
                            alert(response.data.error.message + '\nMeeting was not able to be saved to the database.');
                        }
                        else {
                            console.log('reschedule hearing update meeting response ', response);
                        };
                    });
                    let emailParams = {
                        facilityName: meeting.facilityName,
                        meetingId: meeting.meetingId,
                        eventType: meeting.eventType,
                        courtroom: meeting.courtroom,
                        courtroomDevice: meeting.courtroomDevice,
                        startTime: availability.startTime,
                        endTime: availability.endTime,
                        offenderDisplayName: "",
                        facilityOwnerEmail: "",
                        participant:"",
                        reschedule:1
                    };
                    if(meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings')
                    {
                        if(offender){
                            emailParams.offenderDisplayName = offender.firstName + ' ' + offender.lastName;
                        }
                        else{
                            console.log("Offender details not found")
                        }
                    }
                    if(facility){
                        emailParams.facilityOwnerEmail = facility.ownerEmail;
                        http.post('/send-email-notification', emailParams).then(response => {
                            if (response.data.error) {
                                console.log(response.data.error);
                                alert(response.data.error.message + '\n Reschedule email notification was unable to be sent.');
                            }
                            else {
                                console.log(response);
                            };
                        });
                    }
                    else{
                        setIsLoading(false);
                        alert('Error Ocurred: Facility details not found')
                        window.location = window.location.href.split("?")[0];
                    }
                    setIsLoading(false);
                    alert('Webex meeting: "' + response.data.title + '" was successfully rescheduled!')
                    window.location = window.location.href.split("?")[0];
                };
            }).catch(e => {
                console.log('reschedule meeting catch outside', e);
                alert(e);
            });
        } catch (err) {
            alert(err);
            console.log('reschedule meeting catch error', err);
        };
        setIsLoading(false);
    };

    const getMeetingDetails = async (meetingId) => {
        setIsLoading(true);
        try {
            await http.get('/get-meeting-offender', {params: {meetingId: meetingId}}).then(response => {
                console.log("This is offender data",response.data);
                if(response.data.length > 0){
                    setOffender(response.data[0])
                }
            }).catch(e => {
                console.log(e);
            });
            await http.post('/get-facility-by-name', {facilityName: meeting.facilityName}).then(response => {
                console.log("This is facility data",response.data);
                if(response.data.length > 0){
                    setFacility(response.data[0])
                }
            }).catch(e => {
                console.log(e);
            });

        } catch (err) {
            console.log(err);
        };
        setIsLoading(false);
    };

    React.useEffect(() => {
        console.log('NewTimeConfirmationModal useEffect render on initial load');
        getMeetingDetails(meeting.meetingId);
      }, []);

    return (
        <Modal containerStyle={{height: '100%', width: '100%', marginTop: '5em', alignItems: 'center', boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
            style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
            <Loading type={'spinningBubbles'} color={'#003366'} 
                containerStyle={{display: isLoading ? "flex" : "none"}}/>
            <ModalHeader containerStyle={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center'}} onClick={props.close}>
                <div className="app__newtimeconfirmationmodal-header">
                    <div className="app__newtimeconfirmationmodal-header-logo">
                        <img src={SealLogo} alt="seal logo" />
                    </div>
                    <div className="app__newtimeconfirmationmodal-tagline">
                        <img src={LTagline} alt='tagline'/>
                    </div>
                    <div className="app__newtimeconfirmationmodal-graphicstars">
                        <img src={GraphiStars} alt="Graphin stars" />
                    </div>
                    <div className="app__newtimeconfirmationmodal-generic-inputbox" style={containerStyle}>
                        <div style={style}>ARE YOU SURE YOU WANT TO REQUEST A NEW TIME?</div>
                    </div>
                </div>
            </ModalHeader>
            <ModaBody containerStyle={{width: "100%", height: '100%', flex: '0'}}>
                <div className="app__newtimeconfirmationmodal-body-container">
                {meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' &&
                    <div className="app__newtimeconfirmationmodal-casenumber-container">
                        <div className="app__newtimeconfirmationmodal-casenumber-font">
                            <div style={{marginRight: '4px', fontWeight: 'bold', color: '#202020'}}>Case Number: </div>
                        </div>
                        <div className="app__newtimeconfirmationmodal-casenumber-number" style={{display: 'flex'}}>
                            <div>{meeting.caseNumbers ? meeting.caseNumbers.map((caseNumber, index) =>
                                    <div key={`${index}-${caseNumber}`}>{caseNumber}</div>
                                ) : "No case numbers found." }
                            </div>
                        </div>
                    </div>}
                    <div className="app__newtimeconfirmationmodal-dateandtime">
                        <p>{availability.startTime ? new Date(availability.startTime).toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) + ' at ' + 
                            new Date(availability.startTime).toLocaleTimeString() + ' for ' + availability.duration : "No meeting start time found."}</p>
                    </div>
                </div>
            </ModaBody>
            <ModalFooter containerStyle={{marginTop: '1em', marginBottom: '2em', height: '100%', borderTop: '0'}}>
            {/* <ModalFooter containerStyle={{width: '350px', height: '100px', alignItems: 'center'}}> */}
                <div className="app__newtimeconfirmationmodal-footer-container">
                    <div className="app__newtimeconfirmationmodal-footer-content">
                        <Button text={"YES, REQUEST A NEW TIME"}
                            // containerStyle={{marginLeft: '2em', marginBottom: '1em'}}
                            style={{fontWeight: 'bold', height: '4em', width: '318px', 
                            font: 'normal normal bold 14px/19px Open Sans', letterSpacing: '0px', color: '#FFFFFF',
                             opacity: '1', backgroundColor: '#3576a5', border: '1px solid #3576a5'}}
                             originalColor='#3576a5' onHoverColor='#206392' onClick={ requestNewTime }/>
                        <ClickableText text={'CANCEL'} style={{marginTop: '1em', font: 'normal normal 600 14px/19px Open Sans',
                        letterSpacing: '1.25px', color: '#4392CD', opacity: '1'}} onClick = { props.close }/>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}
