import React from 'react';
import './HearingsPage.css';
import Sidebar from './Sidebar';
import Button from './Button';
import ToggleSwitch from './ToggleSwitch';
import ClickableText from './ClickableText';
import Table from'./Table';
import CurrentlyViewingIcon from '../assets/icons/currently-viewing-icon.svg';
import CancelHearingModal from './CancelHearingModal.jsx';
import ModalService from '../services/ModalService.js';
import ModalRoot from './ModalRoot';
import EditHearingModal from './EditHearingModal.jsx';
import RescheduleHearingModal from './RescheduleHearingModal.jsx';
import Navbar from './Navbar';
import http from '../http-common';
import HearingDetails from './HearingDetails';
import Loading from './Loading';
import { useCookies } from "react-cookie";
import LoginPage from './LoginPage';
import AddCommentIcon from '@mui/icons-material/AddComment';
import FeedbackModal from './FeedbackModal.jsx';
import Fab from '@mui/material/Fab';
import UtilService from '../services/Utils';

const fabStyle = {
    position: 'fixed',
    bottom: 16,
    right: 16,
    mr: 1
  };

const HearingsPage = ({initialCourtroom, initialDate}) => {
    const [userCookie, setUserCookie] = useCookies();
    const [county, setCounty] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDisplay, setIsDisplay] = React.useState(false);
    const [courtroom, setCourtroom] = React.useState(initialCourtroom);
    const [date, setDate] = React.useState(initialDate);
    const [meetings, setMeetings] = React.useState([]);
    const [upcomingMeetings, setUpcomingMeetings] = React.useState([]);
    const [pastMeetings, setPastMeetings] = React.useState([]);
    const [isChecked, setIsChecked] = React.useState(false);
    const [checkedMeeting, setCheckedMeeting] = React.useState({});
    
    // const openFeedbackModal = () => {
    //     if(userCookie.userProfile){
    //       const _profile = userCookie.userProfile;
    //       ModalService.open(FeedbackModal, {userProfile: _profile});
    //       console.log('open feedback modal clicked - if')
    //     }
    //     else {
    //       console.log('open feedback modal clicked - else');
  
    //     }
    //   };

    const getMeetings = async () => {
        setIsLoading(true);
        let meetingArray = [];
        let upcomingArray = [];
        let pastArray = [];
        let countyName = "";
        let body = {
                  email: userCookie.userProfile.emails[0]
                };
        try {
            await http.get('/get-meetings').then(async response => {
                console.log("Meetings List: ",response.data);
                meetingArray = response.data;
                setMeetings(meetingArray);
                //Get county name to which the currently logged in user belongs to
                await http.post('/get-county-by-clerk-email', body).then(response => {
                    countyName = response.data.displayName
                    setCounty(response.data);
                    //If countyName is available then filter meetings based on courtroom = countyName
                    if(countyName){
                        upcomingArray = meetingArray.filter((meeting) =>  UtilService.hasDateOccured(meeting.startTime));
                        if(upcomingArray.length > 1){
                            upcomingArray.sort(
                                (objA, objB) => new Date(objA.startTime) - new Date(objB.startTime),
                            );
                        }
                        setUpcomingMeetings(upcomingArray);
                        pastArray = meetingArray.filter((meeting) => !UtilService.hasDateOccured(meeting.startTime));
                        if(pastArray.length > 1){
                            pastArray.sort(
                                (objA, objB) => new Date(objB.startTime) - new Date(objA.startTime),
                            );
                        }
                        setPastMeetings(pastArray);
                    }
                    else {
                        /*
                        If countyName is not available then show all the meetings
                        This scenario will come into picture if the currently logged in user's email
                        is not found in the individuals table with role clerk
                        */
                        upcomingArray = meetingArray.filter((meeting) => UtilService.hasDateOccured(meeting.startTime));
                        if(upcomingArray.length > 1){
                            upcomingArray.sort(
                                (objA, objB) => new Date(objA.startTime) - new Date(objB.startTime),
                            );
                        }
                        setUpcomingMeetings(upcomingArray);
                        pastArray = meetingArray.filter((meeting) => !UtilService.hasDateOccured(meeting.startTime));
                        if(pastArray.length > 1){
                            pastArray.sort(
                                (objA, objB) => new Date(objB.startTime) - new Date(objA.startTime),
                            );
                        }
                        setPastMeetings(pastArray);
                    }
                  }).catch(e => {
                    console.log(e);
                });
            }).catch(e => {
                console.log(e);
            });
        } catch (err) {
            console.log(err);
        };
        setIsLoading(false);
    };  

    const openCancelHearingModal = () => {
        if (isChecked) {
            ModalService.open(CancelHearingModal, {meetingId: checkedMeeting.meetingId});
        } else {
            alert('Please check a hearing first.')
        }
    };

    const openEditHearingModal = () => {
        if (isChecked) {
            ModalService.open(EditHearingModal, {meetingId: checkedMeeting.meetingId});
        } else {
            alert('Please check a hearing first.')
        }
    };
    
    const openRescheduleHearingModal = () => {
        if (isChecked) {
            ModalService.open(RescheduleHearingModal, {meetingId: checkedMeeting.meetingId});
        } else {
            alert('Please check a hearing first.')
        }
    };

    const openHearingDetailsModal = () => {
        if (isChecked) {
            if (!isDisplay) {
                ModalService.open(HearingDetails, {hearingDetailsType: 'upcoming', meetingId: checkedMeeting.meetingId});
            } else {
                ModalService.open(HearingDetails, {hearingDetailsType: 'past', meetingId: checkedMeeting.meetingId});
            };
        } else {
            alert('Please check a hearing first.')
            console.log(isChecked);
        }
    };

    React.useEffect(() => {
        if (!userCookie.userProfile) {
            return;
        } else {
            console.log('Hearings page useEffect render on initial load');
            getMeetings();
        };
    }, []);

    if (userCookie.userProfile && userCookie.userProfile.emails[0].split('@')[1] === 'nccourts.org') {
        return (
            <div className='app__hearingspage'>
                <div id='loading' style={{display: isLoading ? "initial" : "none"}}>
                    <Loading type={'spinningBubbles'} color={'#003366'}/>
                </div>
                <div className='app__hearingspage-navbar'>
                    <Navbar/>
                </div>
                <ModalRoot/>
                <div className='app__hearingspage-pagecontent'>
                    <div className='app__hearingschedulermain-sidebar'>
                        <Sidebar selectedMeeting={checkedMeeting}/>
                    </div>
                    <div className='app__hearingspage-main'>
                        <div className='app__hearingspage-header'>
                            <div className='app__hearingspage-header-row'>
                                <div className='app__hearingspage-header-row-title' style={{display: !isDisplay ? "flex" : "none"}}>
                                    Upcoming Hearings
                                </div>
                                <div className='app__hearingspage-header-row-title' style={{display: !isDisplay ? "none" : "flex"}}>
                                    Past Hearings
                                </div>
                                <div className='app__hearingspage-header-row-button'>
                                    <Button text={'SCHEDULE HEARING'}
                                        containerStyle={{marginRight: '0', marginBottom: '0'}}
                                        style={{height: '4em', width: '100%', backgroundColor: '#3576a5', 
                                            border: '1px solid #3576a5', color: 'white', fontWeight: 'bold'}}
                                        route={'/hearing-scheduler'}/>
                                </div>                      
                            </div>
                            <div className='app__hearingspage-header-title-description'>
                                <img src={CurrentlyViewingIcon} alt={"Currently Viewing Icon"} style={{paddingRight: "0.25em"}}/> 
                                <div className='app__hearingspage-header-title-description-text'>
                                    <b>Currently Viewing</b> Docket
                                </div>
                            </div>  
                        </div>
                        <div className='app__hearingspage-settings'>
                            <div className='app__hearingspage-settings-left'>
                                {/* <DropdownSelect
                                    title={"Courtroom"}
                                    placeholder={"Select Courtroom"}
                                    containerStyle={{marginRight: '1em'}}
                                    style={{width: '200px', height: '4em'}}
                                    setState={setCourtroom}/>
                                <DatePickerInput placeholder={"Select Date Range"}
                                    containerStyle={{width: '200px', height: '4em', marginRight: '1em'}}
                                    style={{height: '100%'}}
                                    type={'range'}
                                    setDateState={setDate}/> */}
                                <div className='app__hearingspage-settings-left-toggle'>
                                    <ToggleSwitch 
                                        title={"hearings-toggle"} id={"hearings-toggle"}
                                        containerStyle={{height: 'auto', marginRight: '.5em'}}
                                        style={{}}
                                        initialChecked={false}
                                        isDisplay={isDisplay}
                                        setIsDisplay={setIsDisplay}
                                        setCheckedState={setIsChecked}
                                        />
                                    <div style={{display: !isDisplay ? "flex" : "none", alignItems: 'center', justifyContent: 'center', 
                                        fontWeight: '500',}}>
                                        Show Past Hearings
                                    </div>
                                    <div style={{display: !isDisplay ? "none" : "flex", alignItems: 'center', justifyContent: 'center', 
                                        fontWeight: '500',}}>
                                        Show Upcoming Hearings
                                    </div>
                                </div>
                            </div>
                            <div className='app__hearingspage-settings-right'>
                                <div className="app__hearingspage-settings-right-view">
                                    <ClickableText text={'VIEW HEARING PARTICIPANTS'} 
                                        onClick={openHearingDetailsModal}
                                        // onClick={() => console.log('wow amazing')}
                                        containerStyle={{marginRight: '1em'}}
                                        style={{}}
                                        image={"carrot-down"} />
                                </div>
                                {checkedMeeting.hearingStatus !== 'Cancelled' &&
                                <div className="app__hearingspage-settings-right-cancel" onClick={openCancelHearingModal} style={{display: !isDisplay ? "" : "none"}}>
                                    <ClickableText text={'CANCEL'}
                                        containerStyle={{marginRight: '1em'}}
                                        style={{}}
                                        image={"cancel"} />
                                </div>}
                                {checkedMeeting.hearingStatus !== 'Cancelled' &&
                                <div className="app__hearingspage-settings-right-edit" onClick={openEditHearingModal} style={{display: !isDisplay ? "" : "none"}}>
                                    <ClickableText text={'EDIT'}
                                        containerStyle={{marginRight: '1em'}}
                                        style={{}}
                                        image={"edit"} />
                                </div>}
                                {checkedMeeting.hearingStatus !== 'Cancelled' &&
                                <div className="app__hearingspage-settings-right-reschedule" onClick={() => openRescheduleHearingModal({setDateState: setDate})}
                                    style={{display: !isDisplay ? "" : "none"}}>
                                    <ClickableText text={'RESCHEDULE / PROPOSE NEW TIME'}
                                        containerStyle={{}}
                                        style={{}}
                                        image={"reschedule"} />
                                </div>}
                            </div>
                        </div>
                        <div className='app__hearingspage-table'>
                            <div className='app__hearingspage-table-upcoming' style={{display: !isDisplay ? "" : "none"}}>
                                <Table tableType={'upcominghearings'} data={upcomingMeetings} setState={[setIsChecked, setCheckedMeeting]}/>
                            </div>
                            <div className='app__hearingspage-table-past' style={{display: !isDisplay ? "none" : ""}}>
                                <Table tableType={'pasthearings'} data={pastMeetings} setState={[setIsChecked, setCheckedMeeting]}/>
                            </div>
                            {/* <div>
                            <Fab variant="extended" sx={fabStyle} color="primary" aria-label="add" onClick={() => openFeedbackModal()}>
                                <AddCommentIcon/> &nbsp;Feedback
                            </Fab>
                            </div>              */}
                        </div>
                    </div>
                </div>
            </div>                
        );
    } else {
        return (
            <LoginPage loginRequest={true}/>
          );
    };
};

HearingsPage.defaultProps = {
    initialCourtroom: "Default Courtroom",
    initialDate: "Default Date"
}

export default HearingsPage;
