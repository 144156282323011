import _ from "lodash";
import React from "react";
import './Modal.css'

export default function Modal (props) {
    const {
        containerStyle, 
        style
    } = props;

    return (
        <div className="modal d-block" id="modal-container">
            <div className="modal-dialog" style={style}>
                <div className="modal-content" style={containerStyle}>
                   { props.children } 
                </div>
            </div>
        </div>
    );
}
