import React from "react";
import './LoginPage.css';
import Button from './Button';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphicStars from '../assets/icons/graphic-stars.svg';
import WebexLogo from '../assets/icons/webex-logo.svg';
import BackgroundV2 from '../assets/images/background-v2.jpg';


const LoginPage = ({style, loginRequest, requestRoute}) => {
    let backgroundImage = BackgroundV2;

    if (!loginRequest) {
        return (
            <div className='app__loginpage' style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover'}}>
                <div className='app__loginpage-container' style={style}>
                    <div className='app__loginpage-logo'>
                        <img src={SealLogo}/>
                    </div>
                    <div className='app__loginpage-ltagline'>
                        <img src={LTagline}/>
                    </div>
                    <div className='app__loginpage-graphicstars'>
                        <img src={GraphicStars}/>
                    </div>
                    <div className='app__loginpage-title'>
                        VIRTUAL HEARING PLATFORM
                    </div>
                    <div className='app__loginpage-buttons'>
                        <Button text={'LOGIN WITH WEBEX'}
                            containerStyle={{}}
                            style={{height: '4em', width: '240px', fontWeight: 'bold', 
                            backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                            route={'login'}/>
                    </div>
                    <div className='app__loginpage-webexlogo'>
                        <img src={WebexLogo}/>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='app__loginpage' style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover'}}>
                <div className='app__loginpage-container' style={style}>
                    <div className='app__loginpage-logo'>
                        <img src={SealLogo}/>
                    </div>
                    <div className='app__loginpage-ltagline'>
                        <img src={LTagline}/>
                    </div>
                    <div className='app__loginpage-graphicstars'>
                        <img src={GraphicStars}/>
                    </div>
                    <div className='app__loginpage-loginrequest'>
                        Please login to view this page.
                    </div>
                    <div className='app__loginpage-buttons'>
                        <Button text={'LOGIN WITH WEBEX'}
                            containerStyle={{}}
                            style={{height: '4em', width: '240px', fontWeight: 'bold', 
                            backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                            route={'/login'}/>
                    </div>
                    <div className='app__loginpage-webexlogo'>
                        <img src={WebexLogo}/>
                    </div>
                </div>
            </div>
        );
    };
}

export default LoginPage;