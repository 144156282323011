import React, {getTime, getDate, toString} from 'react';
import Button from './Button';
import './TimeWheel.css';


const TimeWheel = ({durationArray, datesObject, selectedStartDate, selectedEndDate, selectedDuration, setState}) => {
    const getDates = (d1_string, d2_string) => {
        let d1 = new Date(d1_string);
        let d2 = new Date(d2_string);

        let date = new Date(d1.getTime());
        let dates = [];
        
        while (date <= d2) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        };
        let dates_string = dates.map(date => date.toDateString('en-US'));
        return dates_string;
    };

    const dateArray = getDates(selectedStartDate, selectedEndDate);

    // const timeArray = getTimes(selectedStartDate);
    const defaultArray = durationArray;
    const times = datesObject;
    // console.log(durationArray);
    // console.log(datesObject);
    // console.log(dateArray);
    // console.log(selectedDuration.split(' ')[0]);
    // dateArray.map(date => {
    //     console.log(date);
    //     console.log(times);
    //     console.log(date in times);
    //     console.log(times[date.toString()]);
    // });
    
    if (selectedStartDate === null || selectedEndDate === null) {
        return (
            <div style={{display: 'none'}}></div>
        )
    }
    else {
        return (
            <div className='app__timewheel'>
                <div className='app__timewheel-content'>
                    <div className='btn-group'>
                        {dateArray && Array.from(dateArray).map((date, index) => 
                            <div className='app__timewheel-wheel' key={`${index}-${date}`}>
                                <div className='app__timewheel-wheel-date' key={`${index}-${date}`}>{date}</div>
                                <div className='app__timewheel-wheel-selection'>
                                    { date in times ? 
                                        times[date.toString()][selectedDuration.split(' ')[0].toString()] && Array.from(times[date.toString()][selectedDuration.split(' ')[0].toString()]).map((time, index) => 
                                            <Button key={`${index}-${time}`}
                                                buttonType={'duration'} 
                                                text={time}
                                                onClick={setState}
                                                dateValue={date}
                                            />
                                    )   
                                    : 
                                        defaultArray && Array.from(defaultArray).map((time, index) => 
                                            <Button key={`${index}-${time}`}
                                                buttonType={'duration'} 
                                                text={time}
                                                onClick={setState}
                                                dateValue={date}
                                            /> 
                                        ) 
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );  
    }
}

export default TimeWheel;