import React from "react";
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import HomeIcon from '../assets/icons/home-icon.png';
import { useCookies } from "react-cookie";
import http from '../http-common';


const Navbar = () => {
    let navigate = useNavigate();
    const [userCookie, setUserCookie] = useCookies();
    const [displayName, setDisplayName] = React.useState('Guest');
    const [initials, setInitials] = React.useState('G');
    const [avatar, setAvatar] = React.useState('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbq0OfAFf9k4X3w6WKlI4WGmbSEFrJXtE-ARC-grO6jO9qP8PrAEYMJIbeR9hXhMFapJ0Wp7SeW6w&usqp=CAU&ec=48600112');

    const logout = async () => {
        try { 
            console.log('logging out');
            let response = await http.get("/logout", {withCredentials: 'true'});
            console.log(response);
            if (response.status === 200) {
                navigate('/');
            };
        } catch (err) {
            console.log(err);
        };
    };

    React.useEffect(() => {
        if( userCookie && userCookie.userProfile && userCookie.userProfile.emails[0].split('@')[1] === 'nccourts.org'){
            setDisplayName(userCookie.userProfile.displayName)
            setInitials(userCookie.userProfile.displayName.split(" ")[0][0] + userCookie.userProfile.displayName.split(" ")[1][0])
            setAvatar(userCookie.userProfile.avatar)
        }
  }, []);

    if (avatar) {
        return (
            <div className="app__navbar">
                <div className="app__navbar-homeicon"><img onClick={() => { navigate('/hearing-scheduler') }} src={HomeIcon}/></div>
                <div className="app__navbar-option" 
                    onClick={() => { navigate('/hearing-scheduler')}}
                    style={{marginLeft: '40px', borderRight: '0'}}>
                    Schedule Hearing
                </div>
                <div className="app__navbar-option" onClick={() => { navigate('/hearings')}}>
                    Hearings Docket
                </div>
                <div className="app__navbar-profileicon">
                    <div className="app__navbar-profile">
                        <div className="app__navbar-dropdown">
                            <img className="app__navbar-dropdown-avatar" src={avatar}/>
                            <div className="app__navbar-dropdown-content">
                                <div className="app__navbar-dropdown-content-name">{displayName}</div>
                                <a onClick={logout}>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="app__navbar">
                <div className="app__navbar-homeicon"><img onClick={() => { navigate('/') }} src={HomeIcon}/></div>
                <div className="app__navbar-option" 
                    onClick={() => { navigate('/hearing-scheduler')}}
                    style={{marginLeft: '40px', borderRight: '0'}}>
                    Schedule Hearing
                </div>
                <div className="app__navbar-option" onClick={() => { navigate('/hearings')}}>
                    Hearings Docket
                </div>
                <div className="app__navbar-profileicon">
                    <div className="app__navbar-profile">
                        <div className="app__navbar-dropdown">
                            <button className="app__navbar-dropdown-dropbtn">{initials}</button>
                            <div className="app__navbar-dropdown-content">
                                <div className="app__navbar-dropdown-content-name">{displayName}</div>
                                <a onClick={logout}>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }; 
};

export default Navbar;
