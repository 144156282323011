import React from 'react';
import _ from "lodash";
import './DropdownSelectCheckbox.css';


const DropdownSelectCheckbox = ({title, placeholder, style, containerStyle, setParticipantServices}) => {
    let show = true;

    const showCheckboxes = (e) => {
        const checkboxes = document.getElementById('checkboxes');
        if (show) {
            checkboxes.style.display = "block";
            show = false;
        } else {
            checkboxes.style.display = "none";
            show = true;
        }
    };

    const addService = (e) => {
        const service = e.target.value;
        const checked = e.target.checked;        
        if (checked) {
            setParticipantServices(service, 'add');
        }
        else {
            setParticipantServices(service, 'remove')
        }
    };

    const preventDefault = (e) => {
        e.preventDefault();
    };

    return (
        <div className='app__dropdownselectcheckbox' style={containerStyle}>
          <div className='app__dropdownselectcheckbox-title'>{title}</div>
          <div className='app__dropdownselectcheckbox-select' onClick={showCheckboxes}>
              <select style={style} onMouseDown={preventDefault} onChange={preventDefault} value={placeholder}>
                  <option disabled hidden>{placeholder}</option>
              </select>
              <div className="app__dropdownselectcheckbox-overselect"></div>
          </div>
          <div className='app__dropdownselectcheckbox-checkboxes' id='checkboxes'>
            <div className='app__dropdownselectcheckbox-checkboxes-section'>In-Court</div>
            <label>
                <input className='app__dropdownselectcheckbox-checkboxes-input' 
                type='checkbox' id='court proceeding' defaultValue='Court Proceeding' 
                onChange={addService}/>
                <div className='app__dropdownselectcheckbox-checkboxes-text'>Court Proceeding</div>
            </label>
            <label>
                <input className='app__dropdownselectcheckbox-checkboxes-input' 
                type='checkbox' id='custody mediation or orientation' defaultValue='Custody Mediation or Orientation'
                onChange={addService}/>
                <div className='app__dropdownselectcheckbox-checkboxes-text'>Custody Mediation or Orientation</div>
            </label>
            <label>
                <input className='app__dropdownselectcheckbox-checkboxes-input' 
                type='checkbox' id='child planning conference' defaultValue='Child Planning Conference'
                onChange={addService}/>
                <div className='app__dropdownselectcheckbox-checkboxes-text'>Child Planning Conference</div>
            </label>
            <div className='app__dropdownselectcheckbox-checkboxes-section'>Out-Of-Court</div>
            <label>
                <input className='app__dropdownselectcheckbox-checkboxes-input'
                type='checkbox' id='district attorney' defaultValue='District Attorney' 
                onChange={addService}/>
                <div className='app__dropdownselectcheckbox-checkboxes-text'>District Attorney</div>
            </label>
            <label>
                <input className='app__dropdownselectcheckbox-checkboxes-input' 
                type='checkbox' id='public defender' defaultValue='Public Defender' 
                onChange={addService}/>
                <div className='app__dropdownselectcheckbox-checkboxes-text'>Public Defender</div>
            </label>
            <label>
                <input className='app__dropdownselectcheckbox-checkboxes-input' 
                type='checkbox' id='assigned counsel' defaultValue='Assigned Counsel' 
                onChange={addService}/>
                <div className='app__dropdownselectcheckbox-checkboxes-text'>Assigned Counsel</div>
            </label>
            <label>
                <input className='app__dropdownselectcheckbox-checkboxes-input' 
                type='checkbox' id='gal program' defaultValue='GAL Program' 
                onChange={addService}/>
                <div className='app__dropdownselectcheckbox-checkboxes-text'>GAL Program</div>
            </label>
          </div>
        </div>
      );
    }
  
export default DropdownSelectCheckbox;