import React from "react";
import ReusableLogoHeader from './ReusableLogoHeader';
import Button from "./Button";
import Navbar from "./Navbar";
import './ScheduleHearingConfirmationPage.css'
import http from '../http-common'
import { useCookies } from "react-cookie";
import { useNavigate, createSearchParams } from "react-router-dom";
import Loading from "react-loading";
import LoginPage from './LoginPage';


const ScheduleHearingConfirmationPageNonAdmin = () => {
    let navigate = useNavigate();
    const [userCookie, setUserCookie] = useCookies();
    const [isLoading, setIsLoading] = React.useState(false);
    const [userDisplayName, setUserDisplayName] = React.useState("");
    const [userEmails, setUserEmails] = React.useState("");
    const [meeting, setMeeting] = React.useState({});
    const [deviceEmail, setDeviceEmail ]= React.useState("");
    const [deviceName, setDeviceName ]= React.useState("");
    const [role, setRole] = React.useState('');

    const getMeetingByMeetingId = async (meetingId) => {
        setIsLoading(true);
        if (!meetingId) {
            console.log('No meeting ID.');
        } else {
            console.log("Meeting ID = ",meetingId)
            try {
                let response = await http.get('/get-meeting-by-id', {params: {meetingId: meetingId}});
                console.log("Meeting repsonse = ",response.data);
                if (response.data[0]) {
                    setMeeting(response.data[0]);
                } else {
                    setMeeting({});
                };
            } catch (err) {
                console.log(err);
            };
        };
        setIsLoading(false);
    };

    const getMeetingAttendeesForRoleDevice = async(meetingId) => {
        setIsLoading(true);
        let response = await http.get('/get-meeting-facilityparticipants-role-device', {params: {meetingId: meetingId}});
        console.log(response.data);
        setDeviceEmail(response.data[0].email);
        setDeviceName(response.data[0].displayName);
        setRole(response.data[0].role);
    }

    const confirmHearing = async () => {
        setIsLoading(true);
        let mongoParams = {
            _id: meeting._id,
            hearingStatus: "Confirmed"
        };
        try {
            return await http.put('/update-meeting', mongoParams).then(response => {
                if (response.data.error) {
                    console.log(response.data.error);
                    alert(response.data.error.message + '\nMeeting was not able to be confirmed.');
                }
                else {
                    console.log(response);
                    // console.log('Get meeting participants call in non admin confirmation page');
                    // http.get('/get-meeting-facilityparticipants-role-device', {params: {meetingNumber: meeting.meetingNumber}}).then(response => {
                    //     deviceEmail = response.data[0].email;
                    //     console.log('response of get facility participants with device in non admin', response.data[0].email);
                    // })
                    console.log("Inviting participants for meeting Id = ",meeting.meetingId)
                    // let inviteMeetingAttendeesReqBody = {
                    //     meetingId: meeting.meetingId,
                    //     meetingStartTime: meeting.startTime,
                    //     meetingEndTime: meeting.endTime,
                    //     title: meeting.title,
                    //     role: role || ''
                    // }
                    let  inviteMeetingAttendeesReqBody= {
                        meetingId: response.data.meetingId,
                        meetingNumber: response.data.meetingNumber,
                        startTime: response.data.startTime,
                        caseNumbers: response.data.caseNumbers,
                        eventType: response.data.eventType,
                        description: response.data.description,
                        courtroom: response.data.courtroom,
                        courtroomDevice: response.data.courtroomDevice,
                        facilityName: response.data.facilityName,
                        hearingStatus: response.data.hearingStatus,
                        result: response.data.result,
                        participants: response.data.participants,
                        webLink: response.data.webLink,
                        password: response.data.password,
                        title: response.data.title,
                        offenderDisplayName: response.data.offenderDisplayName,
                        districtAttorneyName: response.data.districtAttorneyName,
                        endTime: response.data.endTime
                      }
                    console.log('this is meeting attendees req body', inviteMeetingAttendeesReqBody);
                        http.post('/invite-meeting-attendees', inviteMeetingAttendeesReqBody).then(response => {
                        if (response.data.error) {
                            console.log(response.data.error);
                            setIsLoading(false);
                            alert(response.data.error.message + '\nMeeting invitees were unable to be added to the meeting.');
                            navigate('/facility-hearings');
                        }
                        else {
                            console.log(response);
                            setIsLoading(false);
                            alert('Meeting confirmed!');
                            navigate('/facility-hearings');
                        };
                    });
                };
            });
        } catch (err) {
            console.log(err);
        };
        setIsLoading(false);
    };

    const proposeNewTime = () => {
        let params = {
            meetingId: meeting.meetingId,
            proposeNewTimeModal: true,
        };
      
        navigate({
          pathname: '/facility-hearings',
          search: `?${createSearchParams(params)}`
        });
    };

    React.useEffect(() => {
        if( userCookie && userCookie.userProfile && userCookie.userProfile.emails[0].split('@')[1] === 'nccourts.org'){
            setUserDisplayName(userCookie.userProfile.displayName);
            setUserEmails(userCookie.userProfile.emails);
            }
        const queryParams = new URLSearchParams(window.location.search);
        const meetingId = queryParams.get("meetingId");
        let response = getMeetingByMeetingId(meetingId);
        console.log(response);
        let deviceInformation = getMeetingAttendeesForRoleDevice(meetingId);
      }, []);

    // if (userCookie.userProfile) {
        return (
            <div className="app__schedulehearingconfirmationpage">
                <div id='loading' style={{display: isLoading ? "flex" : "none", 
                zIndex: '100', position: 'fixed', alignItems: 'center', justifyContent: 'center', 
                height: '100%', width: '100%', backgroundColor: 'rgba(211,211,211,0.4)'}}>
                    <Loading type={'spinningBubbles'} color={'#003366'}/>
                </div>
                <div className="app__schedulehearingconfirmationpage-main-navbar">
                    <Navbar/>
                </div>
                <div className="app__confirmationpage-main-container">
                    <div className="app__confirmationpage-main-page">
                        <ReusableLogoHeader text={"CONFIRMATION"} />
                        <div className="app__confirmationpage-header">
                            <p>Scheduled Hearing Details</p>
                        </div>
                        <div className="app__confirmationpage-container">
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <p><b>Title: </b>{meeting.title ? meeting.title.split(' - ')[0] : "No meeting found."}</p>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
                            {meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' && 
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <div><b>Case Number(s): </b>{meeting.caseNumbers ? meeting.caseNumbers.map((caseNumber, index) =>
                                        <div key={`${index}-${caseNumber}`}>{caseNumber}</div>
                                    ) : "No case numbers found." }
                                    </div>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
                            }
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <p><b>Date and Time: </b>{meeting.startTime ? new Date(meeting.startTime).toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) + ' at ' + 
                                        new Date(meeting.startTime).toLocaleTimeString() : "No meeting start time found."}</p>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <p><b>Event Type: </b>{meeting.eventType ? meeting.eventType : "No meeting event type found."}</p>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
                            <div className="app__confirmationpage-section">
                                <div className="app__confirmationpage-section-text">
                                    <p><b>Description: </b>{meeting.description ? meeting.description : "No meeting description found."}</p>
                                </div>
                                <div className="app__confirmationpage-border"></div>
                            </div>
                            <div className="app__confirmationpage-section-last">
                                <div className="app__confirmationpage-section-text">
                                    <div className="app__confirmationpage-section-text-contact">
                                        <p><b>Scheduler Contact Information:</b></p>
                                        <p><b>Name:</b> {userDisplayName || 'Guest'}</p>
                                        <p><b>Email:</b> {userEmails || 'Guest'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app__confirmationpage-button-container">
                            <Button text={"PROPOSE NEW TIME"}
                                containerStyle={{}}
                                style={{fontWeight: 'bold', height: '4em', width: '250px', 
                                    backgroundColor: 'white', border: '1px solid #3576a5', color: '#3576a5'}}
                                originalColor={'white'} onHoverColor='lightgray'
                                onClick={proposeNewTime}/>
                            <Button text={"CONFIRM"}
                                containerStyle={{}}
                                style={{fontWeight: 'bold', height: '4em', width: '250px',
                                backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                                originalColor={'#3576a5'} onHoverColor={'#206392'}
                                onClick={confirmHearing}
                                />
                        </div>
                    </div>    
                </div>
            </div>
        );
    // } else {
    //     return (
    //         <LoginPage loginRequest={true} />
    //     );
    // };
};

export default ScheduleHearingConfirmationPageNonAdmin;
