import './CancelHearingConfirmationModal.css';
import React from "react";
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModaBody from './ModalBody';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphiStars from '../assets/icons/graphic-stars.svg';
import ModalFooter from "./ModalFooter";
import ClickableText from './ClickableText';
import Button from "./Button";
import http from '../http-common';
import { useNavigate } from "react-router-dom";
import Loading from './Loading';

export default function CancelHearingConfirmationModal(props) {
    const {
        style, 
        containerStyle,
        meeting,
        selectedReason,
        changedDescription,
    } = props;

    let navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    
    const cancelHearing = () => {
        setIsLoading(true);
        let cancelMeetingObject = {
            'cancellationReason': selectedReason,
            'description': changedDescription
        };
        console.log('cancel hearing API call:');
        console.log(cancelMeetingObject);
        let cancelHearingParam = {
            cancellationReason: cancelMeetingObject.cancellationReason,
            description: cancelMeetingObject.description,
            _id: meeting._id,
            meetingId: meeting.meetingId
        };
        console.log("Cancel hearing params",cancelHearingParam);
        try {
            http.put('/cancel-meeting', cancelHearingParam).then(response => {
                console.log(response);
                if (response.data.error) {
                    console.log(response.data.error);
                    setIsLoading(false);
                    alert(response.data.error.message + '\nMeeting was not able to be cancelled.');
                } else {
                    console.log(response);
                    if (response.status === 200) {
                        setIsLoading(false);
                        alert('Success code: ' + response.status + '\nMeeting cancelled.');
                        window.location.reload();
                    };
                };
            });
        } catch (err) {
            setIsLoading(false);
            alert(err);
            console.log(err);
      };
    };

    return (
        <div>
            <Modal containerStyle={{height: '689px', width: '515px', marginTop: '5em', alignItems: 'center', boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
            style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center'}}>
                <Loading type={'spinningBubbles'} color={'#003366'} 
                    containerStyle={{display: isLoading ? "flex" : "none"}}/>
                <ModalHeader containerStyle={{display: 'flex', flexDirection: 'column', width: '372px', height: '348px', alignItems: 'center'}} onClick={props.close}>
                    <div className="app__cancelHearingconfirmationmodal-header">
                        <div className="app__cancelHearingconfirmationmodal-header-logo">
                            <img src={SealLogo} alt="seal logo" />
                        </div>
                        <div className="app__cancelHearingconfirmationmodal-tagline">
                            <img src={LTagline} alt='tagline'/>
                        </div>
                        <div className="app__cancelHearingconfirmationmodal-graphicstars">
                            <img src={GraphiStars} alt="Graphin stars" />
                        </div>
                        <div className="app__cancelHearingconfirmationmodal-generic-inputbox" style={containerStyle}>
                            <div style={style}>ARE YOU SURE YOU WANT TO CANCEL THIS HEARING?</div>
                        </div>
                    </div>
                </ModalHeader>
                <ModaBody containerStyle={{width: "300px", height: '200px', flex: '0'}}>
                    <div className="app__cancelHearingconfirmationmodal-body-container">
                    {meeting.eventType !== 'First Appearance' && meeting.eventType !== 'Bulk Hearings' &&
                        <div className="app__cancelHearingconfirmationmodal-casenumber-container">
                            <div className="app__cancelHearingconfirmationmodal-casenumber-font">
                                <p>Case Number: </p>
                            </div>
                            <div className="app__cancelHearingconfirmationmodal-casenumber-number">
                                <div>{meeting.caseNumbers ? meeting.caseNumbers.map((caseNumber, index) =>
                                    <div key={`${index}-${caseNumber}`}>{caseNumber}</div>
                                    ) : "No case numbers found." }</div>
                            </div>
                        </div>
                    }
                        <div className="app__cancelHearingconfirmationmodal-dateandtime">
                            <p>{meeting.startTime ? new Date(meeting.startTime).toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) + ' at ' + 
                                new Date(meeting.startTime).toLocaleTimeString() : "No meeting start time found."}</p>
                        </div>
                    </div>
                </ModaBody>
                <ModalFooter containerStyle={{marginTop: '3em', height: 'auto', borderTop: '0px'}}>
                    <div className="app__cancelHearingconfirmationmodal-footer-container">
                        <div className="app__cancelHearingconfirmationmodal-footer-content">
                            <Button text={"YES, CANCEL"}
                                style={{fontWeight: 'bold', height: '4em', width: '318px', 
                                font: 'normal normal bold 14px/19px Open Sans', letterSpacing: '0px', color: '#FFFFFF',
                                opacity: '1', backgroundColor: '#3576a5', border: '1px solid #3576a5'}}
                                originalColor='#3576a5' onHoverColor='#206392' 
                                onClick={cancelHearing}/>
                            <ClickableText text={'DO NOT CANCEL'} style={{marginTop: '1em', font: 'normal normal 600 14px/19px Open Sans',
                            letterSpacing: '1.25px', color: '#4392CD', opacity: '1'}} onClick = { props.close}/>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}
