import React from "react";
import _ from "lodash";
import './ReusableLogoHeader.css';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphiStars from '../assets/icons/graphic-stars.svg';

const ModalLogoHeader = ({text, style, containerStyle}) => {
    const [modalLogoHeader, setModalLogoHeader] = React.useState(false);

    return (
        <div className="app__modallogo-header">
            <div className="app__modallogo-header-logo">
                <img src={SealLogo} alt="seal logo" />
            </div>
            <div className="app__modallogo-tagline">
            <img src={LTagline} alt='tagline'/>
            </div>
            <div className="app__modallogo-graphicstars">
                <img src={GraphiStars} alt="Graphin stars" />
            </div>
            <div className="app__modallogo-generic-inputbox" style={containerStyle}>
                <div style={style}>{text}</div>
            </div>
        </div>
    )
}

export default ModalLogoHeader;