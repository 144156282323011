import './EditHearingModal.css';
import React from "react";
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModaBody from './ModalBody';
import SealLogo from '../assets/icons/seal-logo.svg';
import LTagline from '../assets/icons/l_tagline.svg';
import GraphiStars from '../assets/icons/graphic-stars.svg';
import Button from "./Button";
import Pill from './Pill';
import DatePickerInput from './DatePickerInput';
import DropdownSelect from './DropdownSelect';
import DropdownEditSelect from './DropdownEditSelect';
import InputEditField from './InputEditField';
import TextArea from './TextArea';
import ModalRoot from './ModalRoot';
import ModalService from '../services/ModalService';
import EditHearingConfirmationModal from './EditHearingConfirmationModal';
import http from '../http-common';
import TimeWheel from './TimeWheel';
import {eventTypes, participantTypes, participantLanguages, participantCounties, durations, defaultTimeArray, mutableTimeArray} from './DefaultData';
import DropdownSelectCheckbox from './DropdownSelectCheckbox';
import Loading from './Loading';
import { useCookies } from "react-cookie";
import moment from 'moment';
import UtilService from '../services/Utils';


export default function EditHearingModal(props) {
    const {
        style, 
        containerStyle,
        onClick,
        meetingId,
    } = props;

    const [userCookie, setUserCookie] = useCookies();
    const [isLoading, setIsLoading] = React.useState(false);
    const [courtrooms, setCourtrooms] = React.useState([]);
    const [isCourtroomSelected, setIsCourtroomSelected] = React.useState(false);
    const [isCourtroomDeviceSelected, setIsCourtroomDeviceSelected] = React.useState(false);
    const [courtroomDevices, setCourtroomDevices] = React.useState([]);
    const [judges, setJudges] = React.useState([]);
    const [districtAttorneys, setDistrictAttorneys] = React.useState([]);
    const [courtroomPeople, setCourtroomPeople] = React.useState({
      judges: [],
      clerks: [],
      districtAttorneys: [],
      devices: []
    });
    const selectedMeeting = JSON.parse(window.localStorage.getItem('selectedMeeting'))
    const [meeting, setMeeting] = React.useState({});
    const [currentOffender, setCurrentOffender] = React.useState({});
    const [currentInterpreter, setCurrentInterpreter] = React.useState({});
    const [oldCourtroomPeople, setOldCourtroomPeople] = React.useState({
      judges: [],
      clerks: [],
      districtAttorneys: [],
      devices: []
    });

    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }

    const setOldCourtroomPeopleArrays = (person) => {
      if (person.role === "Judge") {
        setOldCourtroomPeople(current => ({
          ...current, judges: [person]
        }));
      } else if (person.role === "Clerk") {
        setOldCourtroomPeople(current => ({
          ...current, clerks: [person]
        }));
      } else if (person.role === "District Attorney") {
        setOldCourtroomPeople(current => ({
          ...current, districtAttorneys: [person]
        }));
      };
  };
    
    const getMeetingByMeetingId= async (meetingId) => {
      setIsLoading(true);
      if (!meetingId) {
          console.log('No meeting ID.')
      } else {
          try {
              let response = await http.get('/get-meeting-by-id', {params: {meetingId: meetingId}});
              console.log(response.data);
              if (response.data[0]) {
                setMeeting(response.data[0]);
                return response.data[0];
              } else {
                  setMeeting({});
                  return {}
              };
          } catch (err) {
              console.log(err);
          };
      };
      setIsLoading(false);
    };
    const getCurrentOffender = async (meetingId) => {
      setIsLoading(true);
      if (!meetingId) {
        console.log('No meeting ID.');
      } else {
        try {
          let response = await http.get('/get-meeting-offender', {params: {meetingId: meetingId}});
          console.log(response.data);
          if (response.data[0]) {
            setCurrentOffender(response.data[0]);
          } else {
            setCurrentOffender({});
          };
        } catch (err) {
          console.log(err);
        };
      };
      setIsLoading(false);
    };

    const getCurrentInterpreter = async (meetingId) => {
      setIsLoading(true);
      if (!meetingId) {
        console.log('No meeting ID.');
      } else {
        try {
          let response = await http.get('/get-meeting-interpreter', {params: {meetingId: meetingId}});
          console.log(response.data);
          if (response.data[0]) {
            setCurrentInterpreter(response.data[0]);
          } else {
            setCurrentInterpreter({});
          };
        } catch (err) {
          console.log(err);
        };
      };
      setIsLoading(false);
    };

    const setCourtroomPeopleArrays = (person) => {
        if (person.role === "Judge") {
          setCourtroomPeople(current => ({
            ...current, judges: [person]
          }));
        } else if (person.role === "Clerk") {
          setCourtroomPeople(current => ({
            ...current, clerks: [person]
          }));
        } else if (person.role === "District Attorney") {
          setCourtroomPeople(current => ({
            ...current, districtAttorneys: [person]
          }));
        };
    };

    const setCourtroomDA = (person) => {
      let da = {
        displayName: person,
        role: "District Attorney",
        phone: "N/A",
        email:"N/A"

      }
        setCourtroomPeople(current => ({
          ...current, districtAttorneys: [da]
        }));
    };

    const [facilities, setFacilities] = React.useState([]);
    const [dateIsSelected, setDateIsSelected] = React.useState(false);
    const [durationIsSelected, setDurationIsSelected] = React.useState(false);

    const printState = () => {
      console.log(newHearingInformation);
      console.log(newOffender);
      console.log(newParticipants);
      console.log(availability);
      console.log(courtroomPeople);
      console.log(meeting);
      console.log(currentOffender);
      console.log(currentInterpreter);
    };

    const openEditHearingConfirmationModal = () => {
      let check = [
        newHearingInformation.caseNumbers, 
        newHearingInformation.courtroom, 
        newHearingInformation.courtroomDevice, 
        newHearingInformation.eventType,
        newOffender.firstName, 
        newOffender.lastName, 
        newOffender.dateOfBirth, 
        newOffender.facilityName,
        availability.dateRange, 
        availability.duration,
        availability.startTime, 
        availability.endTime];
      
        for (var i = 0; i < check.length; i++) {
          if (!check[i] || check[i] === null || check[i] === undefined || check[i].length === 0) {
            if (i >= 11) {
              alert('Please select a meeting time slot.');
              setIsLoading(false);
              return;
            };
            if(i===7){
              alert('Please select a facility.');
              setIsLoading(false);
              return;
            }
            if((i >=4 && i <= 6) || i===0){
              if(newHearingInformation.eventType === 'First Appearance' || newHearingInformation.eventType === 'Bulk Hearings'){
                continue;
              }
            }
            // console.log('missing ' + i);
            alert('Please fill in all the required fields.');
            setIsLoading(false);
            return;
          };
          if (i === 6 && (newHearingInformation.eventType !== 'First Appearance' && newHearingInformation.eventType !== 'Bulk Hearings')) {
            if (!moment(check[i], "MM/DD/YYYY", true).isValid()) {
              alert('Please enter a valid DOB for the Offender.');
              setIsLoading(false);
              return;
            };
          };
        };
      ModalService.open(EditHearingConfirmationModal, {
        oldMeeting: meeting,
        newHearingInformation: newHearingInformation,
        newOffender: newOffender,
        newInterpreter: newParticipants,
        newAvailability: availability,
        newCourtroomPeople: courtroomPeople,
        currentOffender: currentOffender,
        currentInterpreter: currentInterpreter,
        oldCourtroomPeople: oldCourtroomPeople
      });
    };

// --------------------------- API CALLS --------------------------- //

    const getAndUpdateCourtrooms = () => {
      setIsLoading(true);
      let courtroomArray = [];
      try {
          http.get('/courtrooms').then(response => {
              console.log(response.data.items);
              courtroomArray = response.data.items;
              setCourtrooms(courtroomArray);
              try {
                  http.post('/update-courtrooms', courtroomArray).then(response => {
                      console.log(response);
                  }).catch(e => {
                      console.log(e);
                  });
              } catch (err) {
                  console.log(err);
              };
          }).catch(e => {
              console.log(e);
          });
      } catch (err) {
          console.log(err);
      };
      setIsLoading(false);
    };

    const getCourtrooms = async () => {
        setIsLoading(true);
        let courtroomArray = [];
        try {
            await http.get('/courtrooms').then(response => {
                console.log(response.data.items);
                courtroomArray = response.data.items;
                setCourtrooms(courtroomArray);
            }).catch(e => {
                console.log(e);
            });
        } catch (err) {
            console.log(err);
        };
        setIsLoading(false);
    };

    const getCourtroomDevices = async (location_id) => {
      setIsLoading(true);
      let courtroomDeviceArray = [];
      let params = {
        location_id: location_id
      };
      try {
          await http.get('/workspaces', {params: params}).then(response => {
              console.log(response.data.items);
              courtroomDeviceArray = response.data.items;
              setCourtroomDevices(courtroomDeviceArray);
          }).catch(e => {
              console.log(e);
          });
      } catch (err) {
          console.log(err);
      };
      setIsLoading(false);
    };

    const getCourtroomPeople = async (location_id) => {
      setIsLoading(true);
      let courtroomPeopleArray = [];
      let body = {
        location_id: location_id
      };
      try {
        await http.post('/get-courtroom-people', body).then(response => {
          console.log(response);
          courtroomPeopleArray = response.data;
          courtroomPeopleArray.map((courtroomPerson => {
            console.log(courtroomPerson);
            setCourtroomPeopleArrays(courtroomPerson);
          }));
        }).catch(e => {
          console.log(e);
        });
      } catch (err) {
          console.log(err);
      };
      setIsLoading(false);
    };

    const getFacilities = async () => {
      setIsLoading(true);
      let facilityArray = [];
      try {
        await http.get('/get-facilities').then(response => {
          console.log(response);
          facilityArray = response.data;
          console.log(facilityArray);
          setFacilities(facilityArray);
        }).catch(e => {
          console.log(e);
        });
      } catch (err) {
        console.log(err);
      };
      setIsLoading(false);
    };

    const getOldCourtroomPeople = async (meetingId) => {
      setIsLoading(true);
      try {
        await http.get('/get-meeting-facilityparticipants', {params: {meetingId: meetingId}}).then(response => {
          console.log("Old CourtroomPeople data",response);
          if(response.data.length > 0){
            response.data.map((courtroomPerson => {
              setOldCourtroomPeopleArrays(courtroomPerson);
            }));
          }else {
            console.log("Oldcourtroom data not found")
          }
        }).catch(e => {
            console.log(e);
        });
      } catch (err) {
        console.log(err);
      };
      setIsLoading(false);
    };

    const getJudges = async () => {
      setIsLoading(true);
      let body = {
        role: 'Judge'
      };
      try {
        await http.post('/get-courtroom-people-by-role', body).then(response => {
          setJudges(response.data);
        }).catch(e => {
          console.log(e);
        });
      } catch (err) {
          console.log(err);
      };
      setIsLoading(false);
    };

    const getDA = async () => {
      setIsLoading(true);
      let body = {
        role: 'District Attorney'
      };
      try {
        await http.post('/get-courtroom-people-by-role', body).then(response => {
          setDistrictAttorneys(response.data);
        }).catch(e => {
          console.log(e);
        });
      } catch (err) {
          console.log(err);
      };
      setIsLoading(false);
    };

    const getClerk = async () => {
      setIsLoading(true);
      //Check if user is logged in i.e user cookie is available
     if(userCookie.userProfile){
      //Fetch all the clerks available in the database
      let body = {
          role: 'Clerk'
        };
        try {
          await http.post('/get-courtroom-people-by-role', body).then(response => {
            if(response.data.length > 0){
              var clerkData = response.data;
              //If clerks are available then check if the currently logged in user is 
              //available in the database as a clerk -> if so then use that data
              clerkData.map((person => {
                if(person.email.toLowerCase() === userCookie.userProfile.emails[0].toLowerCase()){
                  setCourtroomPeopleArrays(person);
                }
                else{
                  //Set static data if logged in user is not available as a clerk in the database
                  //Use info from user cookie
                  setCourtroomPeopleArrays({
                    role:"Clerk",
                    displayName:userCookie.userProfile.displayName,
                    email:userCookie.userProfile.emails[0],
                    firstName:userCookie.userProfile.firstName,
                    lastName:userCookie.userProfile.lastName,
                    phone:"N/A",
                    courtroomId:"N/A"
                  })
                }
              }));
            }
            else{
              //If clerks are not found then use static data
              setCourtroomPeopleArrays({
                role:"Clerk",
                displayName:userCookie.userProfile.displayName,
                email:userCookie.userProfile.emails[0],
                firstName:userCookie.userProfile.firstName,
                lastName:userCookie.userProfile.lastName,
                phone:"N/A",
                courtroomId:"N/A"
              })
            }
          }).catch(e => {
            //If an error occurs then use static cookie data
            setCourtroomPeopleArrays({
              role:"Clerk",
              displayName:userCookie.userProfile.displayName,
              email:userCookie.userProfile.emails[0],
              firstName:userCookie.userProfile.firstName,
              lastName:userCookie.userProfile.lastName,
              phone:"N/A",
              courtroomId:"N/A"
            })
            console.log(e);
          });
        } catch (err) {
            console.log(err);
        };
      
     }
     setIsLoading(false);
    };

// ------------------------ END OF API CALLS ----------------------------------//

// ------------------------------- HEARING INFORMATION STATE CODE --------------------------- //

//Function to extract a county name from the courtroom name
const getCountyName = (displayName) => {
  //Will return -1 if the sub-str is not available
  var indexCounty = displayName.indexOf('County')
  var indexCourt = displayName.indexOf('Court')
  if(indexCounty > 0){
    return displayName.slice(0,indexCounty).trim()+'.Interpreter@yopmail.com'
  }
  else{
    return displayName.slice(0,indexCourt).trim().split(' ').join('')+'.Interpreter@yopmail.com';
  }
}
    const [newHearingInformation, setHearingInformation] = React.useState({
      caseNumbers: selectedMeeting.caseNumbers,
      courtroom: "",
      courtroomDevice: "",
      eventType: "",
      description: ""
    });

    // helper functions for newHearingInformation state
    const setCaseNumbers = (newCaseNumber) => {
        setHearingInformation(
          current => ({
          ...current, caseNumbers: [...current.caseNumbers, newCaseNumber]
        }));
      };

      const removeCaseNumber = (caseNumber) => {
        setHearingInformation(
          current => ({
          ...current, caseNumbers: current.caseNumbers.filter((number) => number !== caseNumber)
        }));
      };
    
    const setCourtroom = (selectedCourtroomIndex) => {
      console.log(selectedCourtroomIndex);
      setHearingInformation(current => ({
        ...current, courtroom: courtrooms[selectedCourtroomIndex].displayName
      }));
      setIsCourtroomSelected(true);
      getCourtroomDevices(courtrooms[selectedCourtroomIndex].id);
      setParticipantCounty(courtrooms[selectedCourtroomIndex].id) //Setting participant/interpreter county
      setParticipantEmail(getCountyName(courtrooms[selectedCourtroomIndex].displayName)) //Setting interpretr email
      //If cookie is not available then select clerk available in the database for a selected courthouse
      if(!userCookie.userProfile){
        getCourtroomPeople(courtrooms[selectedCourtroomIndex].id);
      }
    };

    // const setCourtroomDevice = (newCourtroomDevice) => {
    //   setHearingInformation(current => ({
    //     ...current, courtroomDevice: newCourtroomDevice
    //   }));

    //   let selectedDevice = courtroomDevices.find(x => x.displayName === newCourtroomDevice)

    //   let device = {
    //     displayName: selectedDevice.displayName,
    //     role: "Device",
    //     phone: "N/A",
    //     email:selectedDevice.calendar.emailAddress || 'N/A'

    //   }
    //     setCourtroomPeople(current => ({
    //       ...current, devices: [device]
    //     }));
    // };
    const setCourtroomDevice = (newCourtroomDevice) => {
      setHearingInformation(current => ({
        ...current, courtroomDevice: newCourtroomDevice
      }));
      let selectedDevice = courtroomDevices.find(x => x.displayName === newCourtroomDevice)
      console.log(selectedDevice);
      let selectedDeviceId = selectedDevice.id;
      console.log(selectedDeviceId);

      let params = {
        workspace_Id : selectedDeviceId
      };
      let deviceIdByWorkspace = '';
      let device = {};
      try {
        http.get('/get-workspace-by-workspace-id', {params: params}).then(response => {
          console.log('in get devices by workspace api call',response.data.items);
          // let tags = response.data.items['0'].tags
          // let deviceEmail = tags.filter(tag => isValidEmail(tag))['0'] || courtroomPeople.clerks[0].email
          let deviceEmail = response.data.notes || courtroomPeople.clerks[0].email
          console.log("This is Device Email: ", deviceEmail)
          // deviceIdByWorkspace = response.data.items['0'].id;
          // console.log('this is device id data', deviceIdByWorkspace);
          device = {
            displayName: selectedDevice.displayName,
            deviceIdWorkspace: '',
            role: "Device",
            phone: "N/A",
            // email:selectedDevice.sipAddress || 'N/A'
            email: deviceEmail
          }
          console.log(device);
          setCourtroomPeople(current => ({
            ...current, devices: [device]
          }));
        })
      }
      catch (err) {
        console.log(err);
      }

      console.log(device);
    };

    const setEventType = (newEventType) => {
      setHearingInformation(current => ({
        ...current, eventType: newEventType
      }));
    };

    const setDescription = (newDescription) => {
      setHearingInformation(current => ({
        ...current, description: newDescription
      }));
    };

// ------------------------------- END OF HEARING INFORMATION STATE CODE --------------------------- //

// ------------------------------- OFFENDER STATE CODE ------------------------------------//

    const [newOffender, setOffender] = React.useState({
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      offenderId: "",
      facilityName: "",
      facilityOwnerEmail: ""
    });
    
    const setOffenderFirstName = (newOffenderInfo) => {
        setOffender(current => ({
          ...current, firstName: newOffenderInfo
        }));
      };
  
    const setOffenderMiddleName = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, middleName: newOffenderInfo
      }));
    };

    const setOffenderLastName = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, lastName: newOffenderInfo
      }));
    };

    const setOffenderDateOfBirth = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, dateOfBirth: newOffenderInfo
      }));
    };

    const setOffenderOffenderId = (newOffenderInfo) => {
      setOffender(current => ({
        ...current, offenderId: newOffenderInfo
      }));
    };

    const setOffenderFacilityName = (selectedFacilityIndex) => {
      setOffender(current => ({
        ...current, facilityName: facilities[selectedFacilityIndex].displayName
      }));
    };

    const setOffenderFacilityOwnerEmail = (selectedFacilityIndex) => {
      setOffender(current => ({
        ...current, facilityOwnerEmail: facilities[selectedFacilityIndex].ownerEmail
      }));
    };

// ------------------------------- END OF OFFENDER STATE CODE ------------------------------------//

// ------------------------------- PARTICIPANTS STATE CODE -------------------------------- //

    const [newParticipants, setParticipants] = React.useState(
      {
        type: "",
        language: "",
        county: "",
        email:"",
        services: []
      },
    );

    const setParticipantType = (newParticipantInfo) => {
        setParticipants(current => (
          {...current, type: newParticipantInfo}
        ));
      };
  
    const setParticipantLanguage = (newParticipantInfo) => {
      setParticipants(current => (
        {...current, language: newParticipantInfo}
      ));
    };

    const setParticipantCounty = (newParticipantInfo) => {
      setParticipants(current => (
        {...current, county: newParticipantInfo}
      ));
    };
    
    const setParticipantEmail = (newParticipantInfo) => {
      setParticipants(current => (
        {...current, email: newParticipantInfo}
      ));
    };

    const setParticipantServices = (service, type) => {
      if (type === 'add') {
        setParticipants(current => (
          {
          ...current, services: [...current.services, service]
          }
        ));
      }
      else if (type === 'remove') {
        setParticipants(current => (
          {
          ...current, services: [...current.services].filter(item => item !== service)
          }
      ));
      }
      else {
        return
      }
    };
// ------------------------------- PARTICIPANTS STATE CODE -------------------------------- //

// --------------------------------- AVAILABILITY STATE CODE ------------------------------- //
  const [availability, setAvailability] = React.useState({
    dateRange: [],
    duration: [],
    startDate: "",
    startTime: "",
    endTime: "",
    startTimeString: ""
  });

  const [timeWheelArray, setTimeWheelArray] = React.useState({});

  const findAvailability = async () => {
    setIsLoading(true);
    if (isCourtroomDeviceSelected.isSelected) {
      setTimeWheelDuration(availability.duration);
      setTimeWheelDateRange(availability.dateRange);
      if (durationIsSelected && dateIsSelected) {
        // do API call to determine what times are available here:
        let result = await getMeetingAvailability(availability.duration.split(' ')[0]);
        setTimeWheelArray(result);
        setTimeWheelShow(true);
      }
      else {
        alert('Please select a date range and duration.');
      }
    } else {
        alert('Please select a courtroom device before finding availability.');
    };
    setIsLoading(false);
  };

  const getMeetingAvailability = async (durationString) => {
    let tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
    let meetingAvailability = {};
    let busyTimes = [];
    let params = {
      courtroomDevice: isCourtroomDeviceSelected.deviceName
    };
    try {
      let response = await http.get('/get-meeting-availability', {params: params});
      busyTimes = response.data;
      let busyKeys = Object.keys(busyTimes);
      console.log("busyTimes = ",busyTimes)
      console.log("busyKeys = ",busyKeys)
      availability.dateRange.forEach((key) => {
            var dateKeyString = new Date(key).toDateString()
            if(busyKeys.indexOf(dateKeyString) === -1){
              tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
              tempObject[durationString] = tempObject[durationString].filter((time) => 
              UtilService.hasTimeOccured(key,time.split('-')[0].trim()));
              meetingAvailability[dateKeyString] = tempObject;
            }
            else{
              tempObject = JSON.parse(JSON.stringify(mutableTimeArray));
              busyTimes[dateKeyString].map((meeting) => {
                      tempObject[durationString] = (tempObject[durationString].filter((time) => 
                      UtilService.hasTimeOccured(key,time.split('-')[0].trim()) && 
                      !(time.split('-')[0].trim() >= meeting.startTime &&
                      time.split('-')[0].trim() < meeting.endTime ||
                      time.split('-')[1].trim() <= meeting.endTime &&
                      time.split('-')[1].trim() > meeting.startTime)));
              });
              meetingAvailability[dateKeyString] = tempObject;
            }
      });
      return meetingAvailability;
    } catch (err) {
      console.log(err);
    };
  };

  // helper functions for availability state
  const setDateRange = (selectedDateRange) => {
    setAvailability(current => ({
      ...current, dateRange: selectedDateRange
    }));
    if (selectedDateRange.includes(undefined)){
      setDateIsSelected(false);
    }
    else {
      setDateIsSelected(true);
    }
  };

  const setDuration = (selectedDuration) => {
    setAvailability(current => ({
      ...current, duration: selectedDuration
    }));
    setDurationIsSelected(true);
  };

  const setStartDate = (selectedStartDate) => {
    setAvailability(current => ({
      ...current, startDate: selectedStartDate
    }));
  };

  const setStartTime = (selectedStartTime) => {
    setAvailability(current => ({
      ...current, startTime: selectedStartTime
    }));
  };

  const setEndTime = (selectedEndTime) => {
    setAvailability(current => ({
      ...current, endTime: selectedEndTime
    }));
  };    

  const setStartTimeString = (selectedStartTimeString) => {
    setAvailability(current => ({
      ...current, startTimeString: selectedStartTimeString
    }));
  };    

  const [timeWheelParams, setTimeWheelParams] = React.useState({
    duration: "", 
    dateRange: [],
    show: false
  });

  // helper functions for timewheel params state
  const setTimeWheelDuration = (newDuration) => {
    setTimeWheelParams(current => ({
      ...current, duration: newDuration
    }));
  };

  const setTimeWheelDateRange = (newDateRange) => {
    setTimeWheelParams(current => ({
      ...current, dateRange: newDateRange
    }));
  };    

  const setTimeWheelShow = (bool) => {
    setTimeWheelParams(current => ({
      ...current, show: bool
    }));
  };
  // --------------------------------- END OF AVAILABILITY STATE CODE ------------------------------- //
  
  React.useEffect(() => {
    if (meetingId) {
      let response = getMeetingByMeetingId(meetingId);
      let offender = getCurrentOffender(meetingId);
      let interpreter = getCurrentInterpreter(meetingId);
      let oldcourtoom = getOldCourtroomPeople(meetingId);
    };
    getAndUpdateCourtrooms();
    getFacilities();
    getJudges(); //Get a list of all the Judges
    getDA(); // Get a list of all the DAs
    getClerk(); //Get a list of all the Clerks
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('newHearingInformation', JSON.stringify(newHearingInformation));
    window.localStorage.setItem('newOffender', JSON.stringify(newOffender));
    window.localStorage.setItem('newParticipants', JSON.stringify(newParticipants));
    window.localStorage.setItem('newAvailability', JSON.stringify(availability));
    window.localStorage.setItem('courtroomPeople', JSON.stringify(courtroomPeople));
    window.localStorage.setItem('oldCourtroomPeople', JSON.stringify(oldCourtroomPeople));
  }, [newHearingInformation, newOffender, newParticipants, availability, courtroomPeople,oldCourtroomPeople]);


    return (
        <Modal containerStyle={{minHeight: '1700px', width: '1340px', alignItems: 'center', 
        boxShadow: '0px 3px 6px #00000029', opacity: '1', borderRadius: '0px'}}
            style= {{alignItems: 'center', display: 'flex',  justifyContent: 'center', marginTop: '0px'}}>
            <Loading type={'spinningBubbles'} color={'#003366'} 
                  containerStyle={{display: isLoading ? "flex" : "none"}}/>
            <ModalRoot/>   
            <ModalHeader containerStyle={{display: 'flex', flexDirection: 'column', width: '372px', height: '348px', alignItems: 'center'}} onClick={props.close}>
                <div className="app__edithearingmodal-header">
                    <div className="app__edithearingmodal-header-logo">
                        <img src={SealLogo} alt="seal logo" />
                    </div>
                    <div className="app__edithearingmodal-tagline">
                        <img src={LTagline} alt='tagline'/>
                    </div>
                    <div className="app__edithearingmodal-graphicstars">
                        <img src={GraphiStars} alt="Graphin stars" />
                    </div>
                    <div className="app__edithearingmodal-generic-inputbox" style={containerStyle}>
                        <div style={style}>EDIT HEARING</div>
                    </div>
                </div>
            </ModalHeader>
            <ModaBody containerStyle={{width: "1208px", height: '100vh', flex: '0'}}>
                <div className="app__edithearingmodal-body-container">
                {newHearingInformation.eventType !== 'First Appearance' && newHearingInformation.eventType !== 'Bulk Hearings' &&
                    <div className="app__edithearingmodal-casenumber-container">
                        <div className="app__edithearingmodal-casenumber-font">
                            <p>Case Number: </p>
                        </div>
                        <div className="app__edithearingmodal-casenumber-number">
                          <p>{meeting.caseNumbers ? meeting.caseNumbers.join(', ') : "No case numbers found"}</p>
                        </div>
                    </div>}
                    <div className="app__edithearingmodal-dateandtime">
                    <p>{meeting.startTime ? new Date(meeting.startTime).toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) + ' at ' + 
                        new Date(meeting.startTime).toLocaleTimeString() : "No meeting start time found."}</p>
                    </div>
                </div>
                <div className='app__edithearingmodal-container'>
                    <div className='app__edithearingmodal-container-section'>
                    <div className='app__edithearingmodal-container-section-header'>
                        <div className='app__edithearingmodal-subtitle'>Hearing Information</div>
                        <div className='app__edithearingmodal-subtitle-line'></div>
                    </div>
                    <div className='app__edithearingmodal-subtitle-description'
                        style={{fontSize: '1em', color: 'grey', paddingBottom: '2em'}}>
                    </div>
                    <div className='app__edithearingmodal-container-section-content'>
                        <DropdownEditSelect title={"Event Type"} placeholder={"Select Event Type"}
                        id={'eventType'}
                        selectedEventType={selectedMeeting.eventType}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        dropdownOptions={eventTypes}
                        setState={setEventType}/>
                        <DropdownEditSelect title={"Courtroom*"} placeholder={"Select Courtroom"}
                        id={'courtroom'}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        selectedCourtroom={selectedMeeting.courtroom}
                        dropdownType={'courtrooms'}
                        dropdownOptions={courtrooms}
                        setState={setCourtroom}/>
                        <DropdownSelect title={"Courtroom Device*"} placeholder={"Select Device"}
                        id={'courtroomDevice'}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        dropdownType={'courtroomDevices'}
                        dropdownOptions={courtroomDevices}
                        isDisabled={!isCourtroomSelected}
                        setState={setCourtroomDevice}
                        setIsSelected={setIsCourtroomDeviceSelected}/>
                        {(newHearingInformation.eventType === 'First Appearance' || newHearingInformation.eventType === 'Bulk Hearings') &&
                        <DropdownEditSelect title={"Facility Name"} placeholder={"Select Facility"}
                        id={'facilityName'}
                        selectedFacility={selectedMeeting.facilityName}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        dropdownType={'facility'}
                        dropdownOptions={facilities}
                        setState={[setOffenderFacilityName, setOffenderFacilityOwnerEmail]}/>}
                    </div>
                    {newHearingInformation.eventType !== 'First Appearance' && newHearingInformation.eventType !== 'Bulk Hearings' &&
                    <React.Fragment>
                    <div className='app__edithearingmodal-container-section-content'>
                        <InputEditField title={"Case Number(s)"} 
                        placeholder={"Type Case Number then ENTER to add"}
                        containerStyle={{marginRight: '1em'}}
                        style={{width: '476px'}}
                        caseNumbersField={true}
                        caseNumbers={newHearingInformation.caseNumbers}
                        setCaseNumbers={setCaseNumbers}/>
                    </div>
                    <div className='app__edithearingmodal-container-section-content'>
                        <div className='app__edithearingmodal-container-section-content-pills'>
                        {newHearingInformation.caseNumbers.map(caseNumber => <Pill text={caseNumber} caseNumbers={newHearingInformation.caseNumbers} setCaseNumbers={removeCaseNumber}/>)}
                        </div>
                    </div>
                    </React.Fragment>}
                    <div className='app__edithearingmodal-container-section-content'>
                        <TextArea title={"Description"}
                        defaultValue={selectedMeeting.description}
                        placeholder={"Provide a brief description of the hearing."}
                        containerStyle={{width: 'auto', minWidth: '60%', marginBottom: '1em'}}
                        style={{width: '100%', minWidth: '230px', minHeight: '7em'}}
                        setState={setDescription}/>
                    </div>
                    </div>
                    {newHearingInformation.eventType !== 'First Appearance' && newHearingInformation.eventType !== 'Bulk Hearings' &&
                    <div className='app__edithearingmodal-container-section'>
                      <div className='app__edithearingmodal-container-section-header'>
                          <div className='app__edithearingmodal-subtitle'>Offender</div>
                          <div className='app__edithearingmodal-subtitle-line'></div>
                      </div>
                      <div className='app__edithearingmodal-container-section-content'>
                        <InputEditField title={"First Name"} placeholder={"First Name"}
                        maxLength={15}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        defaultValue={currentOffender.firstName}
                        setState={setOffenderFirstName}/>
                        <InputEditField title={"Middle Name"} placeholder={"Middle Name"}
                        maxLength={15}
                        defaultValue={currentOffender.middleName}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        setState={setOffenderMiddleName}/>
                        <InputEditField title={"Last Name"} placeholder={"Last Name"}
                        maxLength={15}
                        defaultValue={currentOffender.lastName}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        setState={setOffenderLastName}/>
                        <InputEditField title={"Date Of Birth"} placeholder={"MM/DD/YYYY"}
                        maxLength={10}
                        defaultValue={currentOffender.dateOfBirth}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        setState={setOffenderDateOfBirth}/>
                        <DropdownEditSelect title={"Facility Name"} placeholder={"Select Facility"}
                        id={'facilityName'}
                        selectedFacility={selectedMeeting.facilityName}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        dropdownType={'facility'}
                        dropdownOptions={facilities}
                        setState={[setOffenderFacilityName, setOffenderFacilityOwnerEmail]}/>
                      </div>
                    </div>}

                    <div className='app__edithearingmodal-container-section'>
                    <div className='app__edithearingmodal-container-section-header'>
                        <div className='app__edithearingmodal-subtitle'>Participants</div>
                        <div className='app__edithearingmodal-subtitle-line'></div>
                    </div>
                    <div className='app__edithearingmodal-container-section-content'>
                        <div className='app__hearingshcedulermain-container-section-content-participants'>
                          <div><b>Scheduler: </b> 
                          {courtroomPeople.clerks.map((clerk, index) => 
                            <p key={`${index}-${clerk}`} style={{display: "inline"}}>{clerk.displayName}</p>
                          )}
                          </div>
                        </div>
                        <div className='app__hearingshcedulermain-container-section-content-participants'>
                          <InputEditField title={"District Attorney"} placeholder={"Enter District Attorney Name"}
                            maxLength={25}
                            defaultDaValue={oldCourtroomPeople.districtAttorneys}
                            containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                            style={{width: '230px'}}
                            setState={setCourtroomDA}/>
                        </div>
                    </div>

                    </div>

                    <div className='app__edithearingmodal-container-section'>
                    <div className='app__edithearingmodal-container-section-header'>
                        <div className='app__edithearingmodal-subtitle'>Search Availability</div>
                        <div className='app__edithearingmodal-subtitle-line'></div>
                    </div>
                    <div className='app__edithearingmodal-container-section-content'>
                        <DatePickerInput placeholder={"Select Date Range"}
                        containerStyle={{width: '230px', height: '4em', marginRight: '1em'}}
                        style={{height: '100%'}}
                        type="range"
                        setDateState={setDateRange}/>
                        <DropdownSelect title={"Duration*"} placeholder={"Select Duration"}
                        id={'duration'}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{width: '230px'}}
                        dropdownOptions={durations}
                        setState={setDuration}/>
                        <Button text={"LOOK UP AVAILABILITY"} 
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{fontWeight: 'bold', width: '200px', backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                        onClick={findAvailability}/>
                    </div>
                    <div className='app__hearingschedulermain-container-section-content' style={{display: (timeWheelParams.show) ? "flex" : "none"}}>
                      <div className='app__hearingschedulermain-container-section-content-timewheel' style={{maxHeight: '300px', overflow: 'auto', border: '1px solid lightgray'}}>
                        <TimeWheel
                        selectedDuration={timeWheelParams.duration}
                        durationArray={defaultTimeArray[parseInt(timeWheelParams.duration).toString()]}
                        datesObject={timeWheelArray}
                        selectedStartDate={timeWheelParams.dateRange[0]}
                        selectedEndDate={timeWheelParams.dateRange[1]}
                        setState={[setStartDate, setStartTime, setEndTime, setStartTimeString]}/>
                      </div>
                    </div>
                    </div>

                    <div className='app__edithearingmodal-container-section'>
                    <div className='app__edithearingmodal-container-section-content'>
                        <Button text={"CANCEL"}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{fontWeight: 'bold', height: '4em', width: '250px', backgroundColor: 'white', border: '1px solid #3576a5', color: '#3576a5'}}
                        originalColor={'white'}
                        onHoverColor={'lightgray'}
                        onClick = { props.close }/>
                        <Button text={"EDIT VIRTUAL HEARING"}
                        containerStyle={{marginRight: '1em', marginBottom: '1em'}}
                        style={{fontWeight: 'bold', height: '4em', width: '250px', backgroundColor: '#3576a5', border: '1px solid #3576a5', color: 'white'}}
                        originalColor='#3576a5' onHoverColor='#206392' onClick={ openEditHearingConfirmationModal }/>
                    </div>
                    </div>
                </div>
            </ModaBody>
        </Modal>
    );
}
