import React from 'react';
import {useNavigate} from 'react-router-dom';
import './Button.css';
import Cancel from '../assets/icons/cancel-icon.svg';
import Edit from '../assets/icons/edit-icon.svg';
import Reschedule from '../assets/icons/reschedule-icon.svg';


const Button = ({buttonType, text, containerStyle, style, originalColor='#3576a5', onHoverColor='#206392', image, route, onClick, dateValue}) => {
   
    function changeTextColor({event, type}) {
      if (originalColor === 'white') {
        event.target.style.backgroundColor = type === 'hover' ? onHoverColor : originalColor;
      }
      else {
        event.target.style.backgroundColor = type === 'hover' ? onHoverColor : originalColor;
        event.target.style.borderColor = type === 'hover' ? onHoverColor : originalColor; 
      };
    };

    function radioOnChange({e, type}) {
      console.log(e.target.value);
    };

    const handleTimeSelection = (e) => {
      let time = e.target.value;

      let startDate = new Date(dateValue + ' ' + time.split('-')[0].trim());
      let endDate = new Date(dateValue + ' ' + time.split('-')[1].trim());
      let isoStartDate = startDate.toISOString();
      let isoEndDate = endDate.toISOString();
      onClick[0](dateValue);
      onClick[1](isoStartDate);
      onClick[2](isoEndDate);
      onClick[3](time.split('-')[0].trim());
    }

    let navigate = useNavigate();
    let buttonImage;
    if (image) {
      if (image == "cancel") {
        buttonImage = Cancel;
      }
      else if (image == "edit") {
        buttonImage = Edit;
      }
      else if (image == "reschedule") {
        buttonImage = Reschedule;
      }
      return (
        <div className='app__button' style={containerStyle}>
          <button type="button" 
          onClick={onClick}
          onMouseEnter={(e) => changeTextColor({event: e, type: 'hover'})} 
          onMouseLeave={(e) => changeTextColor({event: e, type: 'leave'})} 
          style={style}>
            <img src={buttonImage} alt="cancel-icon"/>
            {text}
          </button>
        </div>
      );
    }
    else {
      if (route) {
        return (
          <div className='app__button' style={containerStyle}>
            <button type="button" onClick={() => { navigate(route) }} onMouseEnter={(e) => changeTextColor({event: e, type: 'hover'})} onMouseLeave={(e) => changeTextColor({event: e, type: 'leave'})} style={style}>
              {text}
            </button>
          </div>
        );
      }
      else if (buttonType === 'duration') {
        return (
          <div className='app__button-duration'>
            <label className='app__button-duration-label'>
              <input type="radio" name="options" 
              id={text} value={text}
              onChange={handleTimeSelection}/>
              {text}
            </label>
          </div>

        );
      }
      else {
        return (
          <div className='app__button' style={containerStyle}>
            <button type="button" 
            onClick={onClick}
            onMouseEnter={(e) => changeTextColor({event: e, type: 'hover'})} 
            onMouseLeave={(e) => changeTextColor({event: e, type: 'leave'})} 
            style={style}>
              {text}
            </button>
          </div>
        );
      }
    }
  }

export default Button;