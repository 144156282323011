import React from 'react';
import _ from "lodash";
import './TextArea.css';

const TextArea = ({title, placeholder, pattern, style, containerStyle, setState,defaultValue=""}) => {
    const [textAreaValue, setTextAreaValue] = React.useState(defaultValue);
    const [textAreaValueCount, setTextAreaValueCount] = React.useState(textAreaValue.length);

    const handleChange = async (e) => {
      if (e.target.value.length > 150) {
        document.getElementById('textarea-counter').style['color'] = 'red';
        return;
      };
      document.getElementById('textarea-counter').style['color'] = 'black';
      setTextAreaValueCount(e.target.value.length);
      setTextAreaValue(e.target.value);
      setState(e.target.value);
    }

    React.useEffect(() => {
      console.log("Text Area Field Use Effect")
      if(defaultValue.length > 0){
        setTextAreaValue(defaultValue);
        setState(defaultValue);
      }
    }, [defaultValue]);
  
    return (
      <div className='app__textarea' style={containerStyle}>
        <div className='app__textarea-title'>{title}</div>
        <div className='app__textarea-textarea'>
            <textarea type="text" name={title} placeholder={placeholder}
            style={style} value={textAreaValue} onChange={handleChange}></textarea>
            <div id="textarea-counter" className='app_textarea-textarea-count' style={{fontSize: '12px'}}>{textAreaValueCount}/150</div>
        </div>
      </div>
    );
  }

export default TextArea;