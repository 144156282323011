import React from 'react';
import './FacilityHearingsPage.css';
import Sidebar from './Sidebar';
import ToggleSwitch from './ToggleSwitch';
import ClickableText from './ClickableText';
import Table from'./Table';
import ModalService from '../services/ModalService.js';
import ProposeNewTimeModal from './ProposeNewTimeModal.jsx';
import CurrentlyViewingIcon from '../assets/icons/currently-viewing-icon.svg';
import ModalRoot from './ModalRoot.jsx';
import Navbar from './Navbar';
import http from '../http-common';
import HearingDetails from './HearingDetails';
import Loading from './Loading';
import { useCookies } from "react-cookie";
import LoginPage from './LoginPage';
import Fab from '@mui/material/Fab';
import AddCommentIcon from '@mui/icons-material/AddComment';
import FeedbackModal from './FeedbackModal.jsx';
import UtilService from '../services/Utils';

const fabStyle = {
    position: 'fixed',
    bottom: 16,
    right: 16,
    mr: 1
  };

const FacilityHearingsPage = ({initialCourtroom, initialDate}) => {
    const [userCookie, setUserCookie] = useCookies();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDisplay, setIsDisplay] = React.useState(false);
    const [courtroom, setCourtroom] = React.useState(initialCourtroom);
    const [date, setDate] = React.useState(initialDate);
    const [meetings, setMeetings] = React.useState([]);
    const [upcomingMeetings, setUpcomingMeetings] = React.useState([]);
    const [pastMeetings, setPastMeetings] = React.useState([]);
    const [isChecked, setIsChecked] = React.useState(false);
    const [checkedMeeting, setCheckedMeeting] = React.useState({});


    const openFeedbackModal = () => {
        if(userCookie.userProfile){
          const _profile = userCookie.userProfile;
          ModalService.open(FeedbackModal, {userProfile: _profile});
          console.log('open feedback modal clicked - if')
        }
        else {
          console.log('open feedback modal clicked - else');
  
        }
      };

    const getMeetings = async () => {
        setIsLoading(true);
        let meetingArray = [];
        let upcomingArray = [];
        let pastArray = [];
        try {
            await http.get('/get-meetings').then(response => {
                meetingArray = response.data;
                setMeetings(meetingArray);
                upcomingArray = meetingArray.filter((meeting) => UtilService.hasDateOccured(meeting.startTime));
                if(upcomingArray.length > 1){
                    upcomingArray.sort(
                        (objA, objB) => new Date(objA.startTime) - new Date(objB.startTime),
                    );
                }
                setUpcomingMeetings(upcomingArray);
                pastArray = meetingArray.filter((meeting) => !UtilService.hasDateOccured(meeting.startTime));
                if(pastArray.length > 1){
                    pastArray.sort(
                        (objA, objB) => new Date(objB.startTime) - new Date(objA.startTime),
                    );
                }
                setPastMeetings(pastArray);
            }).catch(e => {
                console.log(e);
            });
        } catch (err) {
            console.log(err);
        };
        setIsLoading(false);
    };

    const preOpenNewTimeProposedModal = (meetingId) => {
        ModalService.open(ProposeNewTimeModal, {meetingId: meetingId});
    };

    const openNewTimeProposedModal = () => {
        if (isChecked) {
            ModalService.open(ProposeNewTimeModal, {meetingId: checkedMeeting.meetingId});
        } else {
            alert('Please check a hearing first.')
        }
    };

    const openHearingDetailsModal = (meetingId) => {
        if (isChecked) {
            if (!isDisplay) {
                ModalService.open(HearingDetails, {hearingDetailsType: 'upcoming', meetingId: checkedMeeting.meetingId});
            } else {
                ModalService.open(HearingDetails, {hearingDetailsType: 'past', meetingId: checkedMeeting.meetingId});
            };
        } else {
            alert('Please check a hearing first.')
            console.log(isChecked);
        }
    };

    React.useEffect(() => {
        if (!userCookie.userProfile) {
            return;
        } else {
            getMeetings();
            console.log('Facility Hearings page useEffect render on initial load');
            const queryParams = new URLSearchParams(window.location.search);
            let meetingId = queryParams.get("meetingId");
            let proposeNewTimeModal = queryParams.get('proposeNewTimeModal');
            if (proposeNewTimeModal && meetingId) {
                preOpenNewTimeProposedModal(meetingId);
            };
        };
      }, []);

    if (userCookie.userProfile && userCookie.userProfile.emails[0].split('@')[1] === 'nccourts.org') {
        return (
            <div className='app__facilityhearingspage'>
                <div id='loading' style={{display: isLoading ? "initial" : "none"}}>
                    <Loading type={'spinningBubbles'} color={'#003366'}/>
                </div>
                <div className='app__facilityhearingspage-navbar'>
                    <Navbar/>
                </div>
                <div className='app__facilityhearingspage-pagecontent'>
                    <ModalRoot />
                    <div className='app__facilityhearingspage-sidebar'>
                        <Sidebar selectedMeeting={checkedMeeting}/>
                    </div>
                    <div className='app__facilityhearingspage-main'>
                        <div className='app__facilityhearingspage-header'>
                            <div className='app__facilityhearingspage-header-row'>
                                <div className='app__facilityhearingspage-header-row-title' style={{display: !isDisplay ? "flex" : "none"}}>
                                    Upcoming Facility Hearings
                                </div>
                                <div className='app__facilityhearingspage-header-row-title' style={{display: !isDisplay ? "none" : "flex"}}>
                                    Past Facility Hearings
                                </div>
                                <div className='app__facilityhearingspage-header-title-description'>
                                    <img src={CurrentlyViewingIcon} style={{paddingRight: "0.25em"}}/> 
                                    <div className='app__facilityhearingspage-header-title-description-text'>
                                        <b>Currently Viewing</b> Docket
                                    </div>
                                </div>                        
                            </div>
                        </div>
                        <div className='app__facilityhearingspage-settings'>
                            <div className='app__facilityhearingspage-settings-left'>
                                {/* <DropdownSelect
                                    title={"Facility Name"}
                                    placeholder={"Select Facility"}
                                    containerStyle={{marginRight: '1em'}}
                                    style={{width: '200px', height: '4em'}}/>
                                <DatePickerInput placeholder={"Select Date Range"}
                                    containerStyle={{width: '200px', height: '4em', marginRight: '1em'}}
                                    style={{height: '100%'}}
                                    type={'range'}
                                    setDateState={setDate}/> */}
                                <div className='app__facilityhearingspage-settings-left-toggle'>
                                    <ToggleSwitch 
                                        title={"hearings-toggle"} id={"hearings-toggle"}
                                        containerStyle={{height: 'auto', marginRight: '.5em'}}
                                        style={{}}
                                        initialChecked={false}
                                        isDisplay={isDisplay}
                                        setIsDisplay={setIsDisplay}
                                        />
                                    <div style={{display: !isDisplay ? "flex" : "none", alignItems: 'center', justifyContent: 'center', 
                                        fontWeight: '500',}}>
                                        Show Past Hearings
                                    </div>
                                    <div style={{display: !isDisplay ? "none" : "flex", alignItems: 'center', justifyContent: 'center', 
                                        fontWeight: '500',}}>
                                        Show Upcoming Hearings
                                    </div>
                                </div>
                            </div>
                            <div className='app__facilityhearingspage-settings-right' >
                                <div className="app__hearingspage-settings-right-view">
                                    <ClickableText text={'VIEW HEARING PARTICIPANTS'} 
                                        // onClick={() => openHearingDetailsModal('past')}
                                        // onClick={() => console.log('wow amazing')}
                                        containerStyle={{marginRight: '1em'}}
                                        style={{}}
                                        image={"carrot-down"} 
                                        onClick={ openHearingDetailsModal }
                                        />
                                </div>  
                                <ClickableText text={'PROPOSE NEW TIME'}
                                    containerStyle={{}}
                                    style={{}}
                                    image={"reschedule" }
                                    onClick={ openNewTimeProposedModal }
                                    />
                            </div>
                        </div>
                        <div className='app__facilityhearingspage-table'>
                            <div className='app__facilityhearingspage-table-upcoming' style={{display: !isDisplay ? "" : "none"}}>
                                <Table tableType={'upcominghearings'} data={upcomingMeetings} setState={[setIsChecked, setCheckedMeeting]}/>
                            </div>
                            <div className='app__facilityhearingspage-table-past' style={{display: !isDisplay ? "none" : ""}}>
                                <Table tableType={'pasthearings'} data={pastMeetings} setState={[setIsChecked, setCheckedMeeting]}/>
                            </div>                   
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <LoginPage loginRequest={true} />
        );
    };
};

export default FacilityHearingsPage;
